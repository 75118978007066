import { AlertRowTextType } from '../../../../../../../../../../../../component/alertRowText/alertRowText'

const fasting_glucose_translate = require('../../../../../../../../../../../data/translation.json')

export function getGlycemiaResult(glycemia, high) {
	glycemia = Number(glycemia)

	if (glycemia >= 92) {
		return { type: AlertRowTextType.WARNING, content: high }
	} else {
		return { type: AlertRowTextType.SUCCESS, content: 'Normal' }
	}
}

export function getTotgResult(totg, totg_interval, high) {
	if (
		(totg >= 180 && totg_interval === 'ONE_HOUR') ||
		(totg >= 153 && totg_interval === 'TWO_HOURS')
	) {
		return { type: AlertRowTextType.WARNING, content: high }
	} else {
		return { type: AlertRowTextType.SUCCESS, content: 'Normal' }
	}
}

export function getFastingBloodGlucoseResults(
	glucose_exams = [],
	selectedLanguage
) {
	const { Bloodglucose } = fasting_glucose_translate[selectedLanguage]
	let alterations = []

	for (let i = 0; i < glucose_exams.length; i++) {
		let glycemia = getGlycemiaResult(glucose_exams[i].glycemia)
		if (glycemia.type === AlertRowTextType.WARNING) {
			alterations.push({ type: AlertRowTextType.INFO, content: Bloodglucose })
			break
		}
	}

	for (let i = 0; i < glucose_exams.length; i++) {
		let totg_result = getTotgResult(
			glucose_exams[i].totg,
			glucose_exams[i].totg_interval
		)
		if (totg_result.type === AlertRowTextType.WARNING) {
			alterations.push({ type: AlertRowTextType.INFO, content: 'TOTG' })
			break
		}
	}

	return alterations
}
