import isValidDateExcludingPastDates from "../../../../../../../../validators/isValidDateExcludingPastDates"

export class InventoryMaterialRequest {
    #errors

    constructor(data) {
        Object.assign(this, data)
        
        this.id = data?.id || null
        this.name = data?.name || ''
        this.product_type = data?.product_type || ''
        this.code = data?.code || ''
        this.category = data?.category || ''
        this.location = data?.location || ''
        this.manufacturer = data?.manufacturer || ''
        this.presentation = data?.presentation || ''
        this.health_place = data?.health_place || null
        this.batch_quantity = typeof data?.batch_quantity === 'number' ? data.batch_quantity : null
        this.minimum_stock = typeof data?.minimum_stock === 'number' ? data.minimum_stock : null
        this.expiration_notice = typeof data?.expiration_notice === 'number' ? data.expiration_notice : null
        this.expiration_date = data?.expiration_year && data?.expiration_month && data?.expiration_day
            ? `${data?.expiration_year}-${data?.expiration_month}-${data?.expiration_day}`
            : ""
        this.is_a_free_sample = data?.is_a_free_sample || false

        this.#errors = {}
    }
    
    isValid() {
        const OPEN_FIELDS = ['name','code','category','location','manufacturer','presentation']
        const NUM_OPEN_FIELDS = ['batch_quantity', 'minimum_stock', 'expiration_notice']

        Object.entries(this).map(([key, value]) => {
            if (OPEN_FIELDS.includes(key) && !value) {
                this.#errors[key] = true
            } else if (NUM_OPEN_FIELDS.includes(key) && value === null) {
                this.#errors[key] = true
            } else if (key === 'expiration_date' && !isValidDateExcludingPastDates(value)) {
                this.#errors['expiration_year'] = true
                this.#errors['expiration_month'] = true
                this.#errors['expiration_day'] = 'Data anterior'
            }
        })

        return Object.keys(this.#errors).length === 0
    }

    getErrors() {
        return this.#errors
    }
}