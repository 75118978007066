import './SOAPSubjective.css'
import React, { useEffect, useRef } from 'react'
import { useAttendance } from '../../../../consultation'
import ConsultationTextField from '../../../../consultation-text-field'
import { Input } from '../../../../../../component'
import NewMultiSelect from '../../../../../../newcomponents/form/select/multiselect'
import AlertRowText, { AlertRowTextType } from '../../../../../../component/alertRowText/alertRowText'
import MedicalTeamIco from '../../../../../../component/icon/medicalTeam'
import PreceptorshipEvaluation from '../subcomponents/PreceptorshipEvaluation/PreceptorshipEvaluation'

const {
    PreceptorshipSections
} = require('../../SOAPSection.json')

function uniqueCIAPs(combined) {
    let uniqueDict = {};
    let uniqueByCode = [];

    for (let item of combined) {
        if (!uniqueDict[item.code]) {
            uniqueDict[item.code] = true;
            uniqueByCode.push(item);
        }
    }

    return uniqueByCode
}

export default function SOAPSubjective() {
    const searchTimeout = useRef(null)
    const { 
        attendance, 
        setAttendance, 
        isEditable, 
        savePartialAppointment,
        savePartialAppointmentForeignKey,
        fetchCIAPTwentyCodes, 
        attendanceExtraData: { ciaps },
        displayPreceptorship
    } = useAttendance()

    useEffect(() => {
        savePartialAppointmentForeignKey('reason_for_consultation')
    }, [attendance.reason_for_consultation])

    const toggleOption = ({ optionRef }) => {
        let selected = ciaps.find(ciap => ciap.code === optionRef)
        let isExisting = attendance.reason_for_consultation.find(ciap => ciap.code === optionRef)

        if (isExisting) {
            setAttendance(prev => ({ ...prev, 'reason_for_consultation': prev.reason_for_consultation.filter(ciap => ciap.code !== optionRef) }));
        } else {
            setAttendance(prev => ({ ...prev, 'reason_for_consultation': [...prev.reason_for_consultation, selected] }));
        }
        fetchCIAPTwentyCodes();
    };

    const searchReasonForConsultation = (search) => {
        if (searchTimeout.current) clearTimeout(searchTimeout.current)

        searchTimeout.current = setTimeout((search) => {
            fetchCIAPTwentyCodes({ search });
        }, 400, search)
    };

    const handleChange = (id, value) => {
        setAttendance(prev => ({ ...prev, [id]: value }))

        if (searchTimeout.current) {
            clearTimeout(searchTimeout.current);
        }

        searchTimeout.current = setTimeout(() => {
            savePartialAppointment(id, value);
        }, 500)
    };

    const handleBlur = (id) => {
        savePartialAppointment(id, attendance[id]);
    }

    return <>
        {
            attendance?.symptoms?.length
            ? <>
                <b>Sintomas reportados</b>
                <div className='symptoms-list'>
                    { attendance.symptoms.map(symptom => <div key={symptom.id} className='symptom'>{symptom.name}</div>) }
                </div>
                <div className='SOAP-Section-Separator' />
            </>
            : null
        }
        <div>
            <p><b>Motivo de consulta (CIAP-2)</b></p>
            <div className='SOAP-Subjective-NewMultiSelect'>
                <NewMultiSelect
                    filterNode={<div className='SOAP-Subjective-NewMultiSelect-FilterNode'>
                        <Input
                            placeholder='Pesquisar por CIAP ou Título'
                            action={({ target: { value } }) => searchReasonForConsultation(value)}
                            actionFocus={()=> fetchCIAPTwentyCodes()}
                        />
                    </div>}
                    defaultText='Selecione'
                    onlyDefaultText
                    onSelect={toggleOption}
                    selectedOptions={attendance?.reason_for_consultation?.map(ciap => ciap.code) || []}
                    optionRefKey='code'
                    optionStrKey='title'
                    options={attendance?.reason_for_consultation
                        ? uniqueCIAPs([...attendance.reason_for_consultation, ...ciaps]).map(ciap => ({ ...ciap, title: `${ciap.code} | ${ciap.title}` }))
                        : []
                    }
                    disabled={!isEditable}
                />
            </div>
            <div className='SOAP-Subjective-NewMultiSelect-Selecteds'>
                { attendance?.reason_for_consultation?.map(({ title, code }, idx) => <div key={idx} className='SOAP-Subjective-NewMultiSelect-Selecteds-Option'><AlertRowText content={`${code} | ${title}`} type={AlertRowTextType.INFO} outlined /></div>) }
            </div>
        </div>
        <div className='SOAP-Section-Separator' />
        <ConsultationTextField
            id='main_complaint'
            title='Queixa principal (QP)'
            value={attendance.main_complaint}
            handleChange={(value) => handleChange('main_complaint', value)}
            readOnly={!isEditable}
            onBlur={() => handleBlur('main_complaint')}
        />
        <div className='SOAP-Section-Separator' />
        <ConsultationTextField
            id='history_of_current_illness'
            title='História da doença atual (HDA)'
            value={attendance.history_of_current_illness}
            handleChange={(value) => handleChange('history_of_current_illness', value)}
            readOnly={!isEditable}
            onBlur={() => handleBlur('history_of_current_illness')}
        />
        <div className='SOAP-Section-Separator' />
        <ConsultationTextField
            id='past_pathological_history'
            title='História patológica pregressa (HPP)'
            value={attendance.past_pathological_history}
            handleChange={(value) => handleChange('past_pathological_history', value)}
            readOnly={!isEditable}
            onBlur={() => handleBlur('past_pathological_history')}
        />
        <div className='SOAP-Section-Separator' />
        {
            displayPreceptorship
            ? <div className='SOAP-Subjective-Preceptorship'>
                <div className='SOAP-Subjective-Preceptorship-Label'>
                    <MedicalTeamIco style={{ width: '21px', fill: '#CED4DA', marginRight: '8px' }} /> Preceptoria
                </div>
                <PreceptorshipEvaluation 
                    options={PreceptorshipSections.Subjective.options}
                    feedbackKey={PreceptorshipSections.Subjective.feedbackKey}
                />
            </div>
            : null
        }
    </>
}
