import './SaveDocumentSection.css'
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AddAlert, AlertType, RadioButton } from '../../../../../../../../../../../../../component'
import { loaded, loading } from '../../../../../../../../../../../../../layout/redux/AppActions'
import { putActivity } from '../../../../../../../../../../../http'
import DefaultEditor from './subcomponents/DefaultEditor/DefaultEditor'
import GlassesPrescription from './subcomponents/GlassesEditor/GlassesPrescription'
import { MODAL_INIT_PROPS } from '../../../../../../../../../../../../../newcomponents/modal/modal'
import { formatDateWithTimezoneFromDateObj } from '../../../../../../../../../../../../../utils/convertDate'


const { attachOptions } = require('../../../../../DocumentsEmission.json')

export default function SaveDocumentSection({ attendance=null, setModalInfo= () => null }) {
    const dispatch = useDispatch()
    const [selectedCategory, setSelectedCategory] = useState('RECIPE')

    const handleSelectCategory = (event) => {
        setSelectedCategory(event.target.value.id)
    }

    const saveDocument = async (payload) => {
        const document_date = formatDateWithTimezoneFromDateObj(new Date(), '00', '00')

        payload = {
            ...payload,
            title: attachOptions?.find(op => op?.id === selectedCategory)?.name,
            category: selectedCategory,
            attendance_id: attendance?.id,
            person_id: attendance?.person?.id,
            document_date: document_date
        }
        
        dispatch(loading())
        try {
            await putActivity(payload)
            document.dispatchEvent(new CustomEvent("DocumentsHistoryFetchActivities"))
            dispatch(AddAlert('Salvar documento', 'Documento salvo com sucesso.', AlertType.SUCCESS))
            setModalInfo(MODAL_INIT_PROPS)
        } catch (err) {
            console.error('saveDocument ~ ', err)
            dispatch(AddAlert('Salvar documento', 'Falha ao salvar documento.', AlertType.ERROR))
        }
        dispatch(loaded())
    }

    const editorOptions = {
        "GLASSES_PRESCRIPTION": <GlassesPrescription 
            saveDocument={saveDocument}
        />,
    }

    return <div className="SaveDocumentSection">
        <div>
            <p><b>Tipo de documento</b></p>
            <RadioButton
                name='category'
                action={handleSelectCategory}
                checked={selectedCategory}
                options={attachOptions}
            />
        </div>
        {
            editorOptions[selectedCategory] || <DefaultEditor 
                saveDocument={saveDocument}
            />
        }
    </div>
}