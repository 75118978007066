import React, { useRef } from 'react'
import { useAttendance } from '../../../../consultation'
import ConsultationTextField from '../../../../consultation-text-field'
import { Anthropometry, VitalSigns } from './VitalSignsAnthropometry/VitalSignsAnthropometry'
import MedicalTeamIco from '../../../../../../component/icon/medicalTeam'
import PreceptorshipEvaluation from '../subcomponents/PreceptorshipEvaluation/PreceptorshipEvaluation'

const {
    PreceptorshipSections
} = require('../../SOAPSection.json')

export default function SOAPObjective() {
    const searchTimeout = useRef(null)
    const { 
        attendance, 
        setAttendance, 
        isEditable, 
        savePartialAppointment, 
        displayPreceptorship 
    } = useAttendance()

    const handleChange = (id, value) => {
        setAttendance(prev => ({ ...prev, [id]: value }))

        if (searchTimeout.current) {
            clearTimeout(searchTimeout.current);
        }

        searchTimeout.current = setTimeout(() => {
            savePartialAppointment(id, value);
        }, 500)
    };

    const handleBlur = (id) => {
        savePartialAppointment(id, attendance[id]);
    }

    return <>
        <ConsultationTextField
            id='physical_exam'
            title='Exame físico'
            value={attendance.physical_exam}
            handleChange={(value) => handleChange('physical_exam', value)}
            readOnly={!isEditable}
            onBlur={() => handleBlur('physical_exam')}
        />
        <div className='SOAP-Section-Separator' />
        <VitalSigns />
        <div className='SOAP-Section-Separator' />
        <Anthropometry />
        <div className='SOAP-Section-Separator' />
        {
            displayPreceptorship
            ? <div className='SOAP-Subjective-Preceptorship'>
                <div className='SOAP-Subjective-Preceptorship-Label'>
                    <MedicalTeamIco style={{ width: '21px', fill: '#CED4DA', marginRight: '8px' }} /> Preceptoria
                </div>
                <PreceptorshipEvaluation 
                    options={PreceptorshipSections.Objective.options}
                    feedbackKey={PreceptorshipSections.Objective.feedbackKey}
                />
            </div>
            : null
        }
    </>
}