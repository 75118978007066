import './ListInventoryMaterials.css'
import React, { useState } from "react";
import { IcoPencil, List, MaskDataHora, Paginate } from '../../../../../../component';
import { getInventoryMaterials } from '../../http';
import { useInventoryMaterials } from '../../InventoryMaterials';
import InsertInventoryMaterialsModal from './subcomponents/InsertInventoryMaterialsModal/InsertInventoryMaterialsModal';
import NewRequestModal from './subcomponents/NewRequestModal/NewRequestModal';
import { getExpirationStatus, getStockStatus } from '../../helpers';
import FilterInventoryParams from './subcomponents/FilterInventoryParams/FilterInventoryParams';
import IcoBoxBarcode from '../../../../../../component/icon/box_barcode';
import IcoBoxDelete from '../../../../../../component/icon/box_delete';
import IcoBoxEdit from '../../../../../../component/icon/box_edit';
import DeleteInventoryMaterialModal from './subcomponents/DeleteInventoryMaterialModal/DeleteInventoryMaterialModal';

const INIT_FILTER_PARAMS = { name__icontains: '', code__icontains: '', category: null, location: null, manufacturer: null, ordering: '', offset: 0 }

export default function ListInventoryMaterials() {
    const limit = 20
    const [materials, setMaterials] = useState([])
    const [filterParams, setFilterParams] = useState(INIT_FILTER_PARAMS)
    const [pagination, setPagination] = useState({ pageNumber: 0, totalPages: limit, totalElements: 0 })
    const { setModalInfo, canReadAndWrite } = useInventoryMaterials()

    const fetchMaterials = async (params={}) => {
        let res = await getInventoryMaterials({ ...params, limit, active: true })

        setMaterials(res.data.results)
        setPagination({
            totalElements: res.data.count, 
            totalPages: Math.ceil(res.data.count / limit),
            pageNumber: (params.offset / limit),
        })
    }

    const handleNextPage = offset => {
		fetchMaterials({ ...filterParams, "offset": offset * limit })
	}

    const openEditInsertInventoryModal = (holdingInventoryMaterial) => {
        setModalInfo(prev => ({ ...prev, 
            open: true, 
            title: 'Editar material', 
            content: <InsertInventoryMaterialsModal initHoldingInventoryMaterial={holdingInventoryMaterial} fetchMaterials={fetchMaterials} /> 
        }))
    }

    return <div className='ListInventoryMaterials'>
        <FilterInventoryParams 
            filterParams={filterParams}
            setFilterParams={setFilterParams}
            fetchMaterials={fetchMaterials} 
            setModalInfo={setModalInfo}
            INIT_FILTER_PARAMS={INIT_FILTER_PARAMS}
        />
        <div>
            <List 
                head={
                    canReadAndWrite
                    ? [
                        { colunm: 'h_name', text: 'Nome' },
                        { colunm: 'h_code', text: 'Código', className: 'ListInventoryMaterials-List-Code' },
                        { colunm: 'h_location', text: 'Localização', className: 'ListInventoryMaterials-List-Location' },
                        { colunm: 'h_presentation', text: 'Apresentação', className: 'ListInventoryMaterials-List-Presentation' },
                        { colunm: 'h_expiration_status', text: 'Status', width: '172px' },
                        { colunm: 'h_remaining', text: 'Restante', width: '200px' },
                        { colunm: 'h_action', text: <div className='ListInventoryMaterials-HeaderRow-Action'>Ações</div>, width: '72px' }
                    ]
                    : [
                        { colunm: 'h_name', text: 'Nome' },
                        { colunm: 'h_code', text: 'Código', className: 'ListInventoryMaterials-List-Code' },
                        { colunm: 'h_location', text: 'Localização', className: 'ListInventoryMaterials-List-Location' },
                        { colunm: 'h_presentation', text: 'Apresentação', className: 'ListInventoryMaterials-List-Presentation' },
                        { colunm: 'h_expiration_status', text: 'Status', width: '172px' },
                        { colunm: 'h_remaining', text: 'Restante', width: '200px' },
                    ]
                }
                data={materials}
                listCustom={custom => {
                    let cursor = custom
                    let expirationStatus = getExpirationStatus(cursor?.expiration_date, cursor?.expiration_notice)
                    let stockStatus = getStockStatus(cursor?.remaining, cursor?.minimum_stock)
                    cursor['h_name'] = cursor?.name || '-'
                    cursor['h_code'] = cursor?.code || '-'
                    cursor['h_category'] = cursor?.category?.name || '-'
                    cursor['h_location'] = cursor?.location?.name || '-'
                    cursor['h_manufacturer'] = cursor?.manufacturer?.name || '-'
                    cursor['h_presentation'] = cursor?.presentation || '-'
                    cursor['h_expiration_date'] = MaskDataHora(cursor?.expiration_date, 'DD/MMM/AAAA', '-')?.data || '-'
                    cursor['h_expiration_status'] = <div className='ListInventoryMaterials-Status-Subtitle' style={{...expirationStatus}}>{expirationStatus.label}</div>
                    cursor['h_remaining'] = <div className='ListInventoryMaterials-Status-Subtitle' style={{...stockStatus}}>{stockStatus.label}</div>
                    custom['h_action'] = canReadAndWrite
                        ? <div className='ListInventoryMaterials-ActionRow'>
                            {
                                cursor?.remaining > 0 
                                ? <div
                                    title='Alocar material'
                                    onClick={() => setModalInfo(prev => ({ ...prev, 
                                        open: true,
                                        title: 'Alocar material',
                                        content: <NewRequestModal holdingInventoryMaterial={cursor} fetchMaterials={fetchMaterials} />
                                    }))}
                                >
                                    <IcoBoxBarcode style={{ width: '24px', height: '24px'}} />
                                </div>
                                : null
                            }
                            <div 
                                title='Editar material'
                                onClick={() => openEditInsertInventoryModal(cursor)}                            
                            >
                                <IcoBoxEdit style={{ width: '26px', height: '26px'}} />
                            </div>
                            <div
                                title='Excluir material'
                                onClick={() => setModalInfo(prev => ({ ...prev, 
                                    open: true,
                                    title: 'Excluir Material',
                                    content: <DeleteInventoryMaterialModal
                                        holdingInventoryMaterial={cursor}
                                        fetchMaterials={fetchMaterials}
                                        setModalInfo={setModalInfo}
                                        openEditInsertInventoryModal={openEditInsertInventoryModal}
                                    />
                                }))}
                            >
                                <IcoBoxDelete style={{ width: '24px', height: '24px'}} />
                            </div>
                        </div>
                        : null
                    return custom
                }}
            />
            <div>
                <Paginate data={pagination} action={handleNextPage} />
            </div>
        </div>
    </div>
}