import './PlanUnit.css'
import React from "react";
import { renderCIAP2Field, renderSession } from '../helpers';


export default function PlanUnit({ attendance }) {

    return <div className='PlanUnit'>
        <div className='PlanUnit-Subtitle'><b>Plano</b></div>
        <div className='PlanUnit-Content' style={{ color: '#aaa' }}>
            {
                attendance?.intervention_clinical_procedures?.length
                ? <div>{renderCIAP2Field(attendance.intervention_clinical_procedures)}</div>
                : null
            }
            {
                attendance?.conduct
                ? <div>{renderSession('Conduta', attendance.conduct)}</div>
                : null
            }
        </div>
    </div>
}