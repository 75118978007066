export default function getParams(params={}) {
    params = Object.entries(params)
                .filter(([key, value]) => value || value === 0)
                .map(([key, value]) => `&${key}=${value}`)
                .join('')
    if (!params) return ''       
    if (params) params = '?' + params.substring(1)
    return params
}

export function getHotlinkFromPath() {
    const path = window.location.pathname;
    const pattern = '/1000days/:hotlink'.split('/').filter(Boolean);
    const pathSegments = path.split('/').filter(Boolean);

    if (pathSegments.length === pattern.length && pathSegments[0] === pattern[0]) {
        return pathSegments[1];
    }

    return null;
}