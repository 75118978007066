import './Partograph.css'
import React, { useState } from 'react'
import { Mask1DecimalPlace } from '../../../../../../../../../../component/mask/mask'
import ActiveLabour from './subcomponents/ActiveLabour/ActiveLabour'
import GeneralInfo from './subcomponents/GeneralInfo/GeneralInfo'
import Deliverance from './subcomponents/Deliverance/Deliverance'
import UsedDrugsInfoSection from './subcomponents/UsedDrugs/UsedDrugs'
import NewBorn from './subcomponents/NewBorn/NewBorn'
import { useThousandDays } from '../../../../ThousandDaysSection'

export default function Partograph() {
	const { selectedLanguage, setPartograph } = useThousandDays()
	const [openSection, setOpenSection] = useState('General')

	const { Geralinformation, Activephase, Delivery, Medications, Newborn } =
		require('../../../../../../../../../data/translation.json')[
			selectedLanguage
		]

	const handleChange = event => {
		let cleanedString

		switch (event.target.name) {
			case 'newborn_oxygen_saturation':
				if (
					Number(Mask1DecimalPlace(event.target.value).replace(',', '.')) > 100
				) {
					cleanedString = '100,0'
				} else if (Number(Mask1DecimalPlace(event.target.value).replace(',', '.')) < 0) {
					cleanedString = '0'
				} else {
					cleanedString = Mask1DecimalPlace(event.target.value)
				}
				break
			case 'newborn_body_temperature':
			case 'body_temperature_1':
			case 'body_temperature_2':
			case 'body_temperature_3':
			case 'body_temperature_4':
			case 'body_temperature_5':
			case 'body_temperature_6':
			case 'body_temperature_7':
			case 'body_temperature_8':
			case 'body_temperature_9':
			case 'body_temperature_10':
			case 'body_temperature_11':
			case 'body_temperature_12':
			case 'cervical_dilation_1':
			case 'cervical_dilation_2':
			case 'cervical_dilation_3':
			case 'cervical_dilation_4':
			case 'cervical_dilation_5':
			case 'cervical_dilation_6':
			case 'cervical_dilation_7':
			case 'cervical_dilation_8':
			case 'cervical_dilation_9':
			case 'cervical_dilation_10':
			case 'cervical_dilation_11':
			case 'cervical_dilation_12':
			case 'urine_eliminated_1':
			case 'urine_eliminated_2':
			case 'urine_eliminated_3':
			case 'urine_eliminated_4':
			case 'urine_eliminated_5':
			case 'urine_eliminated_6':
			case 'urine_eliminated_7':
			case 'urine_eliminated_8':
			case 'urine_eliminated_9':
			case 'urine_eliminated_10':
			case 'urine_eliminated_11':
			case 'urine_eliminated_12':
				cleanedString = Mask1DecimalPlace(event.target.value)
				break
			case 'stage_labour':
			case 'category':
			case 'placental_displacement':
			case 'deliverance_spontaneous':
			case 'deliverance_maneuver':
			case 'deliverance_manual_extraction':
			case 'deliverance_placenta_exam':
			case 'deliverance_curettage':
			case 'apgar_first_appearance':
			case 'apgar_first_heart_rate':
			case 'apgar_first_gesticulation':
			case 'apgar_first_activity':
			case 'apgar_first_min_respiration':
			case 'apgar_fifth_appearance':
			case 'apgar_fifth_heart_rate':
			case 'apgar_fifth_gesticulation':
			case 'apgar_fifth_activity':
			case 'apgar_fifth_min_respiration':
				cleanedString = event.target.value
				break
			default:
				cleanedString = event.target.value.replace(/[^0-9]/g, '')
				break
		}
		setPartograph(prev => ({
			...prev,
			[event.target.name]: cleanedString,
		}))
	}

	const handleSelect = event => {
		setPartograph(prev => ({
			...prev,
			[event.target.id]: event.target.selected,
		}))
	}

	const sections = {
		General: (
			<GeneralInfo
				handleChange={handleChange}
				handleSelect={handleSelect}
			/>
		),
		Active: (
			<ActiveLabour
				handleChange={handleChange}
				handleSelect={handleSelect}
			/>
		),
		Deliverance: <Deliverance handleChange={handleChange} />,
		Drugs: <UsedDrugsInfoSection />,
		NewBorn: (
			<NewBorn
				handleChange={handleChange}
				handleSelect={handleSelect}
			/>
		),
	}

	return (
		<div className='ThousandDays_Consultation_Childbirth'>
			<div className='Partograph-Header'>
				<p
					className={
						openSection === 'General' ? 'Partograph-Header-Active' : ''
					}
					onClick={() => setOpenSection('General')}>
					{Geralinformation}
				</p>
				<p
					className={openSection === 'Active' ? 'Partograph-Header-Active' : ''}
					onClick={() => setOpenSection('Active')}>
					{Activephase}
				</p>
				<p
					className={
						openSection === 'Deliverance' ? 'Partograph-Header-Active' : ''
					}
					onClick={() => setOpenSection('Deliverance')}>
					{Delivery}
				</p>
				<p
					className={openSection === 'Drugs' ? 'Partograph-Header-Active' : ''}
					onClick={() => setOpenSection('Drugs')}>
					{Medications}
				</p>
				<p
					className={
						openSection === 'NewBorn' ? 'Partograph-Header-Active' : ''
					}
					onClick={() => setOpenSection('NewBorn')}>
					{Newborn}
				</p>
			</div>
			<div className='PregnancyRiskCalculator-Separator' />
			{sections[openSection]}
		</div>
	)
}
