import './ObjectiveUnit.css'
import React from "react";
import { renderAnthropometty, renderSession, renderVitalSign } from '../helpers';

export default function ObjectiveUnit({ attendance }) {

    return <div className='ObjectiveUnit'>
        <div className='ObjectiveUnit-Subtitle'><b>Objetivo</b></div>
        <div className='ObjectiveUnit-Content' style={{ color: '#aaa'}}>
            {
                attendance?.physical_exam
                ? <div>{renderSession('Exame físico', attendance.physical_exam)}</div>
                : null
            }
            { attendance?.physical_exam ? <div /> : null }
            {
                attendance?.vital_sign
                ? <div>{renderVitalSign(attendance.vital_sign)}</div>
                : null
            }
            {
                attendance?.anthropometry
                ? <div>{renderAnthropometty(attendance.anthropometry)}</div>
                : null
            }
        </div>
    </div>
}