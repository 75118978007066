import React, { useEffect } from 'react'
import { NewCheckbox } from '../../../../../../../../../../../newcomponents'
import { useThousandDays } from '../../../../../ThousandDaysSection'
import { Input, RadioButton } from '../../../../../../../../../../../component'
import RiskBadge from './RiskBadge'

export function PregnancyRiskBackgroundForm() {
	const {
		selectedLanguage,
		pregnancyBackground,
		setPregnancyBackground,
		savePregnancyBackground,
	} = useThousandDays()

	const {
		Voluntaryabortion,
		Spontaneousabortion,
		Stillbirth,
		Fetaldeath,
		Eclampsia,
		PlacentaPreviaob,
		PrematurPplacentalabruption,
		IsthmoceIvicalincompetenceob,
		IntrauteIinegrowthrestriction,
		FetalmalFormation,
		PreeclamPsia,
		Lastchildbirth,
		No,
		Yes,
		Previouspregnancies,
		Miscarriages,
		Premature,
		Deliveriesconducted,
		Vaginal,
		Cesareansections,
		Withforceps,
		Livingchildren,
		Background,
	} = require('../../../../../../../../../../data/translation.json')[
		selectedLanguage
	]

	useEffect(() => {
		savePregnancyBackground()
	}, [
		pregnancyBackground.voluntary_abortion,
		pregnancyBackground.spontaneous_abortion,
		pregnancyBackground.stillbirth,
		pregnancyBackground.fetal_death,
		pregnancyBackground.eclampsia,
		pregnancyBackground.placenta_previa_ob,
		pregnancyBackground.premature_placental_abruption,
		pregnancyBackground.isthmocervical_incompetence_ob,
		pregnancyBackground.intrauterine_growth_restriction,
		pregnancyBackground.fetal_malformation,
		pregnancyBackground.pre_eclampsia,
		pregnancyBackground.last_delivery_less_than_1_year_ago,
	])

	const handleChange = (event) => {
		setPregnancyBackground((prev) => ({
			...prev,
			[event.target.name]:
				event.target.name === 'last_delivery_less_than_1_year_ago'
					? event.target.value.id
					: event.target.value.replace(/[^0-9]/g, ''),
		}))
	}

	return (
		<section>
			<NewCheckbox
				title={<b>{Background}</b>}
				className='PregnancyRiskCalculator-Checkbox'
				state={[
					{
						id: 'voluntary_abortion',
						name: Voluntaryabortion,
						checked: pregnancyBackground.voluntary_abortion,
					},
					{
						id: 'spontaneous_abortion',
						name: Spontaneousabortion,
						checked: pregnancyBackground.spontaneous_abortion,
					},
					{
						id: 'stillbirth',
						name: Stillbirth,
						checked: pregnancyBackground.stillbirth,
					},
					{
						id: 'fetal_death',
						name: Fetaldeath,
						checked: pregnancyBackground.fetal_death,
					},
					{
						id: 'eclampsia',
						name: Eclampsia,
						checked: pregnancyBackground.eclampsia,
					},
					{
						id: 'placenta_previa_ob',
						name: PlacentaPreviaob,
						checked: pregnancyBackground.placenta_previa_ob,
					},
					{
						id: 'premature_placental_abruption',
						name: PrematurPplacentalabruption,
						checked: pregnancyBackground.premature_placental_abruption,
					},
					{
						id: 'isthmocervical_incompetence_ob',
						name: IsthmoceIvicalincompetenceob,
						checked: pregnancyBackground.isthmocervical_incompetence_ob,
					},
					{
						id: 'intrauterine_growth_restriction',
						name: IntrauteIinegrowthrestriction,
						checked: pregnancyBackground.intrauterine_growth_restriction,
					},
					{
						id: 'fetal_malformation',
						name: FetalmalFormation,
						checked: pregnancyBackground.fetal_malformation,
					},
					{
						id: 'pre_eclampsia',
						name: PreeclamPsia,
						checked: pregnancyBackground.pre_eclampsia,
					},
				]}
				defaultHandleCheckbox={false}
				setState={(event) =>
					setPregnancyBackground((prev) => ({
						...prev,
						[event.target.name]: event.target.checked,
					}))
				}
			/>
			<div className='PregnancyRiskCalculator-Separator' />
			<div>
				<RadioButton
					label={
						<p>
							<b>{Lastchildbirth}</b>
						</p>
					}
					name='last_delivery_less_than_1_year_ago'
					action={handleChange}
					checked={pregnancyBackground.last_delivery_less_than_1_year_ago}
					options={[
						{ id: 'NO', name: No },
						{ id: 'YES', name: Yes },
					]}
				/>
				<div className='PregnancyRiskCalculator-3FR-Inputs'>
					<div>
						<b>{Previouspregnancies}</b>
						<div className='PregnancyRiskCalculator-DefaultInput'>
							<Input
								name='previous_pregnancies'
								action={handleChange}
								actionBlur={savePregnancyBackground}
								value={pregnancyBackground.previous_pregnancies}
								maxLength={2}
								autoComplete='off'
							/>
						</div>
					</div>
					<div>
						<b>{Miscarriages}</b>
						<div className='PregnancyRiskCalculator-DefaultInput'>
							<Input
								name='abortions'
								action={handleChange}
								actionBlur={savePregnancyBackground}
								value={pregnancyBackground.abortions}
								maxLength={2}
								autoComplete='off'
							/>
						</div>
					</div>
					<div>
						<b>{Premature}</b>
						<div className='PregnancyRiskCalculator-DefaultInput'>
							<Input
								name='prematures'
								action={handleChange}
								actionBlur={savePregnancyBackground}
								value={pregnancyBackground.prematures}
								maxLength={2}
								autoComplete='off'
							/>
						</div>
					</div>
				</div>
				<div className='PregnancyRiskCalculator-4FR'>
					<div>
						<b>{Deliveriesconducted}</b>
						<div className='PregnancyRiskCalculator-DefaultInput'>
							<Input
								name='deliveries_amount'
								action={handleChange}
								actionBlur={savePregnancyBackground}
								value={pregnancyBackground.deliveries_amount}
								maxLength={2}
								autoComplete='off'
							/>
						</div>
					</div>
					<div>
						<b>{Vaginal}</b>
						<div className='PregnancyRiskCalculator-DefaultInput'>
							<Input
								name='vaginal_deliveries'
								action={handleChange}
								actionBlur={savePregnancyBackground}
								value={pregnancyBackground.vaginal_deliveries}
								maxLength={2}
								autoComplete='off'
							/>
						</div>
					</div>
					<div>
						<b>{Cesareansections}</b>
						<div className='PregnancyRiskCalculator-DefaultInput'>
							<Input
								name='cesarean_deliveries'
								action={handleChange}
								actionBlur={savePregnancyBackground}
								value={pregnancyBackground.cesarean_deliveries}
								maxLength={2}
								autoComplete='off'
							/>
						</div>
					</div>
					<div>
						<b>{Withforceps}</b>
						<div className='PregnancyRiskCalculator-DefaultInput'>
							<Input
								name='forceps_deliveries'
								action={handleChange}
								actionBlur={savePregnancyBackground}
								value={pregnancyBackground.forceps_deliveries}
								maxLength={2}
								autoComplete='off'
							/>
						</div>
					</div>
				</div>

				<div className='PregnancyRiskCalculator-2FR'>
					<div>
						<b>{Livingchildren}</b>
						<div className='PregnancyRiskCalculator-DefaultInput'>
							<Input
								name='living_childrens'
								action={handleChange}
								actionBlur={savePregnancyBackground}
								value={pregnancyBackground.living_childrens}
								maxLength={2}
								autoComplete='off'
							/>
						</div>
					</div>
					<div>
						<b>{Stillbirth}(s)</b>
						<div className='PregnancyRiskCalculator-DefaultInput'>
							<Input
								name='stillbirth_amount'
								action={handleChange}
								actionBlur={savePregnancyBackground}
								value={pregnancyBackground.stillbirth_amount}
								maxLength={2}
								autoComplete='off'
							/>
						</div>
					</div>
				</div>
			</div>
			<RiskBadge />
		</section>
	)
}
