export function getExpirationStatus(expirationDateStr, expirationNotice=0) {
    const expirationDate = new Date(expirationDateStr);
    if (isNaN(expirationDate.getTime())) {
        return { color: '#aaa', border: '1px solid #aaa', backgroundColor: '#fbfbfb', label: 'Sem data de expiração' };
    }

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const noticePeriodStartDate = new Date(expirationDate);
    noticePeriodStartDate.setDate(expirationDate.getDate() - expirationNotice);

    if (today > expirationDate) {
        return { color: '#F95C5C', border: '1px solid #F95C5C', backgroundColor: '#fff7f7', label: 'Fora da validade' };
    } else if (today >= noticePeriodStartDate) {
        return { color: '#F8B24A', border: '1px solid #F8B24A', backgroundColor: '#fbfbfb', label: 'Próximo do vencimento' };
    } else {
        return { color: '#14A44D', border: '1px solid #14A44D', backgroundColor: '#f3fef7', label: 'Dentro do prazo' };
    }
}

export function getStockStatus(remaining, minimumStock) {
    if (remaining === null || remaining === null || minimumStock === null || minimumStock === undefined) {
        return { color: '#aaa', border: '1px solid #aaa', backgroundColor: '#fbfbfb', label: 'Estoque não informado' };
    }

    if (remaining <= 0) {
        return { color: '#F95C5C', border: '1px solid #F95C5C', backgroundColor: '#fff7f7', label: 'Fora de estoque' };
    } else if (remaining <= minimumStock) {
        return { color: '#F8B24A', border: '1px solid #F8B24A', backgroundColor: '#fbfbfb', label: `${remaining} | Estoque baixo` };
    } else {
        return { color: '#14A44D', border: '1px solid #14A44D', backgroundColor: '#f3fef7', label: `${remaining} | Disponível`};
    }
}