import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Loading from '../../newcomponents/loading/loading'
import Modal, { MODAL_INIT_PROPS } from '../../newcomponents/modal/modal'
import { MaskCpf, MaskTelefone, MaskItemCep } from '../../component'

import './configuration.css'

import IcoPassword from '../../component/icon/password'
import IcoPersonInformation from '../../component/icon/personInformation'
import IcoBusiness from '../../component/icon/business'
import IcoAffiliated from '../../component/icon/affiliated'
import IcoWhatsapp from '../../component/icon/whats_app'
import IcoBoxBarcode from '../../component/icon/box_barcode'

import {
	getHealthPlaceBranches,
	getInstituition,
	patchHealthPlace,
	patchInstitution,
	getPersonMySelf
} from './http'


import PasswordData from './subcomponents/HealthProfessionalConfig/PasswordData/PasswordData'
import PersonalDataMenu from './subcomponents/HealthProfessionalConfig/PersonalDataMenu/PersonalDataMenu'
import HealthPlaceLegalData from './subcomponents/HealthUnitConfig/HealthPlaceInstitutionalMenu/HealthPlaceInstitutionalMenu'
import HealthPlaceBranches from './subcomponents/HealthUnitConfig/HealthPlaceBranches/HealthPlaceBranches'
import WhatsAppSuportData from './subcomponents/HealthProfessionalConfig/WhatsAppSuportData/WhatsAppSuportData'
import HealthPlaceInventory from './subcomponents/HealthUnitConfig/HealthPlaceInventory/HealthPlaceInventory'
import { useApp } from '../../layout/App'

const initPersonData = {
	name: '',
	cpf: '',
	contact_phone: '',
	sex: '',
	gender: '',
	birthday_day: '',
	birthday_month: '',
	birthday_year: '',
}

const ICO_STYLES = { width: '20px', marginRight: '12px' }

const ConfigurationContext = React.createContext()

const filterSections = (currentHealthPlaceUser) => {
	const ADMINISTRATOR = [
		'health_place_legal_data',
		'affiliated',
		'health_place_inventory',
		'person',
		'changepassword',
		'whatsappsuport',
	]
	const ASSISTANT_OR_NURSE = [
		'health_place_legal_data',
		'changepassword',
		'person',
		'whatsappsuport',
	]
	const DEFAULT = [
		'health_place_legal_data',
		'changepassword',
		'person',
		'whatsappsuport',
	]

	return currentHealthPlaceUser?.profile === 'ADMINISTRATOR'
		? SECTIONS.filter((section) => ADMINISTRATOR.includes(section.id))
		: currentHealthPlaceUser?.profile === 'ASSISTANT' || currentHealthPlaceUser?.profile === 'NURSE'
		? SECTIONS.filter((section) => ASSISTANT_OR_NURSE.includes(section.id))
		: SECTIONS.filter((section) => DEFAULT.includes(section.id))
}

const SECTIONS = [
	{
		id: 'person',
		name: 'Dados pessoais',
		icon: <IcoPersonInformation style={ICO_STYLES} />,
	},
	{
		id: 'health_place_legal_data',
		name: 'Institucional',
		icon: <IcoBusiness style={ICO_STYLES} />,
	},
	{
		id: 'affiliated',
		name: 'Filiais',
		icon: <IcoAffiliated style={ICO_STYLES} />,
	},
	{
		id: 'health_place_inventory',
		name: 'Estoque',
		icon: <IcoBoxBarcode style={ICO_STYLES} />
	},
	{
		id: 'whatsappsuport',
		name: 'Suporte',
		icon: <IcoWhatsapp style={ICO_STYLES} />
	},
	{
		id: 'changepassword',
		name: 'Senha',
		icon: <IcoPassword style={ICO_STYLES} />,
	},
]

const strpPerson = person => {
	const birthday = person?.birthday?.split('-')

	return {
		name: person?.name || '',
		is_it_the_same_social_name: person?.is_it_the_same_social_name || '',
		social_name: person?.social_name|| '',
		cpf: MaskCpf(person?.cpf) || '',
		contact_phone: MaskTelefone(person?.contact_phone) || '',
		sex: person?.sex || '',
		gender: person?.gender || '',
		birthday_day: birthday?.[2] || '',
		birthday_month: birthday?.[1] || '',
		birthday_year: birthday?.[0] || '',
		user: person?.user || { email: '' },
	}
}

function strpAddress(address) {
	return {
		id: address?.id || 0,
		zip: MaskItemCep(address?.zip || ''),
		address: address?.address || '',
		number: address?.number || '',
		complement: address?.complement || '',
		district: address?.district || '',
		city: {
			id: address?.city?.id || 1,
			name: address?.city?.name || '',
			state: address?.city?.state || '',
		},
	}
}

export function useConfiguration() {
	return useContext(ConfigurationContext)
}

export default function ConfigurationPage() {
	const { currentHealthPlaceUser, loged } = useApp()
	const [institution, setInstitution] = useState({})
	const [healthPlaceBranches, setHealthPlaceBranches] = useState([])
	const [modalProps, setModalProps] = useState(MODAL_INIT_PROPS)
	const { tab } = useParams()
	const history = useHistory()
	const [loading, setLoading] = useState(false)
	const [personData, setPersonData] = useState(strpPerson(null))
	const [personFormErr, setPersonFormErr] = useState(initPersonData)
	const [addressData, setAddressData] = useState(strpAddress()) //FIXME

	const fetchCurrentPerson = async () => {
		setLoading(true)
		try {
			let response = await getPersonMySelf()
			setPersonData(strpPerson(response?.data?.results?.[0]))
			setAddressData(strpAddress(response?.data?.results?.[0]?.address))
		} catch (error) {
			console.error('fetchCurrentPerson', error)
		}
		setLoading(false)
	}

	useEffect(() => {
		fetchInstitution()
		fetchCurrentPerson()
	}, [])

	const fetchInstitution = async () => {
		if (currentHealthPlaceUser?.health_place?.id) {
			try {
				const params = { id: currentHealthPlaceUser?.health_place?.id }
				const inst = await getInstituition(params)
				setInstitution(inst?.[0])
			} catch (err) {
				setInstitution({})
				console.error('Failed to fetch institution: ', err)
			}
		}
	}

	const fetchHealthPlaceBranches = async (params={}) => {
		try {
			params = {
				...params,
				headquarter__id: currentHealthPlaceUser?.health_place?.id,
			}
			let response = await getHealthPlaceBranches(params)
			setHealthPlaceBranches(response.data.results)
		} catch (err) {
			setHealthPlaceBranches([])
			console.error('Failed to fetch health place branches: ', err)
		}
	}

	const saveInstitution = async (institution_id, payload) => {
		try {
			await patchInstitution(institution_id, payload)
			setModalProps(MODAL_INIT_PROPS)
		} catch (err) {
			console.error('Failed to fetch attendance: ', err)
		}
	}

	const saveHealthPlace = async (health_place_id, payload) => {
		setLoading(true)
		try {
			await patchHealthPlace(health_place_id, payload).then(res => setInstitution(res.data))
			setModalProps(MODAL_INIT_PROPS)
			await loged()
			setLoading(false)
		} catch (err) {
			setLoading(false)
			console.error('Failed to save health place:', err)
			return err
		}
	}

	const handleChange = (event) => {
		setInstitution((prev) => ({ ...prev, [event.target.name]: event.target.value }))
	}

	if (loading)
		return (
			<div className='ConfigurationPage-Loading'>
				<Loading />
			</div>
		)

	const PAGES = {
		person: {
			Content: <PersonalDataMenu />,
			name: '',
			description: '',
		},
		health_place_legal_data: {
			Content: <HealthPlaceLegalData />,
			name: '',
			description: '',
		},
		affiliated: {
			Content: <HealthPlaceBranches />,
			name: 'Filiais',
			description: 'Permitir a busca de parceiros por nome, CNPJ, tipo e cadastrar novos parceiros.',
		},
		health_place_inventory: {
			Content: <HealthPlaceInventory />,
			name: '',
			content: ''
		},
		whatsappsuport: {
			Content: (
				<WhatsAppSuportData/>
			),
			name: 'Suporte',
		},
		changepassword: {
			Content: <PasswordData />,
			name: 'Alterar Senha',
			description: 'Altere sua senha.',
		},
	}

	const filteredSections = filterSections(currentHealthPlaceUser)

	return (
		<ConfigurationContext.Provider
			value={{
				institution,
				setInstitution,
				modalProps,
				setModalProps,
				fetchInstitution,
				saveInstitution,
				saveHealthPlace,
				handleChange,
				healthPlaceBranches,
				setHealthPlaceBranches,
				fetchHealthPlaceBranches,
				personData,
				setPersonData,
				personFormErr,
				setPersonFormErr,
				addressData,
				setAddressData,
				strpAddress
			}}
		>
			<div className='ConfigurationPage'>
				<Modal
					dismissText='Fechar'
					dismissFn={() => setModalProps(MODAL_INIT_PROPS)}
					{...modalProps}
				/>
				<p><strong>Configuração</strong></p>
				<div className='ConfigurationPage-Content'>
					<div className='consultation-data-sideleft'>
						{filteredSections.map(({ id, name, icon }, idx) => (
							<div
								key={idx}
								className={`ConfigurationPage-sideleft-btn ${tab === id ? 'active' : ''}`}
								onClick={() => !tab ? history.push(`/configuration/${id}`) : history.push(id) }
							>
								{icon} {name}
							</div>
						))}
					</div>
					<div className='ConfigurationPage-Wrapper'>
						<p className='ConfigurationPage-SubTitle'>
							<b>{tab ? PAGES[tab]?.name : null}</b>
						</p>
						<p>{tab ? PAGES[tab]?.description : null}</p>
						<div className={`ConfigurationPage-Content-${tab}`}>
							{tab ? PAGES[tab]?.Content : null}
						</div>
					</div>
				</div>
			</div>
		</ConfigurationContext.Provider>
	)
}
