import { objectsOnlyWithIDs } from '../../../../../../../../utils'
import { buildDate } from '../../../../../../../../utils/convertDate'
import { getCompleteHemogramResults } from '../GestationSubSection/subcomponents/Exam/forms/CompleteHemogram/utils'
import { getFastingBloodGlucoseResults } from '../GestationSubSection/subcomponents/Exam/forms/FastingBloodGlucose/utils'
import { getHIVVDRLHbsAgAntiHCVResults } from '../GestationSubSection/subcomponents/Exam/forms/SerologicalTestHIVHbsAgAntiHCVVDRL/utils'
import { getRubellaToxoplasmosisCytomegalovirusResults } from '../GestationSubSection/subcomponents/Exam/forms/SerologicalTestRubToxoCito/utils'
import { getGBSResults } from '../GestationSubSection/subcomponents/Exam/forms/StreptococcusGBS/utils'
import { getTreponemalResults } from '../GestationSubSection/subcomponents/Exam/forms/TreponemalTest/utils'

export const translateABOAndRHFactor = {
	NOT_EVALUATED: 'Não avaliado',
	A: 'A',
	B: 'B',
	AB: 'AB',
	O: 'O',
	POSITIVE: '+',
	NEGATIVE: '-',
}

export function getPregnancyAge({
	last_menstruation_year,
	last_menstruation_month,
	last_menstruation_day,
	ultrasound_weeks,
	ultrasound_days,
	date,
	day,
}) {
	const DAY_REF = day ? new Date(day) : new Date()

	const last_menstruation_date = buildDate(
		last_menstruation_year,
		last_menstruation_month,
		last_menstruation_day
	)

	const CAN_CALCULATE_BY_LAST_MENSTRUATION_DATE = !!last_menstruation_date
	const CAN_CALCULATE_BY_ULTRASOUND_DATE =
		!!ultrasound_weeks && !!ultrasound_days && !!date

	const ultrasound_date_difference_for_today = Math.floor(
		(DAY_REF.getTime() - new Date(date).getTime()) /
			(1000 * 3600 * 24)
	)
	const ultrasound_pregnancy_age_days =
		Number(ultrasound_weeks * 7) +
		Number(ultrasound_days) +
		ultrasound_date_difference_for_today
	const dum_age_days = Math.floor(
		(DAY_REF.getTime() - new Date(last_menstruation_date).getTime()) /
			(1000 * 3600 * 24)
	)

	if (
		CAN_CALCULATE_BY_LAST_MENSTRUATION_DATE &&
		CAN_CALCULATE_BY_ULTRASOUND_DATE
	) {
		const days_difference = Math.abs(
			dum_age_days - ultrasound_pregnancy_age_days
		)
		switch (true) {
			case ultrasound_pregnancy_age_days <= 62:
				return days_difference >= 6
					? { type: 'Ultrassom', age: ultrasound_pregnancy_age_days }
					: { type: 'DUM', age: dum_age_days }
			case 63 <= ultrasound_pregnancy_age_days &&
				ultrasound_pregnancy_age_days <= 111:
				return days_difference >= 8
					? { type: 'Ultrassom', age: ultrasound_pregnancy_age_days }
					: { type: 'DUM', age: dum_age_days }
			case 112 <= ultrasound_pregnancy_age_days &&
				ultrasound_pregnancy_age_days <= 153:
				return days_difference >= 11
					? { type: 'Ultrassom', age: ultrasound_pregnancy_age_days }
					: { type: 'DUM', age: dum_age_days }
			case 154 <= ultrasound_pregnancy_age_days &&
				ultrasound_pregnancy_age_days <= 195:
				return days_difference >= 15
					? { type: 'Ultrassom', age: ultrasound_pregnancy_age_days }
					: { type: 'DUM', age: dum_age_days }
			case 196 <= ultrasound_pregnancy_age_days:
				return days_difference >= 22
					? { type: 'Ultrassom', age: ultrasound_pregnancy_age_days }
					: { type: 'DUM', age: dum_age_days }
			default:
				return ''
		}
	} else if (CAN_CALCULATE_BY_ULTRASOUND_DATE) {
		return { type: 'Ultrassom', age: ultrasound_pregnancy_age_days }
	} else if (CAN_CALCULATE_BY_LAST_MENSTRUATION_DATE) {
		return { type: 'DUM', age: dum_age_days }
	} else {
		return ''
	}
}

export const MaskXXWeekYDay = (value, language = 'pt-BR') => {
	if (!value) return '---'
	let weeks = 0
	while (value > 6) {
		weeks += 1
		value -= 7
	}
	if (language === 'pt-BR') {
		return weeks < 10 ? `0${weeks}s ${value}d` : `${weeks}s ${value}d`
	} else {
		return weeks < 10 ? `0${weeks}w ${value}d` : `${weeks}w ${value}d`
	}
}

export const getAlterations = (holdingProject, selectedLanguage) => {
	return [
		...getCompleteHemogramResults(
			holdingProject.hemogram_exams,
			selectedLanguage
		),
		...getFastingBloodGlucoseResults(
			holdingProject.glucose_exams,
			selectedLanguage
		),
		...getHIVVDRLHbsAgAntiHCVResults(holdingProject.hiv_hbs_antihcv_vdrl_exams),
		...getGBSResults(holdingProject.gbs_exams, selectedLanguage),
		...getRubellaToxoplasmosisCytomegalovirusResults(
			holdingProject.rub_toxo_cito_exams,
			selectedLanguage
		),
		...getTreponemalResults(
			holdingProject.treponemal_test_exams,
			selectedLanguage
		),
	]
}

export function strpObstetricData(obstetricData = null) {
	let last_menstruation_date = obstetricData?.last_menstruation_date
		? new Date(obstetricData?.last_menstruation_date)
		: ''

	return {
		project: obstetricData?.project || -1,
		last_menstruation_day: last_menstruation_date
			? `${
					last_menstruation_date.getDate() < 10 ? '0' : ''
			  }${last_menstruation_date.getDate()}`
			: '',
		last_menstruation_month: last_menstruation_date
			? `${last_menstruation_date.getMonth() + 1 < 10 ? '0' : ''}${
					last_menstruation_date.getMonth() + 1
			  }`
			: '',
		last_menstruation_year: last_menstruation_date
			? `${last_menstruation_date.getFullYear()}`
			: '',
	}
}

export function parseObstetricDate(obstetricData, project_id) {
	let payload = objectsOnlyWithIDs(obstetricData)

	return {
		project: project_id,
		last_menstruation_date: buildDate(
			payload?.last_menstruation_year,
			payload?.last_menstruation_month,
			payload?.last_menstruation_day,
			'12',
			'12'
		),
	}
}
