import './SelectScheduleHealthPlaceUserManager.css'
import React, { useContext, useEffect, useRef, useState } from 'react'
import Modal, { MODAL_INIT_PROPS } from '../../../newcomponents/modal/modal'
import { useApp } from '../../../layout/App'
import { Paginate } from '../../../component'
import CollaboratorsFilters from './subcomponents/CollaboratorsFilters/CollaboratorsFilters'
import IcoTeam from '../../../component/icon/team'
import HealthPlaceUserUnitWrapper from './subcomponents/HealthPlaceUserUnitWrapper/HealthPlaceUserUnitWrapper'
import { getHealthPlaceUserDetailed } from './http'
import NewButton, { ButtonModel } from '../../../newcomponents/button/button'
import SaveCollaborator from './subcomponents/SaveCollaborator/SaveCollaborator'

const CollaboratorsContext = React.createContext()

const initFilters = { offset: 0, name_cpf_or_email__in: [], profile__in: []}

export const useCollaborators = () => {
	return useContext(CollaboratorsContext)
}

export default function SelectScheduleHealthPlaceUserManager({ healthPlace=null }) {
  const [filters, setFilters] = useState(initFilters)
  const [modalInfo, setModalInfo] = useState(MODAL_INIT_PROPS)
  const [pagination, setPagination] = useState({ pageNumber: 0, totalPages: 1, totalElements: 0 })
  const [collaboratorOptions, setCollaboratorOptions] = useState([])
  const { currentHealthPlaceUser } = useApp()

  useEffect(() => {
	const params = buildParams()
    fetchCollaborators(params)
  }, [])

  const buildParams = () => {
	const nameCpfOrEmailFilter = filters?.name_cpf_or_email__in.map((item => item.health_place_user__id))
	const profileFilter = filters?.profile__in.map(item => item.id)

	return {
		is_active: true,
		offset: filters?.offset,
		limit: 10,
		collaborators__in: nameCpfOrEmailFilter,
		profile__in: profileFilter,
	}
}
  
  const fetchCollaborators = async params => {	
		await getHealthPlaceUserDetailed({
			...params,
			health_place__id: currentHealthPlaceUser?.health_place?.id,
			limit: 10,
		})
			.then(res => {					
        setCollaboratorOptions(res.data.results)
        setPagination({
          totalElements: res.data.count,
          totalPages: Math.ceil(res.data.count / 10),
          pageNumber: params.offset / 10,
        })
      })
			.catch(err => {
				console.error('fetchCollaborators', err)
				setCollaboratorOptions([])
			})
	}

  const handleNextPage = event => {
		const params = buildParams()
		fetchCollaborators({...params, offset: event * 10 })
	}

	return (
		<CollaboratorsContext.Provider
		value={{
			fetchCollaborators,
			setModalInfo,
		}}>
			<Modal {...modalInfo} dismissFn={() => setModalInfo(MODAL_INIT_PROPS)} />

			<div className='SelectScheduleHealthPlaceUserManager-Content'>
				<div>
					<div className='SelectScheduleHealthPlaceUserManager-FullWBtn'>
						<NewButton
							model={ButtonModel.PRIMARY}
							label='Novo colaborador'
							onClick={() =>
								setModalInfo({
									open: true,
									title: 'Novo colaborador',
									content: <SaveCollaborator healthPlace={healthPlace} />,
								})
							}
						/>
					</div>

					<div className='CollaboratorsFilters-Separator' />

					<CollaboratorsFilters
						filters={filters}
						setFilters={setFilters}
						setModalInfo={setModalInfo}
						fetchCollaborators={fetchCollaborators}
						buildParams={buildParams}
						currentHealthPlaceUser={currentHealthPlaceUser}
					/>
				</div>
				<div className='SelectScheduleHealthPlaceUserManager-Separator' />
				<div className='SelectScheduleHealthPlaceUserManager-Body'>
          		<div>
						{
							collaboratorOptions?.length
							? <div className='SelectScheduleHealthPlaceUserManager-UnitWrapper'>
								{
									collaboratorOptions.map(collaboratorInstance => <HealthPlaceUserUnitWrapper 
																						holdingHealthPlaceUser={collaboratorInstance} 
																						setModalInfo={setModalInfo}
																						healthPlace={healthPlace}
																						currentHealthPlaceUser={currentHealthPlaceUser}/>)
								}
								<Paginate data={pagination} action={handleNextPage} />
							</div>
							: <div className='SelectScheduleHealthPlaceUserManager-NotFound'>
								<div>
									<IcoTeam />
									<p><b>Nenhum profissional foi encontrado</b></p>
								</div>
							</div>
						}
					</div>
				</div>
			</div>
		</CollaboratorsContext.Provider>
	)
}