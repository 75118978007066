import './HealthPlaceUserUnitWrapper.css'
import React from "react";
import { useHistory } from 'react-router-dom'
import { IcoBin, IcoEstetoscopio, IcoPencil, MaskCNPJ, MaskDataHora } from '../../../../../component';
import AgeGroupIco from '../../../../../component/icon/age_group_ico';
import { HospitalIcon } from '../../../../../component/icon/hospital';
import WarningIcon from '../../../../../component/icon/warning';
import IcoTeam from '../../../../../component/icon/team';
import { BabyFeetIcon } from '../../../../../component/icon/baby_feet';
import ManageTeam from '../ManageTeam/ManageTeam';
import SaveCollaborator from '../SaveCollaborator/SaveCollaborator';
import RemoveCollaborator from '../RemoveCollaborator/RemoveCollaborator';
import IcoBlockCalendar from '../../../../../component/icon/calendar_block';

const { PROFILE_CHOICES } = require('../../../../../component/form/select/options.json')

const getCouncils = (councils=[]) => councils.map(councilCursor => ` | ${councilCursor?.council} ${councilCursor?.nu_crm}/${councilCursor?.uf_crm}`).join('')

const defaultUser = require('../../../../../media/DefaultUser.png')

export default function HealthPlaceUserUnitWrapper({ holdingHealthPlaceUser=null, setModalInfo=()=>null, healthPlace=null, currentHealthPlaceUser=null }) {
    const person = holdingHealthPlaceUser?.user?.person || {}
    const history = useHistory()

    return <div className='HealthPlaceUserUnitWrapper'>
        <div className='HealthPlaceUserUnitWrapper-HoldingInfo'>
            <div className='HealthPlaceUserUnitWrapper-HoldingInfo-Header'>
                <div className='HealthPlaceUserUnitWrapper-HoldingInfo-Header-Profile'>
                    <div className='HealthPlaceUserUnitWrapper-HoldingInfo-Header-Profile-Avatar'>
                        <div className='img'>
                            <img
                                className='HealthPlaceUserUnitWrapper-HoldingInfo-Header-Profile-Avatar-Img'
                                alt='Paciente foto de perfil'
                                src={person?.avatar_img_url || defaultUser}
                            />
                        </div>
                    </div>
                    <div className='HealthPlaceUserUnitWrapper-HoldingInfo-Header-Profile-InfoBox'>

                        <b className='HealthPlaceUserUnitWrapper-HoldingInfo-Header-Profile-InfoBox-Name'>
                             {person?.name || holdingHealthPlaceUser?.user?.email} 
                            {person?.name 
                            ?  null 
                            : <div className='HealthPlaceUserUnitWrapper-HoldingInfo-Header-Profile-InfoBox-Warning' title='Cadastro Incompleto.'>
                                <WarningIcon width={25} height={25} color='orange' />
                            </div>}
                        </b>

                        <span className='HealthPlaceUserUnitWrapper-HoldingInfo-Header-Profile-InfoBox-AltText'>
                            {PROFILE_CHOICES.find(({ id }) => id === holdingHealthPlaceUser?.profile)?.name} {getCouncils(person?.councils)}
                        </span>

                    </div>
                </div>
            </div>
            <div className='HealthPlaceUserUnitWrapper-HoldingInfo-ActionRow'>

                {holdingHealthPlaceUser?.profile === 'DOCTOR' || holdingHealthPlaceUser?.profile === 'DENTIST' ||
                holdingHealthPlaceUser?.profile === 'PSYCHOLOGIST' || holdingHealthPlaceUser?.profile === 'NUTRITIONIST'
                ? <div className={`HealthPlaceUserUnitWrapper-HoldingInfo-ActionRow-Btn ${person?.name ? '': 'disabled'}`} onClick={person?.name ? () => history.push(`/atendimentos/agendamento?health_place_user__user__person=${person?.id}`) : null}>
                        <IcoEstetoscopio /> <b>Agenda</b>
                </div>
                : null
                }

                {/* {holdingHealthPlaceUser?.profile === 'DOCTOR' || holdingHealthPlaceUser?.profile === 'DENTIST' ||
                holdingHealthPlaceUser?.profile === 'PSYCHOLOGIST' || holdingHealthPlaceUser?.profile === 'NUTRITIONIST'
                ? <div className='HealthPlaceUserUnitWrapper-HoldingInfo-ActionRow-Btn' 
                    onClick={() => setModalInfo({
                        open: true,
                        title: 'Gerenciar equipe',
                        content: (
                            <ManageTeam
                                leader={holdingHealthPlaceUser}
                                setModalInfo={setModalInfo}
                            />
                        )})}>
                    <IcoTeam /> <b>Equipe</b>
                  </div> : null
                } */}

                {currentHealthPlaceUser?.profile === "ADMINISTRATOR"
                ? <>
                    <div className='HealthPlaceUserUnitWrapper-HoldingInfo-ActionRow-Btn'
                        onClick={() => setModalInfo({ 
                            open: true, 
                            title: 'Editar colaborador', 
                            content: <SaveCollaborator 
                                healthPlaceUser={holdingHealthPlaceUser} 
                                healthPlace={healthPlace} 
                                /> 
                        })}>
                            <IcoPencil /> <b>Editar</b>
                    </div>

                    <div className='HealthPlaceUserUnitWrapper-HoldingInfo-ActionRow-Btn' 
                        onClick={() => setModalInfo({ open: true, title: 'Remover colaborador', content: <RemoveCollaborator holdingColaborator={holdingHealthPlaceUser} /> })}>
                        <IcoBin /> <b>Excluir</b>
                    </div>
                </> : null
}
            </div>
            
            <div className='HealthPlaceUserUnitWrapper-HoldingInfo-HorizontalSeparator'></div>
            <div className='HealthPlaceUserUnitWrapper-HoldingInfo-Footer'>
                <div>

                    {
                        holdingHealthPlaceUser?.health_place?.id
                        ? <div className='HealthPlaceUserUnitWrapper-HoldingInfo-Footer-Subtitle'>
                            <HospitalIcon style={{ width: '18px' }} /> <span>{holdingHealthPlaceUser?.health_place?.name || '-'} {holdingHealthPlaceUser?.health_place?.cnpj ? `| CNPJ ${MaskCNPJ(holdingHealthPlaceUser?.health_place?.cnpj)}` : null}</span>
                        </div>
                        : null
                    }

                    <div className='HealthPlaceUserUnitWrapper-HoldingInfo-Footer-Subtitle'>
                        <AgeGroupIco style={{ width: '18px' }} /> <span>Atendimento para idades entre {holdingHealthPlaceUser.min_age_allowed} à {holdingHealthPlaceUser?.max_age_allowed}</span>
                    </div>
                    
                </div>
                <div>

                    {
                        holdingHealthPlaceUser?.enabled_thousand_days
                        ? <div className='HealthPlaceUserUnitWrapper-HoldingInfo-Footer-Subtitle'>
                            <BabyFeetIcon style={{ width: '18px' }} /> <span>1000 dias</span>
                        </div>
                        : null
                    }
                    {
                        holdingHealthPlaceUser?.enabled_preceptorship
                        ? <div className='HealthPlaceUserUnitWrapper-HoldingInfo-Footer-Subtitle'>
                            <IcoEstetoscopio style={{ width: '18px' }} /> <span>Preceptoria</span>
                        </div>
                        : null
                    }

                </div>
            </div>
        </div>
        <div className='HealthPlaceUserUnitWrapper-Separator'></div>
        <div className='HealthPlaceUserUnitWrapper-NextTenAvailable'>

            {holdingHealthPlaceUser?.profile === 'DOCTOR' || holdingHealthPlaceUser?.profile === 'DENTIST' ||
            holdingHealthPlaceUser?.profile === 'PSYCHOLOGIST' || holdingHealthPlaceUser?.profile === 'NUTRITIONIST'
            ? <span className='HealthPlaceUserUnitWrapper-NextTenAvailable-Title'>{person?.name ? 'Próximos horários disponíveis' : 'Complete o cadastro para ver os dados completos.'}</span>
            : null}

            {holdingHealthPlaceUser?.profile === 'DOCTOR' || holdingHealthPlaceUser?.profile === 'DENTIST' ||
            holdingHealthPlaceUser?.profile === 'PSYCHOLOGIST' || holdingHealthPlaceUser?.profile === 'NUTRITIONIST'
            ? (holdingHealthPlaceUser?.next_ten_available?.length
            ? holdingHealthPlaceUser?.next_ten_available?.map(instance => {
                    const start = MaskDataHora(instance?.fields?.start, 'DD/MMM/AAAA', '-')
                    return <div className='HealthPlaceUserUnitWrapper-NextTenAvailable-Unit'>
                                {start.data} às {start.hora}
                            </div> 
                })
                : <div className='HealthPlaceUserUnitWrapper-NextTenAvailable-Empty-Message'>
                    Não há horários disponíveis
                </div>)

            : <div className='HealthPlaceUserUnitWrapper-NextTenAvailable-NoCalendar'>
                <IcoBlockCalendar style={{'color': '#aaa', 'width': '75px', 'height': '75px'}}/>
                <span className=''>Usuário sem agenda</span>
            </div>
        }
        </div>
    </div>
}
