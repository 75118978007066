import './SerologicalTestRubToxoCitoWrite.css'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import Button from '../../../../../../../../../../../../../newcomponents/button/button'
import NewSelect from '../../../../../../../../../../../../../newcomponents/form/select/select'
import AlertRowText from '../../../../../../../../../../../../../component/alertRowText/alertRowText'
import { AddAlert, AlertType } from '../../../../../../../../../../../../../component'
import api from '../../../../../../../../../../../../../helpers/api'
import { getCytomegalovirusResult, getRubellaResult, getToxoplasmosisResult } from '../utils'
import { useThousandDays } from '../../../../../../../ThousandDaysSection'
import { MODAL_INIT_PROPS } from '../../../../../../../../../../../../../newcomponents/modal/modal'
import getCurrentDate from '../../../../../../../../../../../../../utils/getCurrentDate'
import { buildDate, formatISO8601 } from '../../../../../../../../../../../../../utils/convertDate'
import { getYearsStart1900 } from '../../../../../../../../../../../../../utils/getYearsStart1900'

const selectOptions_data = require('../../../../../../../../../../../../../component/form/select/options.json')

function strpSerologicalTestRubToxoCito(exam={}) {
	let { day, month, year } = getCurrentDate()

	let date = exam?.date ? new Date(exam.date) : ''

	return {
		"id": exam?.id,
		"rubella_igm": exam?.rubella_igm || 'NOT_EVALUATED',
		"rubella_igg": exam?.rubella_igg || 'NOT_EVALUATED',
		"toxoplasmosis_igm": exam?.toxoplasmosis_igm || 'NOT_EVALUATED',
		"toxoplasmosis_igg": exam?.toxoplasmosis_igg || 'NOT_EVALUATED',
		"cytomegalovirus_igm": exam?.cytomegalovirus_igm || 'NOT_EVALUATED',
		"cytomegalovirus_igg": exam?.cytomegalovirus_igg || 'NOT_EVALUATED',
		"date_day": date ? `${date.getDate() < 10 ? '0': ''}${date.getDate()}` : day,
		"date_month": date ? `${date.getMonth() + 1 < 10 ? '0': ''}${date.getMonth() + 1}` : month,
		"date_year": date ? `${date.getFullYear()}` : year,
	}
}

function parseSerologicalTestRubToxoCito(exam={}) {
	let date = buildDate(exam?.date_year, exam?.date_month, exam?.date_day, 10, 10)

	return {
		"id": exam?.id,
		"rubella_igm": exam?.rubella_igm || 'NOT_EVALUATED',
		"rubella_igg": exam?.rubella_igg || 'NOT_EVALUATED',
		"toxoplasmosis_igm": exam?.toxoplasmosis_igm || 'NOT_EVALUATED',
		"toxoplasmosis_igg": exam?.toxoplasmosis_igg || 'NOT_EVALUATED',
		"cytomegalovirus_igm": exam?.cytomegalovirus_igm || 'NOT_EVALUATED',
		"cytomegalovirus_igg": exam?.cytomegalovirus_igg || 'NOT_EVALUATED',
		"date": formatISO8601(date),
	}
}

export default function SerologicalTestRubToxoCitoWrite({ holdingSerologicalTestRubToxoCito=null, setModalInfo= () => null }) {
	const dispatch = useDispatch()
	const [data, setData] = useState(strpSerologicalTestRubToxoCito(holdingSerologicalTestRubToxoCito))
	const { selectedLanguage, fetchThousandDaysProjects, holdingProject } = useThousandDays()

	const {
		Reagent,
		Notreagent,
		Indeterminate,
		Notevaluated,
		Rubella,
		Toxoplasmosis,
		Cvm,
		Savedexam,
		Notsavedexam,
		Serologicaltestforrubella,
		Serologicaltestsave,
		Select_Months_Options,
		date,
		Day,
		Week,
		Year
	} = require('../../../../../../../../../../../../data/translation.json')[selectedLanguage]

	const handleSelect = event => {
		setData(prev => ({
			...prev,
			[`${event.target.id}`]: event.target.selected,
		}))
	}

	const handleSubmit = async () => {
		const payload = {
			...parseSerologicalTestRubToxoCito(data),
			person: holdingProject.patient.id,
			project: holdingProject.id,
		}

		try {
			if (payload?.id) {
				await api.patch(`/exam/serological_test_rub_toxo_cito/${payload.id}/`, payload)
			} else {
				await api.post('/exam/serological_test_rub_toxo_cito/', payload)
			}
			dispatch(AddAlert(Serologicaltestforrubella, Savedexam, AlertType.SUCCESS))
			fetchThousandDaysProjects()
			setModalInfo(MODAL_INIT_PROPS)
		} catch (err) {
			dispatch(AddAlert(Serologicaltestforrubella, Notsavedexam, AlertType.ERROR))
			console.error("SerologicalTestRubToxoCitoWrite ~ handleSubmit: ", err)
		}
	}

	const date_months_options = selectOptions_data.months.map((option) => ({ id: option.id, name: Select_Months_Options[option.id] }))

	return (
		<div>
			<div>
				<b>{date}</b>
				<div className='ABOGroupRHFactorWrite-Date'>
					<NewSelect
						id='date_day'
						options={selectOptions_data.days}
						selected={data.date_day}
						onSelect={handleSelect}
						canBeEmpty={false}
						defaultText={Day}
					/>
					<NewSelect
						id='date_month'
						options={date_months_options}
						selected={data.date_month}
						onSelect={handleSelect}
						canBeEmpty={false}
						defaultText={Week}
					/>
					<NewSelect
						id='date_year'
						options={getYearsStart1900()}
						selected={data.date_year}
						onSelect={handleSelect}
						canBeEmpty={false}
						defaultText={Year}
					/>
				</div>
			</div>
			<div className='SerologicalTestRubToxoCitoWrite-data SerologicalTestRubToxoCitoWrite-mt'>
				<NewSelect
					id='rubella_igm'
					label={`${Rubella} - IgM`}
					options={[
						{ id: 'NOT_EVALUATED', name: Notevaluated },
						{ id: 'REAGENT', name: Reagent },
						{ id: 'NON_REAGENT', name: Notreagent },
						{ id: 'INDETERMINATE', name: Indeterminate },
					]}
					onSelect={handleSelect}
					selected={data.rubella_igm}
					canBeEmpty={false}
				/>
				<NewSelect
					id='rubella_igg'
					label={`${Rubella} - IgG`}
					options={[
						{ id: 'NOT_EVALUATED', name: Notevaluated },
						{ id: 'REAGENT', name: Reagent },
						{ id: 'NON_REAGENT', name: Notreagent },
						{ id: 'INDETERMINATE', name: Indeterminate },
					]}
					onSelect={handleSelect}
					selected={data.rubella_igg}
					canBeEmpty={false}
				/>
				{data.rubella_igm &&
				data.rubella_igm !== 'NOT_EVALUATED' &&
				data.rubella_igm !== 'INDETERMINATE' &&
				data.rubella_igg &&
				data.rubella_igg !== 'NOT_EVALUATED' &&
				data.rubella_igg !== 'INDETERMINATE' ? (
					<AlertRowText
						{...getRubellaResult(
							data.rubella_igm,
							data.rubella_igg,
							selectedLanguage
						)}
					/>
				) : null}
			</div>
			<div className='SerologicalTestRubToxoCitoWrite-data'>
				<NewSelect
					id='toxoplasmosis_igm'
					label={`${Toxoplasmosis} - IgM`}
					options={[
						{ id: 'NOT_EVALUATED', name: Notevaluated },
						{ id: 'REAGENT', name: Reagent },
						{ id: 'NON_REAGENT', name: Notreagent },
						{ id: 'INDETERMINATE', name: Indeterminate },
					]}
					onSelect={handleSelect}
					selected={data.toxoplasmosis_igm}
					canBeEmpty={false}
				/>
				<NewSelect
					id='toxoplasmosis_igg'
					label={`${Toxoplasmosis} - IgG`}
					options={[
						{ id: 'NOT_EVALUATED', name: Notevaluated },
						{ id: 'REAGENT', name: Reagent },
						{ id: 'NON_REAGENT', name: Notreagent },
						{ id: 'INDETERMINATE', name: Indeterminate },
					]}
					onSelect={handleSelect}
					selected={data.toxoplasmosis_igg}
					canBeEmpty={false}
				/>
				{data.toxoplasmosis_igm &&
				data.toxoplasmosis_igm !== 'NOT_EVALUATED' &&
				data.toxoplasmosis_igm !== 'INDETERMINATE' &&
				data.toxoplasmosis_igg &&
				data.toxoplasmosis_igg !== 'NOT_EVALUATED' &&
				data.toxoplasmosis_igg !== 'INDETERMINATE' ? (
					<AlertRowText
						{...getToxoplasmosisResult(
							data.toxoplasmosis_igm,
							data.toxoplasmosis_igg,
							selectedLanguage
						)}
					/>
				) : null}
			</div>
			<div className='SerologicalTestRubToxoCitoWrite-data'>
				<NewSelect
					id='cytomegalovirus_igm'
					label={`${Cvm} - IgM`}
					options={[
						{ id: 'NOT_EVALUATED', name: Notevaluated },
						{ id: 'REAGENT', name: Reagent },
						{ id: 'NON_REAGENT', name: Notreagent },
						{ id: 'INDETERMINATE', name: Indeterminate },
					]}
					onSelect={handleSelect}
					selected={data.cytomegalovirus_igm}
					canBeEmpty={false}
				/>
				<NewSelect
					id='cytomegalovirus_igg'
					label={`${Cvm} - IgG`}
					options={[
						{ id: 'NOT_EVALUATED', name: Notevaluated },
						{ id: 'REAGENT', name: Reagent },
						{ id: 'NON_REAGENT', name: Notreagent },
						{ id: 'INDETERMINATE', name: Indeterminate },
					]}
					onSelect={handleSelect}
					selected={data.cytomegalovirus_igg}
					canBeEmpty={false}
				/>
				{data.cytomegalovirus_igm &&
				data.cytomegalovirus_igm !== 'NOT_EVALUATED' &&
				data.cytomegalovirus_igm !== 'INDETERMINATE' &&
				data.cytomegalovirus_igg &&
				data.cytomegalovirus_igg !== 'NOT_EVALUATED' &&
				data.cytomegalovirus_igg !== 'INDETERMINATE' ? (
					<AlertRowText
						{...getCytomegalovirusResult(
							data.cytomegalovirus_igm,
							data.cytomegalovirus_igg,
							selectedLanguage
						)}
					/>
				) : null}
			</div>
			<div className='AddNewExamModal-ButtonRow'>
				<Button
					label={Serologicaltestsave}
					onClick={handleSubmit}
				/>
			</div>
		</div>
	)
}
