import '../../../component/form/form.css'
import './select.css'
import React, { useEffect, useRef, useState } from 'react';
import Portal from '../../../component/potal/portal';
import useOutsideClick from '../../../component/useOusideClick/useoutsideclick';

export default function NewSelect({
    id='',
    options,
    label='',
    selected='',
    canBeEmpty=true,
    onSelect,
    closeDropwndownOnSelect=true,
    filterNode=null,
    defaultText='Selecione',
    optionRefKey='id',
    optionStrKey='name',
    customOption=null,
    disabled=false,
    error='',
    loadMoreText='',
    loadMoreFn=() => null,
}) {
    const [openDropdown, setOpenDropdown] = useState(false)
    const [dropdownStyles, setDropdownStyles] = useState({})
    const [optionsState, setOptionsState] = useState([])
    
    const handleDropdown = ({ event, value }) => {
        if (event) {
            const div = event.target
            let rect = div.getBoundingClientRect(),
                x = rect.left,
                y = rect.top,
                w = rect.width,
                h = rect.height;
            let windowHeight = window.innerHeight
            
            let dropdownDimension = Math.ceil(y + h) + 300 >= windowHeight
                ? { left: x, bottom: (windowHeight - y), top: 'auto' }
                : { left: x, top: (y + h) }
            setDropdownStyles({ ...dropdownDimension, position: 'absolute', width: w })
        }
        setOpenDropdown(value)
    }

    const ref = useRef()
    useOutsideClick(ref, e => {
        if (!e && openDropdown) handleDropdown({ value: false })
    })
    
    useEffect(() => {
        setOptionsState(options || [])
    }, [options])

    const strSelected = () => options.find(option => option[optionRefKey] === selected)?.[optionStrKey] || defaultText

    return <div className='SelectBox'>
        <label className='label-input'>{label}</label>
        <div>
            <button
                className={`select-selected ${openDropdown ? 'open' : ''} ${disabled ? 'select-disabled' : ''} ${error ? 'Select-Btn-Error' : ''}`}
                onClick={(event) => handleDropdown({ event: event, value: !disabled && !openDropdown})}
            >
                { strSelected() }
            </button>
            <Portal name='select'>
                { openDropdown
                    ? <div ref={ref} className='Select-DropdownBox' style={dropdownStyles}>
                        { filterNode }
                        <div className='select-options'>
                            {
                                canBeEmpty 
                                ? <div
                                    id={id}
                                    selected=''
                                    className={selected === '' ? 'selected' : ''}
                                    onClick={(event) => {
                                        event.target.selected = ''
                                        onSelect(event)
                                        if (closeDropwndownOnSelect) handleDropdown({ value: false })
                                    }}
                                >
                                    { defaultText }
                                </div>
                                : null
                            }
                            {
                                optionsState.map((option, idx) => <div
                                    id={id}
                                    selected={option[optionRefKey]}
                                    key={idx}
                                    className={selected === option[optionRefKey] ? 'selected' : ''}
                                    onClick={(event) => [onSelect(event), closeDropwndownOnSelect && handleDropdown({ value: false })]}
                                >
                                        { customOption ? customOption(option) : option[optionStrKey] }
                                </div>)
                            }
                            {
                                loadMoreText 
                                ? <div className='Select-LoadMoreText' onClick={loadMoreFn}>{loadMoreText}</div>
                                : null
                            }
                        </div>
                    </div> 
                    : null
                }
            </Portal>
        </div>
        { error ? <span className='Select-Text-Error'>{error}</span> : null }
    </div>
}