import React from "react"

export const renderSession = (title, content) => {
    const isEmpty = !content || content === '' || content === '<p><br></p>' || content.length === 0

	return (
		<React.Fragment>
			<strong>{title}</strong>
            {
                isEmpty
                ? <p style={{ fontSize: '0.875rem', color: '#aaa' }}>-</p>
                : <p style={{ fontSize: '0.875rem', color: '#aaa' }} dangerouslySetInnerHTML={{ __html: content }}></p>
            }
		</React.Fragment>
	)
}

export const renderRelatedDiseasesAndProblems = (related_diseases_and_problems) => {
    return (
		<div>
			<strong>Doenças e Problemas Relacionados (CID-10)</strong>
            {
                related_diseases_and_problems?.length
                ? <div style={{ paddingLeft: '0.5rem' }}>
                    {related_diseases_and_problems.map((cid, i) => {
                        return (
                            <p key={`${cid}-${i}`} style={{ fontSize: '0.875rem', color: '#aaa' }}>
                                <b>{cid.code}</b> - <span>{cid.title}</span>
                            </p>
                        )
                    })}
                </div>
                : <p style={{ fontSize: '0.875rem', color: '#aaa' }}>-</p>
            }
		</div>
	)
}

export const renderCIAP2Field = (reason_for_consultation) => {

    return (
		<div>
			<strong>Motivo de consulta (CIAP-2)</strong>
            {
                reason_for_consultation?.length
                ? <div style={{ paddingLeft: '0.5rem' }}>
                    {reason_for_consultation.map((cid, i) => {
                        return (
                            <p key={`${cid}-${i}`} style={{ fontSize: '0.875rem', color: '#aaa' }}>
                                <b>{cid.code}</b> - <span>{cid.title}</span>
                            </p>
                        )
                    })}
                </div>
                : <p style={{ fontSize: '0.875rem', color: '#aaa' }}>-</p>
            }
		</div>
	)
}

export const renderVitalSign = (vital_sign) => {
	const translationDictionary = {
		attendance: 'Atendimento',
		body_temperature: 'Temperatura Corporal',
		diastolic: 'Pressão Diastólica',
		heart_rate: 'Frequência Cardíaca',
		oxygen_saturation: 'Saturação de Oxigênio',
		respiration_rate: 'Frequência Respiratória',
		systolic: 'Pressão Sistólica',
	}

	const unitsDictionary = {
		attendance: '',
		body_temperature: '°C',
		diastolic: 'mmHg',
		heart_rate: 'bpm',
		oxygen_saturation: '%',
		respiration_rate: 'rpm',
		systolic: 'mmHg',
	}

	const elementsArray = vital_sign
		? Object.entries(vital_sign).map(([key, value]) => {
			if (key === 'attendance') {
				return null
			}

			const numericValue = typeof value === 'string' ? parseFloat(value) : value

			const formattedValue = key === 'body_temperature' && numericValue !== null
					? numericValue.toFixed(1)
					: Math.floor(numericValue)

			return (
				<p key={key} style={{ fontSize: '0.875rem', color: '#aaa' }}>
					<b>{translationDictionary[key]} - </b> {formattedValue}{' '}
					{unitsDictionary[key]}
				</p>
			)
		})
		: null

	return (
		<div>
            <strong>Sinais vitais</strong>
			{
				elementsArray?.length
				? <div style={{ paddingLeft: '0.5rem' }}>
					{ elementsArray.filter((element) => element !== null) }
				</div>
				: <p style={{ fontSize: '0.875rem', color: '#aaa' }}>-</p>
			}
		</div>
	)
}

export const renderAnthropometty = (anthropometry) => {
	const translationDictionary = {
		attendance: 'Atendimento',
		abdominal_circumference: 'Circunferência Abdominal',
		height: 'Altura',
		weight: 'Peso',
	}

	const unitsDictionary = {
		attendance: '',
		abdominal_circumference: 'cm',
		height: 'cm',
		weight: 'Kg',
	}
	
	const elementsArray = anthropometry
		? Object.entries(anthropometry).map(([key, value]) => {
			if (key === 'attendance') {
				return null
			}

			const numericValue = typeof value === 'string' ? parseFloat(value) : value

			return (
				<p key={key} style={{ fontSize: '0.875rem', color: '#aaa' }}>
					<b>{translationDictionary[key]} - </b> {Math.floor(numericValue)}{' '}
					{unitsDictionary[key]}
				</p>
			)
		})
		: null
	
	return (
		<div>
			<strong>Antropometria</strong>
			{
				elementsArray?.length
				? <div style={{ paddingLeft: '0.5rem' }}>
					{ elementsArray.filter((element) => element !== null) }
				</div>
				: <p style={{ fontSize: '0.875rem', color: '#aaa' }}>-</p>
			}
		</div>
	)
}