import './Birthforecast.css'
import React, { useEffect } from 'react'
import NewSelect from '../../../../../../../../../../newcomponents/form/select/select'
import { buildDate } from '../../../../../../../../../../utils/convertDate'
import { useThousandDays } from '../../../../ThousandDaysSection'

const selectOptions_data = require('../../../../../../../../../../component/form/select/options.json')

const getFullYears = () => {
	let years = []
	for (
		let i = new Date().getFullYear();
		i > new Date().getFullYear() - 2;
		i--
	) {
		years.push({ id: `${i}`, name: `${i}` })
	}
	return years.reverse()
}

export default function Birthforescast() {
	const {
		selectedLanguage,
		birthforecast,
		setBirthforecast,
		saveBirthForecast,
	} = useThousandDays()

	const {
		Day,
		Month,
		Year,
		SelectOptions_Data_Months_Options,
		Duedate,
		Deliverydate,
	} = require('../../../../../../../../../data/translation.json')[
		selectedLanguage
	]

	const selectOptions_data_months = selectOptions_data.months.map(option => ({
		id: option.id,
		name: SelectOptions_Data_Months_Options[option.id],
	}))

	useEffect(() => {
		if (
			buildDate(
				birthforecast.birthforecast_year,
				birthforecast.birthforecast_month,
				birthforecast.birthforecast_day
			)
		)
			saveBirthForecast()
	}, [
		birthforecast.birthforecast_day,
		birthforecast.birthforecast_month,
		birthforecast.birthforecast_year,
	])

	const handleSelectBirthForecast = event => {
		setBirthforecast(prev => ({
			...prev,
			[`${event.target.id}`]: event.target.selected,
		}))
	}

	return (
		<>
			<p className='Birthforecast-Subtitle'>{Duedate}</p>
			<div className='PregnancyRiskCalculator-Separator' />
			<strong>{Deliverydate}</strong>
			<div className='Birthforescast-DateRow'>
				<NewSelect
					id='birthforecast_day'
					options={selectOptions_data.days}
					onSelect={handleSelectBirthForecast}
					selected={birthforecast.birthforecast_day}
					defaultText={Day}
				/>
				<NewSelect
					id='birthforecast_month'
					options={selectOptions_data_months}
					onSelect={handleSelectBirthForecast}
					selected={birthforecast.birthforecast_month}
					defaultText={Month}
				/>
				<NewSelect
					id='birthforecast_year'
					options={getFullYears()}
					onSelect={handleSelectBirthForecast}
					selected={birthforecast.birthforecast_year}
					defaultText={Year}
				/>
			</div>
		</>
	)
}
