
import React from "react"
import { useDentistAppointment } from "../../DentistAppointment"
import NewSelect from "../../../../../../../../../../../../newcomponents/form/select/select"

const { assessmentOptions } = require('../../DentistAppointment.json')

export default function DentistAppointmentAssessmentAux({
	Visibleplaque,
	Spontaneousbleeding,
	Presenceofdentalcalculus,
	Presenceofmobility,
	Bleedingonprobing,
	Lossofattachmentwithadiagnosisofperiodontitis,
	Assessment_Options
}) {
    const { assessment, setAssessment } = useDentistAppointment()
    const assessment_options = assessmentOptions.map((option) => ({
			id: option.id,
			name: Assessment_Options[option.id],
		}))

	const handleSelect = (event) => {
		setAssessment((prev) => ({
			...prev,
			[event.target.id]: event.target.selected,
		}))
	}

	return (
		<div>
			<div className='DentistAppointmentAssessment-SelectGrid'>
				<div>
					<span>
						<strong>{Visibleplaque}</strong>
					</span>
					<NewSelect
						id='visible_plate'
						options={assessment_options}
						onSelect={handleSelect}
						selected={assessment.visible_plate}
						canBeEmpty={false}
					/>
				</div>
				<div>
					<span>
						<strong>{Spontaneousbleeding}</strong>
					</span>
					<NewSelect
						id='spontaneous_bleeding'
						options={assessment_options}
						onSelect={handleSelect}
						selected={assessment.spontaneous_bleeding}
						canBeEmpty={false}
					/>
				</div>
				<div>
					<span>
						<strong>{Presenceofdentalcalculus}</strong>
					</span>
					<NewSelect
						id='presence_of_dental_calculus'
						options={assessment_options}
						onSelect={handleSelect}
						selected={assessment.presence_of_dental_calculus}
						canBeEmpty={false}
					/>
				</div>
				<div>
					<span>
						<strong>{Presenceofmobility}</strong>
					</span>
					<NewSelect
						id='presence_of_mobility'
						options={assessment_options}
						onSelect={handleSelect}
						selected={assessment.presence_of_mobility}
						canBeEmpty={false}
					/>
				</div>
				<div>
					<span>
						<strong>{Bleedingonprobing}</strong>
					</span>
					<NewSelect
						id='bleeding_on_probing_in_at_least_10'
						options={assessment_options}
						onSelect={handleSelect}
						selected={assessment.bleeding_on_probing_in_at_least_10}
						canBeEmpty={false}
					/>
				</div>
				<div>
					<span>
						<strong>{Lossofattachmentwithadiagnosisofperiodontitis}</strong>
					</span>
					<NewSelect
						id='attachment_loss_with_diagnosis_of_periodontitis'
						options={assessment_options}
						onSelect={handleSelect}
						selected={
							assessment.attachment_loss_with_diagnosis_of_periodontitis
						}
						canBeEmpty={false}
					/>
				</div>
			</div>
		</div>
	)
}