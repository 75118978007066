import './HIVHbsAgAntiHCVVDRLWrite.css'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import Button from '../../../../../../../../../../../../../newcomponents/button/button'
import NewSelect from '../../../../../../../../../../../../../newcomponents/form/select/select'
import AlertRowText from '../../../../../../../../../../../../../component/alertRowText/alertRowText'
import {
	AddAlert,
	AlertType,
} from '../../../../../../../../../../../../../component'
import api from '../../../../../../../../../../../../../helpers/api'
import {
	getAntiHCVResult,
	getHIVResult,
	getHbsAgResult,
	getVDRLResult,
} from '../utils'
import { useThousandDays } from '../../../../../../../ThousandDaysSection'
import { MODAL_INIT_PROPS } from '../../../../../../../../../../../../../newcomponents/modal/modal'
import getCurrentDate from '../../../../../../../../../../../../../utils/getCurrentDate'
import { buildDate, formatISO8601 } from '../../../../../../../../../../../../../utils/convertDate'
import { getYearsStart1900 } from '../../../../../../../../../../../../../utils/getYearsStart1900'

const selectOptions_data = require('../../../../../../../../../../../../../component/form/select/options.json')

function strpSerologicalTestHIVHbsAgAntiHCVVDRL(exam={}) {
	let { day, month, year } = getCurrentDate()

	let date = exam?.date ? new Date(exam.date) : ''

	return {
		"id": exam?.id,
		"hiv": exam?.hiv || 'NOT_EVALUATED',
		"hbsag": exam?.hbsag || 'NOT_EVALUATED',
		"anti_hcv": exam?.anti_hcv || 'NOT_EVALUATED',
		"vdrl": exam?.vdrl || 'NOT_EVALUATED',
		"date_day": date ? `${date.getDate() < 10 ? '0': ''}${date.getDate()}` : day,
		"date_month": date ? `${date.getMonth() + 1 < 10 ? '0': ''}${date.getMonth() + 1}` : month,
		"date_year": date ? `${date.getFullYear()}` : year,
	}
}

function parseSerologicalTestHIVHbsAgAntiHCVVDRL(exam={}) {
	let date = buildDate(exam?.date_year, exam?.date_month, exam?.date_day, 10, 10)

	return {
		"id": exam?.id,
		"hiv": exam?.hiv || 'NOT_EVALUATED',
		"hbsag": exam?.hbsag || 'NOT_EVALUATED',
		"anti_hcv": exam?.anti_hcv || 'NOT_EVALUATED',
		"vdrl": exam?.vdrl || 'NOT_EVALUATED',
		"date": formatISO8601(date),
	}
}

export default function SerologicalTestHIVHbsAgAntiHCVVDRLWrite({ holdingSerologicalTestHIVHbsAgAntiHCVVDRL=null, setModalInfo= () => null }) {
	const dispatch = useDispatch()
	const [data, setData] = useState(strpSerologicalTestHIVHbsAgAntiHCVVDRL(holdingSerologicalTestHIVHbsAgAntiHCVVDRL))
	const { selectedLanguage, fetchThousandDaysProjects, holdingProject } = useThousandDays()

	const {
		Change,
		Reagent,
		Notreagent,
		Indeterminate,
		Notevaluated,
		Greaterthan,
		Savedexam,
		Notsavedexam,
		Serologicaltestsave,
		Serologicaltestforhivhbsagantihcvvddrl,
		Select_Months_Options,
		date,
		Day,
		Week,
		Year,
	} = require('../../../../../../../../.././../../../data/translation.json')[
		selectedLanguage
	]

	const handleSelect = event => {
		setData(prev => ({
			...prev,
			[`${event.target.id}`]: event.target.selected,
		}))
	}

	const handleSubmit = async () => {
		const payload = {
			...parseSerologicalTestHIVHbsAgAntiHCVVDRL(data),
			person: holdingProject.patient.id,
			project: holdingProject.id,
		}

		try {
			if (payload?.id) {
				await api.patch(`/exam/serological_test_hiv_hbs_hcv_vdrl/${payload.id}/`, payload)
			} else {
				await api.post('/exam/serological_test_hiv_hbs_hcv_vdrl/', payload)
			}
			dispatch(AddAlert(Serologicaltestforhivhbsagantihcvvddrl, Savedexam, AlertType.SUCCESS))
			fetchThousandDaysProjects()
			setModalInfo(MODAL_INIT_PROPS)
		} catch (err) {
			dispatch(AddAlert(Serologicaltestforhivhbsagantihcvvddrl, Notsavedexam, AlertType.ERROR))
			console.error("HIVHbsAgAntiHCVVDRLWrite ~ handleSubmit: ", err)
		}
	}

	const date_months_options = selectOptions_data.months.map((option) => ({ id: option.id, name: Select_Months_Options[option.id] }))

	return (
		<>
			<div>
				<b>{date}</b>
				<div className='ABOGroupRHFactorWrite-Date'>
					<NewSelect
						id='date_day'
						options={selectOptions_data.days}
						selected={data.date_day}
						onSelect={handleSelect}
						canBeEmpty={false}
						defaultText={Day}
					/>
					<NewSelect
						id='date_month'
						options={date_months_options}
						selected={data.date_month}
						onSelect={handleSelect}
						canBeEmpty={false}
						defaultText={Week}
					/>
					<NewSelect
						id='date_year'
						options={getYearsStart1900()}
						selected={data.date_year}
						onSelect={handleSelect}
						canBeEmpty={false}
						defaultText={Year}
					/>
				</div>
			</div>
			<div className='SerologicalTestHIVHbsAgAntiHCVVDRLWrite-DataRow'>
				<NewSelect
					id='vdrl'
					label='VDRL'
					options={[
						{ id: 'NOT_EVALUATED', name: Notevaluated },
						{ id: 'INDETERMINATE', name: Indeterminate },
						{ id: 'NON_REAGENT', name: Notreagent },
						{ id: 'REAGENT', name: Reagent },
						{ id: 'ONE_BY_ONE', name: '1 / 1' },
						{ id: 'ONE_BY_TWO', name: '1 / 2' },
						{ id: 'ONE_BY_FOUR', name: '1 / 4' },
						{ id: 'ONE_BY_EIGHT', name: '1 / 8' },
						{ id: 'ONE_BY_SIXTEEN', name: '1 / 16' },
						{ id: 'ONE_BY_THIRTY_TWO', name: '1 / 32' },
						{ id: 'ONE_BY_SIXTY_FOUR', name: '1 / 64' },
						{ id: 'GREATER_ONE_BY_SIXTY_FOUR', name: `${Greaterthan} 1 / 64` },
					]}
					onSelect={handleSelect}
					selected={data.vdrl}
					canBeEmpty={false}
				/>
				{data.vdrl &&
				data.vdrl !== 'NOT_EVALUATED' &&
				data.vdrl !== 'INDETERMINATE' ? (
					<AlertRowText {...getVDRLResult(data.vdrl, Change)} />
				) : null}
			</div>
			<div className='SerologicalTestHIVHbsAgAntiHCVVDRLWrite-DataRow'>
				<NewSelect
					id='hbsag'
					label='HbsAg'
					options={[
						{ id: 'NOT_EVALUATED', name: Notevaluated },
						{ id: 'INDETERMINATE', name: Indeterminate },
						{ id: 'NON_REAGENT', name: Notreagent },
						{ id: 'REAGENT', name: Reagent },
					]}
					onSelect={handleSelect}
					selected={data.hbsag}
					canBeEmpty={false}
				/>
				{data.hbsag &&
				data.hbsag !== 'NOT_EVALUATED' &&
				data.hbsag !== 'INDETERMINATE' ? (
					<AlertRowText {...getHbsAgResult(data.hbsag, Change)} />
				) : null}
			</div>
			<div className='SerologicalTestHIVHbsAgAntiHCVVDRLWrite-DataRow'>
				<NewSelect
					id='anti_hcv'
					label='Anti-HCV'
					options={[
						{ id: 'NOT_EVALUATED', name: Notevaluated },
						{ id: 'INDETERMINATE', name: Indeterminate },
						{ id: 'NON_REAGENT', name: Notreagent },
						{ id: 'REAGENT', name: Reagent },
					]}
					onSelect={handleSelect}
					selected={data.anti_hcv}
					canBeEmpty={false}
				/>
				{data.anti_hcv &&
				data.anti_hcv !== 'NOT_EVALUATED' &&
				data.anti_hcv !== 'INDETERMINATE' ? (
					<AlertRowText {...getAntiHCVResult(data.anti_hcv, Change)} />
				) : null}
			</div>
			<div className='SerologicalTestHIVHbsAgAntiHCVVDRLWrite-DataRow'>
				<NewSelect
					id='hiv'
					label='HIV'
					options={[
						{ id: 'NOT_EVALUATED', name: Notevaluated },
						{ id: 'INDETERMINATE', name: Indeterminate },
						{ id: 'NON_REAGENT', name: Notreagent },
						{ id: 'REAGENT', name: Reagent },
					]}
					onSelect={handleSelect}
					selected={data.hiv}
					canBeEmpty={false}
				/>
				{data.hiv &&
				data.hiv !== 'NOT_EVALUATED' &&
				data.hiv !== 'INDETERMINATE' ? (
					<AlertRowText {...getHIVResult(data.hiv, Change)} />
				) : null}
			</div>
			<div className='AddNewExamModal-ButtonRow'>
				<Button
					label={Serologicaltestsave}
					onClick={handleSubmit}
				/>
			</div>
		</>
	)
}
