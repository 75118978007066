import './AttachDocumentSection.css';
import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AddAlert, AlertType, Input, MaskDataHora, RadioButton, TextareaEditor, adicionaZero } from '../../../../../../../../../../../../../component';
import NewButton, { ButtonModel } from '../../../../../../../../../../../../../newcomponents/button/button';
import Modal, { MODAL_INIT_PROPS } from '../../../../../../../../../../../../../newcomponents/modal/modal';
import { v4 as uuidv4 } from 'uuid';
import api from '../../../../../../../../../../../../../helpers/api';
import { loaded, loading } from '../../../../../../../../../../../../../layout/redux/AppActions';
import { useInternetSpeed } from '../../../../../../../../../../../../../utils/useInternetSpeed';
import CalendarDateModal from '../../../../../../../../../../../../../component/calendar/CalendarDateModal';
import { formatDateWithTimezoneFromDateObj, formatISO8601Str } from '../../../../../../../../../../../../../utils/convertDate';

const { attachOptions } = require('../../../../../DocumentsEmission.json');

const INIT_SELECTED_DOCUMENT = { category: 'RECIPE', description: '', attach: null, document_date: new Date() };

const CHUNK_SIZE = 5 * 1024 * 1024; // 5MB

export default function AttachDocumentSection({ person = null, attendance = null, setModalInfo = () => null }) {
    const dispatch = useDispatch();
    const fileInputRef = useRef(null);
    const speed = useInternetSpeed()
    const [secondaryModal, setSecondaryModal] = useState(MODAL_INIT_PROPS)
    const [selectedDocument, setSelectedDocument] = useState(INIT_SELECTED_DOCUMENT);
    const [downloadProgress, setDownloadProgress] = useState(0);

    const handleSelectCategory = (event) => {
        setSelectedDocument(prev => ({ ...prev, category: event.target.value.id }));
    };

    const handleFileChange = (event) => {
        processFiles(event.target.files);
    };

    const processFiles = async (files) => {
        if (files && files.length > 0) {
            const file = files[0];
            if (file.size / 1024 ** 2 > 250) {
                dispatch(AddAlert('Arquivo Grande', 'O arquivo não deve exceder 250MB', AlertType.ERROR));
                return;
            }

            setSelectedDocument(prev => ({ ...prev, attach: file }));
        } else {
            console.error('No file selected');
        }
    };

    const handleUploadClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDrop = (event) => {
        event.preventDefault();
        processFiles(event.dataTransfer.files);
    };

    const handleRemove = () => {
        setSelectedDocument(INIT_SELECTED_DOCUMENT);
        setDownloadProgress(0);
    };

    const handleSubmit = async () => {
        const file = selectedDocument.attach;
        const document_date = selectedDocument?.document_date ? formatDateWithTimezoneFromDateObj(selectedDocument?.document_date, '00', '00') : null
        if (!file) {
            dispatch(AddAlert('Encaminhamento', 'Por favor, anexe um arquivo', AlertType.ERROR));
            return;
        }

        if (file.size / 1024 ** 2 > 250) {
            dispatch(AddAlert('Encaminhamento', 'O arquivo não deve exceder 250MB', AlertType.ERROR));
            return;
        }

        const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
        const uploadId = uuidv4();
        const title = attachOptions?.find(op => op?.id === selectedDocument.category)?.name || ''
    
        const uploadChunk = async (chunk, chunkIndex) => {
            const formData = new FormData();
            formData.append('file', chunk);
            formData.append('chunkIndex', chunkIndex);
            formData.append('totalChunks', totalChunks);
            formData.append('filename', file.name);
            formData.append('description', selectedDocument.description);
            formData.append('category', selectedDocument.category);
            formData.append('title', title)
            formData.append('upload_id', uploadId)
            formData.append('document_date', document_date)

            if (person?.id) {
                formData.append('person', person?.id)
            } else if (attendance?.person?.id) {
                formData.append('person', attendance?.person?.id)
            }

            if (attendance?.id) {
                formData.append('attendance', attendance?.id)
            }
            dispatch(loading())
            try {
                await api.post('/appointment/upload/health_document_chunk', formData, {
                    onDownloadProgress: (progressEvent) => {
                        if (progressEvent.currentTarget?.responseText) {
                            const response = JSON.parse(progressEvent.currentTarget.responseText);
                            const { chunk_index, total_chunks } = response;

                            const progress = ((chunk_index + 1) / total_chunks) * 100;
                            setDownloadProgress(Math.min(progress, 100));
                        }
                    },
                });
            } catch (error) {
                dispatch([
                    loaded(),
                    AddAlert('Encaminhamento', 'Falha ao tentar enviar encaminhamento, tente novamente!', AlertType.ERROR)
                ]);
                throw error;
            }
            dispatch(loaded())
        };

        const readAndUploadChunks = async () => {
            try {
                let offset = 0;
                let chunkIndex = 0;
                while (offset < file.size) {
                    const chunk = file.slice(offset, offset + CHUNK_SIZE);
                    await uploadChunk(chunk, chunkIndex);
                    offset += CHUNK_SIZE;
                    chunkIndex++;
                }
                dispatch([AddAlert('Encaminhamento', 'Encaminhamento feito com sucesso!', AlertType.SUCCESS)]);
                document.dispatchEvent(new CustomEvent('DocumentsHistoryFetchActivities'));
                setModalInfo(MODAL_INIT_PROPS);
                setDownloadProgress(0);
            } catch (error) {
                dispatch([AddAlert('Encaminhamento', 'Falha ao tentar enviar encaminhamento, tente novamente!', AlertType.ERROR)]);
                setDownloadProgress(0);
            }
        };

        readAndUploadChunks();
    };

    const openCalendarDateModal = (key, modalTitle) => {
        const handleSelectDateObj = (name, {yearSearchCursor, monthSearchCursor, daySearchCursor}) => {
            let nextDate = new Date(yearSearchCursor, parseInt(monthSearchCursor, 10) - 1, daySearchCursor)
            setSelectedDocument(prev => ({ ...prev, [name]: nextDate }))
        }

		const date_cursor = selectedDocument?.[key];
		const currentDate = date_cursor ? new Date(date_cursor) : new Date();
	
		const currentDay = String(adicionaZero(currentDate.getDate()));
		const currentMonth = String(adicionaZero(currentDate.getMonth() + 1));
		const currentYear = String(currentDate.getFullYear());
		
		setSecondaryModal(prev => ({
			...prev,
			open: true,
			title: modalTitle,
			content: (
				<CalendarDateModal
					name={key}
					currentDay={currentDay}
					currentMonth={currentMonth}
					currentYear={currentYear}
					renderNextSelectedDay={(dateEvent) => {
						handleSelectDateObj(key, dateEvent);
						setSecondaryModal(MODAL_INIT_PROPS);
					}}
				/>
			)
		}));
	};

    return (
        <div className='AttachDocumentSection'>
            <Modal {...secondaryModal} dismissFn={() => setModalInfo(MODAL_INIT_PROPS)} />
            <div className="AttachDocumentSection-container-body">
                {
                    speed && speed < 1 
                    ? <p className='AttachDocumentSection-Asterisk'>*A velocidade da sua internet está lenta: {speed} Mbps. Isso irá causar maior tempo necessário para o envio de arquivos e navegação na plataforma.</p> 
                    : null
                }
                <p className="AttachDocumentSection-container-description">Anexe o arquivo abaixo</p>
                <RadioButton
                    name='category'
                    action={handleSelectCategory}
                    checked={selectedDocument.category}
                    options={attachOptions}
                />
                <div className='AttachDocumentSection-DocumentDate'>
                    <span className='AttachDocumentSection-container-description'>Data do Documento</span>
                    <Input
                        label=''
                        name='document_date'
                        placeholder='DD/MM/YYYY'
                        actionFocus={() => openCalendarDateModal('document_date', 'Data do Documento')}
                        value={formatISO8601Str(selectedDocument?.document_date)}
                    />
                </div>
                {
                    !selectedDocument.attach
                        ? (
                            <>
                                <button
                                    className="AttachDocumentSection-upload-area"
                                    onClick={handleUploadClick}
                                    onDragOver={handleDragOver}
                                    onDrop={handleDrop}
                                >
                                    <span className="AttachDocumentSection-upload-area-icon">
                                        <svg width="34px" height="42px" viewBox="0 0 340.531 419.116">
                                            <g id="files-new" clipPath="url(#clip-files-new)">
                                                <path id="Union_2" data-name="Union 2" d="M-2904.708-8.885A39.292,39.292,0,0,1-2944-48.177V-388.708A39.292,39.292,0,0,1-2904.708-428h209.558a13.1,13.1,0,0,1,9.3,3.8l78.584,78.584a13.1,13.1,0,0,1,3.8,9.3V-48.177a39.292,39.292,0,0,1-39.292,39.292Zm-13.1-379.823V-48.177a13.1,13.1,0,0,0,13.1,13.1h261.947a13.1,13.1,0,0,0,13.1-13.1V-323.221h-52.39a26.2,26.2,0,0,1-26.194-26.195v-52.39h-196.46A13.1,13.1,0,0,0-2917.805-388.708Zm146.5,241.621a14.269,14.269,0,0,1-7.883-12.758v-19.113h-68.841c-7.869,0-7.87-47.619,0-47.619h68.842v-18.8a14.271,14.271,0,0,1,7.882-12.758,14.239,14.239,0,0,1,14.925,1.354l57.019,42.764c.242.185.328.485.555.671a13.9,13.9,0,0,1,2.751,3.292,14.57,14.57,0,0,1,.984,1.454,14.114,14.114,0,0,1,1.411,5.987,14.006,14.006,0,0,1-1.411,5.973,14.653,14.653,0,0,1-.984,1.468,13.9,13.9,0,0,1-2.751,3.293c-.228.2-.313.485-.555.671l-57.019,42.764a14.26,14.26,0,0,1-8.558,2.847A14.326,14.326,0,0,1-2771.3-147.087Z" transform="translate(2944 428)" fill="var(--c-action-primary)" />
                                            </g>
                                        </svg>
                                    </span>
                                    <span className="AttachDocumentSection-upload-area-title">Arraste o arquivo aqui para fazer upload.</span>
                                    <span className="AttachDocumentSection-upload-area-description">
                                        Alternativamente, você pode selecionar um arquivo <br /><strong>clicando aqui</strong>
                                    </span>
                                </button>
                                <form>
                                    <input
                                        type="file"
                                        ref={fileInputRef}
                                        onChange={handleFileChange}
                                        style={{ display: 'none' }}
                                    />
                                </form>
                            </>
                        )
                        : (
                            <>
                                <div>
                                    <TextareaEditor
                                        label='Descrição'
                                        name='description'
                                        action={(event) => setSelectedDocument(prev => ({ ...prev, description: event.target.value }))}
                                        value={selectedDocument.description}
                                    />
                                    <div>
                                        <p><b>{selectedDocument.attach.name}</b></p>
                                        <p>Tipo do arquivo: {selectedDocument.attach.type}</p>
                                        <p>Tamanho: {(selectedDocument.attach.size / 1024 ** 2).toFixed(2)} MB</p>
                                        <p>Última modificação: {MaskDataHora(selectedDocument.attach.lastModifiedDate).data} {MaskDataHora(selectedDocument.attach.lastModifiedDate).hora}</p>
                                    </div>
                                </div>
                                <div className='AttachDocumentSection-BtnBox'>
                                    <NewButton
                                        label={`Remover ${selectedDocument.attach.name}`}
                                        model={ButtonModel.SECONDARY}
                                        onClick={handleRemove}
                                        disabled={downloadProgress}
                                    />
                                    <NewButton
                                        onClick={handleSubmit}
                                        label='Enviar arquivo'
                                        disabled={downloadProgress}
                                    />
                                </div>
                            </>
                        )
                }
                {downloadProgress > 0 && (
                    <div className="AttachDocumentSection-upload-progress">
                        <div className="AttachDocumentSection-progress-bar" style={{ width: `${downloadProgress}%` }}>
                            {downloadProgress.toFixed(2)}%
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
