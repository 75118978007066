import './AssessmentUnit.css'
import React from "react";
import { renderRelatedDiseasesAndProblems, renderSession } from '../helpers';


export default function AssessmentUnit({ attendance }) {
    
    return <div className='AssessmentUnit'>
        <div className='AssessmentUnit-Subtitle'><b>Avaliação</b></div>
        <div className='AssessmentUnit-Content' style={{ color: '#aaa'}}>
            { 
                attendance?.related_diseases_and_problems?.length 
                ? <div>{renderRelatedDiseasesAndProblems(attendance.related_diseases_and_problems)}</div>
                : null
            }
            {
                attendance?.diagnosis
                ? <div>{renderSession('Hipóteses diagnósticas', attendance.diagnosis)}</div>
                : null
            }
            
        </div>
    </div>
}