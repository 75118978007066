import './NewAppointmentModal.css'
import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { AddAlert, AlertType, Input, InputOnlyVisible } from '../../../../../../component'
import { uniqueIDs } from '../../../../../../utils/uniqueIDs'
import NewSelect from '../../../../../../newcomponents/form/select/select'
import { getHealthInsurances } from '../../../../../configuration/http'
import { getParams } from '../../../../../../utils'
import NewButton from '../../../../../../newcomponents/button/button'
import Modal, { MODAL_INIT_PROPS } from '../../../../../../newcomponents/modal/modal'
import { loaded, loading } from '../../../../../../layout/redux/AppActions'
import { getCollaborators } from '../../../../../collaborators/http'
import { useApp } from '../../../../../../layout/App'
import { patchAttendance, postNewImmediateAppointment } from '../../../../http'
import { stringifyCollaboratorOption } from '../../../../../../utils/stringifyCollaboratorOption'
import SelectPatient from '../../../../../../component/SelectPatient/SelectPatient'
import { getSpecialties } from '../../../../../agenda/SelectScheduleHealthPlaceUserManager/http'
import { getAppointmentServiceLocation } from '../../../../../configuration/subcomponents/HealthUnitConfig/HealthPlaceInstitutionalMenu/http'
import api from '../../../../../../helpers/api'
import ConfirmAssign from '../../../BookingAppointment/subcomponents/BookingAppointmentCursor/subcomponents/TimeSchedule/subcomponents/AssignScheduleToPatient/ConfirmAssign/ConfirmAssign'

const { 
    DECISION_CHOICES, 
    RESULT_CHOICES, 
    CLASSIFICATION_CHOICES, 
    PRIORITY_CHOICES 
} = require('../../../../../../component/form/select/options.json')

const strpSelectedAppointment = (initHoldingAppointment=null) => {
    return {
        id: initHoldingAppointment?.id || null,
        doctor: initHoldingAppointment?.doctor || {},
        person: initHoldingAppointment?.person || {},
        result: initHoldingAppointment?.result || 'NORMAL',
        decision: initHoldingAppointment?.decision || 'OPEN',
        classification: initHoldingAppointment?.classification || 'EMERGENCY',
        specialty: initHoldingAppointment?.specialty || '',
        health_insurance: initHoldingAppointment?.health_insurance || '',
        priority: initHoldingAppointment?.priority || 'NO_PRIORITY',
        service_location: initHoldingAppointment?.service_location || '',
    }
}

const INIT_APPOINTMENT_ERR = { "person": false, "doctor": false }

export default function NewAppointmentModal({ initAppointment=null, fetchAppointments=() => null, setModalInfo=() => null }) {
    const dispatch = useDispatch()
    const history = useHistory()
    const { currentHealthPlaceUser, isAdminOrAssistant } = useApp()
    const searchTimeout = useRef(null)
    const [holdingAppointment, setHoldingAppointment] = useState(strpSelectedAppointment(initAppointment))
    const [holdingAppointmentErr, setHoldingAppointmentErr] = useState(INIT_APPOINTMENT_ERR)
    const [healthInsurances, setHealthInsurances] = useState([])
    const [specialtiesOptions, setSpecialtiesOptions] = useState([])
    const [collaboratorOptions, setCollaboratorOptions] = useState([])
    const [serviceLocationOptions, setServiceLocationOptions] = useState([])
    const [secondaryModalInfo, setSecondaryModalInfo] = useState(MODAL_INIT_PROPS)
	const healthPlace = currentHealthPlaceUser?.health_place

    useEffect(() => {
        fetchCollaborators()
        fetchSpecialtiesOptions({ offset: 0 })
        fetchHealthInsurances({ offset: 0 })
        fetchServiceLocationsOptions()
    }, [])

    const handleSelectPatient = (selectedPatient) => {
        setHoldingAppointmentErr(INIT_APPOINTMENT_ERR)
        setHoldingAppointment(prev => ({ ...prev, "person": selectedPatient }))
    }

    const continueSubmission = async (patient, redirect) => {
        let response = await api.post(`patient/activate_patient/${patient.id}/`)
  
        if (response.status !== 200) {
          dispatch(AddAlert('Ativar Paciente', 'Houve um erro ao ativar o paciente', AlertType.ERROR))
          return
        } else {
          handleSubmit(true)
        }
      }
  
    const cancelSubmission = () => {
        setSecondaryModalInfo(MODAL_INIT_PROPS)
        setHoldingAppointment((prev => ({ ...prev, "person": {} })))
      }

    const handleSubmit = async (ignoreInactivate=false) => {
        if (!holdingAppointment?.person?.id) {
            setHoldingAppointmentErr(prev => ({ ...prev, "person": true }))
            return
        }

        if (!ignoreInactivate) {
            if (!holdingAppointment?.person?.is_active) {
              setSecondaryModalInfo({ 
                open: true,
                title: 'Paciente Inativado',
                content: <ConfirmAssign 
                    patient={holdingAppointment?.person} 
                    cancelSubmission={cancelSubmission} 
                    continueSubmission={continueSubmission}/>,
              })
    
              return
            }
          }

        if (!holdingAppointment?.doctor?.id) {
            setHoldingAppointmentErr(prev => ({ ...prev, "doctor": true }))
            return
        }

        const payload = {
            "person": holdingAppointment?.person?.id,
            "doctor": holdingAppointment?.doctor?.id,
            "result": holdingAppointment?.result,
            "classification": holdingAppointment?.classification,
            "priority": holdingAppointment?.priority,
            "decision": holdingAppointment?.decision,
            "specialty": holdingAppointment?.specialty?.id,
            "health_insurance": holdingAppointment?.health_insurance?.id,
            "service_location": holdingAppointment?.service_location?.id,
            "health_place": currentHealthPlaceUser?.health_place?.id,
        }

        if (holdingAppointment?.id) {
            dispatch(loading())
            await patchAttendance(holdingAppointment.id, payload)
                .then(() => {
                    fetchAppointments({ offset: 0 })
                    dispatch([loaded(), AddAlert('Editar atendimento', 'Atendimento salvo com sucesso', AlertType.SUCCESS)])
                    setModalInfo(MODAL_INIT_PROPS)
                })
                .catch(err => {
                    dispatch([loaded(), AddAlert('Editar atendimento', 'Falha ao editar atendimento', AlertType.ERROR)])
                    console.error('patchAttendance ~ ', err)
                })
        } else {
            dispatch(loading())
            await postNewImmediateAppointment(payload)
                .then(async res => {
                    dispatch([loaded(), AddAlert('Novo atendimento', 'Atendimento criado com sucesso', AlertType.SUCCESS)])
                    //if (isAdminOrAssistant) {
                    //    fetchAppointments()
                    //} else {
                    //    history.push(`/consultation/${res?.data?.new_attendance_id}`)
                    //}
                    fetchAppointments()
                    setModalInfo(MODAL_INIT_PROPS)
                })
                .catch(err => {
                    dispatch([loaded(), AddAlert('Novo atendimento', 'Falha ao criar atendimento', AlertType.ERROR)])
                    console.error('postNewImmediateAppointment ~ ', err)
                })
        }
    }

    const fetchSpecialtiesOptions = async (params={}) => {
        params = { ...params, limit: 50 }

        try {
            let res = await getSpecialties(params)

            setSpecialtiesOptions(res.data.results)
        } catch(err) {
            console.error('fetchSpecialtiesOptions ~ ', err)
        }
    }

    const fetchServiceLocationsOptions = async (params={}) => {
        try {
            let res = await getAppointmentServiceLocation({...params, limit: 500 })
            setServiceLocationOptions(res.data.results)
        } catch (err) {
            setServiceLocationOptions([])
            console.error('fetchServiceLocationsOptions ~ ', err)
        }
    }

    const fetchHealthInsurances = async (params={}) => {
        params = getParams({ ...params, limit: 50 })

        try {
            let res = await getHealthInsurances(params)
            setHealthInsurances(res.data.results)
        } catch (err) {
            console.error('fetchHealthInsurances ~ ', err)
            dispatch(AddAlert('Planos & convênios', 'Erro ao listar planos e convênios', AlertType.ERROR))
        }
    }

    const handleSearchHealthInsurance = (event) => {
        if (searchTimeout.current) clearTimeout(searchTimeout.current)

        searchTimeout.current = setTimeout(() => {
            fetchHealthInsurances({ 'name': event.target.value })
        }, 400, event.target.value)
    }

    const handleSearchSpecialties = (event) => {
        if (searchTimeout.current) clearTimeout(searchTimeout.current)

        searchTimeout.current = setTimeout(() => {
            fetchSpecialtiesOptions({ name__icontains: event.target.value })
        }, 400, event.target.value)
    }
    
    const handleSearchServiceLocations = (event) => {
        if (searchTimeout.current) clearTimeout(searchTimeout.current)

        searchTimeout.current = setTimeout(() => {
            fetchServiceLocationsOptions({ name__icontains: event.target.value })
        }, 400, event.target.value)
    }

    const fetchCollaborators = async params => {
		await getCollaborators({
			...params,
			health_place__id: healthPlace?.id,
			limit: 500,
			profile__in: 'DOCTOR%2CDENTIST%2CPSYCHOLOGIST%2CNUTRITIONIST%2CNURSE',
			has_person: true
		})
			.then(res => setCollaboratorOptions(res.data.results.map((instance, index) => {
                if (index === 0 && !holdingAppointment?.doctor?.id) {
                    setHoldingAppointment(prev => ({ 
                        ...prev, 
                        "doctor": stringifyCollaboratorOption(instance),
                        "service_location": instance?.current_service_location
                    }))
                }
                return stringifyCollaboratorOption(instance)
            })))
			.catch(err => {
				console.error('fetchCollaborators', err)
				setCollaboratorOptions([])
			})
	}

    const handleSearchCollaborators = (event) => {
        if (searchTimeout.current) clearTimeout(searchTimeout.current)
    
        searchTimeout.current = setTimeout(() => {
                fetchCollaborators({ 'name_cpf_or_email': event.target.value })
        }, 400, event.target.value)
    }

    const handleSelectHealthProfessional = (event) => {
        setHoldingAppointmentErr(INIT_APPOINTMENT_ERR)
        const newCollaborator = collaboratorOptions?.find(({ id }) => id === event.target.selected)

        setHoldingAppointment(prev => ({ 
            ...prev, 
            "doctor": newCollaborator, 
            "service_location": newCollaborator?.health_place_user__current_service_location 
        }))
    }

    return (
    <div className='NewAppointmentModal'>
        <Modal {...secondaryModalInfo} dismissFn={() => setSecondaryModalInfo(MODAL_INIT_PROPS)}/>
        <div className='NewAppointmentModal-Grid'>
            <SelectPatient 
                selectedPatient={holdingAppointment.person} 
                handleSelectPatient={handleSelectPatient}
                inputErr={holdingAppointmentErr?.person}
            />
        <div className='NewAppointmentModal-Separator' />
        <div>
            <div>
                <NewSelect
                    label='Profissional'
                    options={collaboratorOptions}
                    selected={holdingAppointment?.doctor?.id}
                    optionStrKey='str'
                    onSelect={handleSelectHealthProfessional}
                    canBeEmpty={false}
                    filterNode={<div className='ManageTeamEdgesModal-NewSelect'>
                        <Input 
                            placeholder='Pesquisar por e-mail, nome ou cpf'
                            action={handleSearchCollaborators}
                            actionFocus={()=> fetchCollaborators()}
                        />
                    </div>
                    }
                    error={holdingAppointmentErr?.doctor}
                />
            </div>
       
            <div className='NewAppointmentModal-2FRRow'>
                <div>
                    <NewSelect 
                        label='Categoria *'
                        onSelect={event => setHoldingAppointment(prev => ({ ...prev, "classification": event.target.selected }))}
                        canBeEmpty={false}
                        options={CLASSIFICATION_CHOICES}
                        selected={holdingAppointment?.classification || ''}
                    />
                </div>
                <div>
                    <NewSelect 
                        label='Status *'
                        onSelect={event => setHoldingAppointment(prev => ({ ...prev, "decision": event.target.selected }))}
                        canBeEmpty={false}
                        options={DECISION_CHOICES}
                        selected={holdingAppointment?.decision || ''}
                    />
                </div>
            </div>
            <div className='NewAppointmentModal-2FRRow'>
                <div>
                    <NewSelect 
                        label='Tipo *'
                        onSelect={event => setHoldingAppointment(prev => ({ ...prev, "result": event.target.selected }))}
                        canBeEmpty={false}
                        options={RESULT_CHOICES}
                        selected={holdingAppointment?.result || ''}
                    />
                </div>
                <div>
                    <NewSelect 
                        label='Prioridade *'
                        onSelect={event => setHoldingAppointment(prev => ({ ...prev, "priority": event.target.selected }))}
                        canBeEmpty={false}
                        options={PRIORITY_CHOICES}
                        selected={holdingAppointment?.priority || ''}
                    />
                </div>
            </div>

            <div>
                <NewSelect 
                    label='Convênio'
                    onSelect={event => {
                            setHoldingAppointment(prev => ({ 
                                ...prev, 
                                "health_insurance": prev?.health_insurance?.id != event.target.selected 
                                    ? healthInsurances?.find(({ id }) => id === event.target.selected)
                                    : ''
                            }))
                            fetchHealthInsurances({ offset: 0 })
                        }
                    }
                    canBeEmpty={false}
                    options={uniqueIDs(holdingAppointment?.health_insurance, healthInsurances)}
                    selected={holdingAppointment?.health_insurance?.id || ''}
                    filterNode={<div className='NewAppointmentModal-NewSelect'>
                        <Input 
                            placeholder='Pesquisar por nome'
                            action={handleSearchHealthInsurance}
                            actionFocus={()=> fetchHealthInsurances()}
                        />
                    </div>
                    }
                />
            </div>
            <div>
                <NewSelect 
                    label='Especialidade'
                    onSelect={event => {
                            setHoldingAppointment(prev => ({ 
                                ...prev, 
                                "specialty": prev?.specialty?.id != event.target.selected 
                                    ? specialtiesOptions?.find(({ id }) => id === event.target.selected)
                                    : ''
                            }))
                            fetchSpecialtiesOptions({ offset: 0 })
                        }
                    }     
                    canBeEmpty={false}
                    options={uniqueIDs(holdingAppointment?.specialty, specialtiesOptions)}
                    optionRefKey='id'
                    optionStrKey='strf'
                    selected={holdingAppointment?.specialty?.id || ''}
                    filterNode={<div className='NewAppointmentModal-NewSelect'>
                        <Input 
                            placeholder='Pesquisar por nome'
                            action={handleSearchSpecialties}
                            actionFocus={()=> fetchSpecialtiesOptions()}
                        />
                    </div>
                    }
                />
            </div>
            <div>
                <NewSelect 
                    label='Local de Atendimento'
                    onSelect={event => {
                            setHoldingAppointment(prev => ({ 
                                ...prev, 
                                "service_location": prev?.service_location?.id != event.target.selected 
                                    ? serviceLocationOptions?.find(({ id }) => id === event.target.selected)
                                    : ''
                            }))
                            fetchServiceLocationsOptions({ offset: 0 })
                        }
                    }     
                    canBeEmpty={false}
                    options={uniqueIDs(holdingAppointment?.service_location, serviceLocationOptions)}
                    optionRefKey='id'
                    optionStrKey='name'
                    selected={holdingAppointment?.service_location?.id || ''}
                    filterNode={<div className='NewAppointmentModal-NewSelect'>
                        <Input 
                            placeholder='Pesquisar por nome'
                            action={handleSearchServiceLocations}
                            actionFocus={()=> fetchServiceLocationsOptions()}
                        />
                    </div>
                    }
                />
            </div>
            {
                /*
            <div>
                <InputOnlyVisible
                    label='Hora início'
                    value={
                        initAppointment?.start
                        ? String(new Date(initAppointment.start))?.slice(16,21)
                        : String(new Date())?.slice(16,21)
                    }
                />
            </div>
                 */
            }
        </div>
    </div>
    <div className='NewAppointmentModal-BtnBox'>
        <NewButton
            label='Salvar'
            onClick={() => handleSubmit()}
        />
    </div>
    </div>
  )
}
