import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import Button from '../../../../../../../../../../../newcomponents/button/button'
import { AddAlert, AlertType, Input } from '../../../../../../../../../../../component'
import { getFetalMovementsResult, getHeartbeatResult, getPresentationResult, getUterineHeightResult, strpPreNatalAttendanceEditor } from '../utils'
import NewSelect from '../../../../../../../../../../../newcomponents/form/select/select'
import AlertRowText from '../../../../../../../../../../../component/alertRowText/alertRowText'
import { MODAL_INIT_PROPS } from '../../../../../../../../../../../newcomponents/modal/modal'
import { Mask1DecimalPlace } from '../../../../../../../../../../../component/mask/mask'
import { loaded, loading } from '../../../../../../../../../../../layout/redux/AppActions'
import { putPreNatalAttendance } from '../../../../../http'
import { useThousandDays } from '../../../../../ThousandDaysSection'
import { getPressureResult } from '../../../../../../Objective/VitalSignsAnthropometry/utils'

const pregnancyattendance_data = require('../../../../../../../../../../thousandDays/data/PregnancyAttendance.json')


export default function PreNatalAttendanceEditor({ initPreNatalAttendanceEditor=null, previousPrenatalAttendances=[] }) {
	const dispatch = useDispatch()
	const textareaRef = useRef(null)
	const [preNatalAttendanceEditor, setPreNatalAttendanceEditor] = useState(strpPreNatalAttendanceEditor(initPreNatalAttendanceEditor))

	const { holdingProject, selectedLanguage, setModalInfo, getPregnancyAgeAt, fetchThousandDaysProjects } = useThousandDays()

	const savePreNatalAttendance = async () => {
        dispatch(loading())
        try {
            if (holdingProject?.id) {
                await putPreNatalAttendance(preNatalAttendanceEditor, holdingProject?.id)
                dispatch([loaded(), AddAlert('Atendimento pré-natal', 'Atendimento pré-natal salvo', AlertType.SUCCESS)])
                await fetchThousandDaysProjects()
                setModalInfo(MODAL_INIT_PROPS)
            }
        } catch (err) {
            dispatch([loaded(), AddAlert('Atendimento pré-natal', 'Falha ao salvar atendimento pré-natal', AlertType.ERROR)])
            console.error('savePreNatalAttendance', err)
        }
    }

	const {
		Maternalweight,
		Appointmentdate,
		Day,
		Week,
		Year,
		Fetalmovements,
		Fetalheartbeats,
		Presentation,
		Uterineheight,
		Maternalcomplaints,
        BloodPressure,
        Systolic,
        Diastolic,
		Saved,
		Maincomplaintsofthepatient,
		Fetalmovements_Options,
		Presentation_Options,
		Change,
		SelectOptions_Data_Months_Options,
	} = require('../../../../../../../../../../data/translation.json')[selectedLanguage]

	const selectOptions_data = require('../../../../../../../../../../../component/form/select/options.json')

	const selectOptions_data_months = selectOptions_data.months.map(option => ({
		id: option.id,
		name: SelectOptions_Data_Months_Options[option.id],
	}))

	const adjustTextareaHeight = () => {
		textareaRef.current.style.height = 'auto'
		const scrollHeight = textareaRef.current.scrollHeight
		const maxHeight = parseInt(
			window.getComputedStyle(textareaRef.current).maxHeight,
			10
		)
		if (scrollHeight > maxHeight) {
			textareaRef.current.style.height = `${maxHeight}px`
		} else {
			textareaRef.current.style.height = `${scrollHeight}px`
		}
	}

	const getFullYears = () => {
		let years = []
		for (
			let i = new Date().getFullYear();
			i > new Date().getFullYear() - 2;
			i--
		) {
			years.push({ id: `${i}`, name: `${i}` })
		}
		return years.reverse()
	}

	const handleChange = event => {
		let cleanedValue = event.target.value.replace(/\D/g, '')

		if (
			event.target.name === 'uterine_height' ||
			event.target.name === 'weight'
		) {
			cleanedValue = Mask1DecimalPlace(cleanedValue)
		}
		setPreNatalAttendanceEditor(prev => ({
			...prev,
			[`${event.target.name}`]: cleanedValue,
		}))
	}

	const handleChangeById = event => {
		setPreNatalAttendanceEditor(prev => ({
			...prev,
			patient_complaints: event.target.value,
		}))
		adjustTextareaHeight()
	}

	const handleSelect = event => {
		setPreNatalAttendanceEditor(prev => ({
			...prev,
			[`${event.target.id}`]: event.target.selected,
		}))
	}

	const fetalmovements_options = pregnancyattendance_data.fetalmovements.map(
		option => ({
			id: option.id,
			name: Fetalmovements_Options[option.id],
		})
	)

	const presentation_options = pregnancyattendance_data.presentation.map(
		option => ({
			id: option.id,
			name: Presentation_Options[option.id],
		})
	)

	return (
		<div className='PreNatalAttendanceEditor'>
			<div>
				<b>{Appointmentdate}</b>
				<div className='PreNatalAttendanceEditor-DateRow'>
					<NewSelect
						id='prenatal_attendance_day'
						options={selectOptions_data.days}
						selected={preNatalAttendanceEditor.prenatal_attendance_day}
						onSelect={handleSelect}
						defaultText={Day}
						disabled={false}
						canBeEmpty={false}
					/>
					<NewSelect
						id='prenatal_attendance_month'
						options={selectOptions_data_months}
						selected={preNatalAttendanceEditor.prenatal_attendance_month}
						onSelect={handleSelect}
						defaultText={Week}
						canBeEmpty={false}
					/>
					<NewSelect
						id='prenatal_attendance_year'
						options={getFullYears()}
						selected={preNatalAttendanceEditor.prenatal_attendance_year}
						onSelect={handleSelect}
						defaultText={Year}
						canBeEmpty={false}
					/>
				</div>
			</div>
			<div className='PreNatalAttendanceEditor-FARow'>
				<div>
					<b>{Fetalmovements}</b>
					<div className='PreNatalAttendanceEditor-SelectInput'>
						<NewSelect
							id='fetal_movements'
							options={fetalmovements_options}
							selected={preNatalAttendanceEditor.fetal_movements}
							onSelect={handleSelect}
							canBeEmpty={false}
						/>
						{preNatalAttendanceEditor.fetal_movements ? (
							<AlertRowText
								{...getFetalMovementsResult(
									preNatalAttendanceEditor,
									previousPrenatalAttendances,
									selectedLanguage
								)}
							/>
						) : (
							<div />
						)}
					</div>
				</div>
				<div>
					<b>{Fetalheartbeats}</b>
					<div className='PreNatalAttendanceEditor-HU-H-Input'>
						<Input
							name='heartbeat'
							placeholder='bpm'
							maxLength={3}
							value={preNatalAttendanceEditor.heartbeat}
							action={handleChange}
							autoComplete='off'
						/>
						{preNatalAttendanceEditor.heartbeat ? (
							<AlertRowText
								{...getHeartbeatResult(preNatalAttendanceEditor.heartbeat, selectedLanguage)}
							/>
						) : (
							<div />
						)}
					</div>
				</div>
			</div>
			<div className='PreNatalAttendanceEditor-HU'>
				<div>
					<b>{Presentation}</b>
					<div className='PreNatalAttendanceEditor-SelectInput'>
						<NewSelect
							id='presentation'
							options={presentation_options}
							selected={preNatalAttendanceEditor.presentation}
							onSelect={handleSelect}
							canBeEmpty={false}
						/>
						{preNatalAttendanceEditor.presentation ? (
							<AlertRowText
								{...getPresentationResult(
									preNatalAttendanceEditor,
									getPregnancyAgeAt(
										preNatalAttendanceEditor.prenatal_attendance_date
									).age,
									selectedLanguage
								)}
							/>
						) : (
							<div />
						)}
					</div>
				</div>
				<div>
					<b>{Uterineheight}</b>
					<div className='PreNatalAttendanceEditor-HU-H-Input'>
						<Input
							name='uterine_height'
							placeholder='cm'
							maxLength={4}
							value={preNatalAttendanceEditor.uterine_height}
							action={handleChange}
							autoComplete='off'
						/>
						{preNatalAttendanceEditor.uterine_height ? (
							<AlertRowText
								{...getUterineHeightResult(
									preNatalAttendanceEditor,
									getPregnancyAgeAt(
										preNatalAttendanceEditor.prenatal_attendance_date
									).age,
									Change
								)}
							/>
						) : (
							<div />
						)}
					</div>
				</div>
				<div>
					<b>{Maternalweight}</b>
					<div className='PreNatalAttendanceEditor-HU-H-Input'>
						<Input
							name='weight'
							placeholder='kg'
							value={preNatalAttendanceEditor.weight}
							maxLength={5}
							action={handleChange}
							autoComplete='off'
						/>
					</div>
				</div>
                <div>
					<b>{BloodPressure}</b>
					<div className='PreNatalAttendanceEditor-Pressure'>
						<Input
							name='systolic'
							placeholder={Systolic}
							maxLength={3}
							value={preNatalAttendanceEditor.systolic}
							action={handleChange}
							autoComplete='off'
						/>
						<Input
							name='diastolic'
							placeholder={Diastolic}
							maxLength={3}
							value={preNatalAttendanceEditor.diastolic}
							action={handleChange}
							autoComplete='off'
						/>
                        {preNatalAttendanceEditor.systolic && preNatalAttendanceEditor.diastolic ? (
                            <AlertRowText {...getPressureResult(preNatalAttendanceEditor.systolic, preNatalAttendanceEditor.diastolic, selectedLanguage)} />
                        ) : null}
					</div>
				</div>
			</div>
			<div>
				<p>
					<b>{Maternalcomplaints}</b>
				</p>
				<div className='PreNatalAttendanceEditor-PatientComplaints'>
					<textarea
						ref={textareaRef}
						id='treatment_plan'
						placeholder={Maincomplaintsofthepatient}
						className='PreNatalAttendanceEditor-PatientComplaints-TextEditor'
						value={preNatalAttendanceEditor.patient_complaints}
						onChange={handleChangeById}
						rows='5'
					/>
				</div>
			</div>
			<div className='PreNatalAttendanceEditor-BtnBox'>
				<Button
					label={Saved}
					onClick={savePreNatalAttendance}
				/>
			</div>
		</div>
	)
}