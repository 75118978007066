import './FastingBloodGlucoseView.css'
import React from 'react'
import { Button, IcoBin, IcoPencil, List } from '../../../../../../../../../../../../../component'
import AlertRowText from '../../../../../../../../../../../../../component/alertRowText/alertRowText'
import { getGlycemiaResult, getTotgResult } from '../utils'
import { useThousandDays } from '../../../../../../../ThousandDaysSection'
import FastingBloodGlucoseWrite from '../Write/FastingBloodGlucoseWrite'
import { convertDate } from '../../../../../../../../../../../../../utils/convertDate'
import api from '../../../../../../../../../../../../../helpers/api'

export default function FastingBloodGlucoseView() {
	const { 
		selectedLanguage, 
		holdingProject: { glucose_exams }, 
		setModalInfo,
		fetchThousandDaysProjects
	} = useThousandDays()

	const {
		High,
		Dates,
		Collectedafteronehour,
		Collectedaftertwohour,
		Bloodglucose,
		Conclusion,
		Dextrolgap,
		NOT_EVALUATED,
		Notinformation
	} = require('../../../../../../../../../../../../data/translation.json')[
		selectedLanguage
	]

	const translate = {
		ONE_HOUR: Collectedafteronehour,
		TWO_HOURS: Collectedaftertwohour,
		NOT_EVALUATED: NOT_EVALUATED
	}

	const handleDelete = async (id) => {
		await api.delete(`exam/fasting_blood_glucose/${id}/`)
		fetchThousandDaysProjects()
	}

	const openExamWriteModal = (holdingExam) => {
		setModalInfo(prev => ({
			...prev,
			open: true,
			title: Bloodglucose,
			content: (
				<div className='SOAP-ThousandDaysExamSubSection-Modal'>
					<FastingBloodGlucoseWrite 
						holdingFastingBloodGlucose={holdingExam} 
						setModalInfo={setModalInfo} 
					/>
				</div>
			),
		}))
	}

	const headDefaultGlycemia = [
		{ colunm: 'h_glycemia', text: Bloodglucose },
		{ colunm: 'h_glycemia_conclusion', text: Conclusion, width: '120px' },
		{ colunm: 'h_date', text: Dates, width: '100px' },
		{ colunm: 'h_action', text: '', width: '100px' },
	]

	const headDefaultTOTG = [
		{ colunm: 'h_totg', text: 'TOTG' },
		{ colunm: 'h_totg_interval', text: Dextrolgap },
		{ colunm: 'h_totg_conclusion', text: Conclusion, width: '120px' },
		{ colunm: 'h_date', text: Dates, width: '100px' },
		{ colunm: 'h_action', text: '', width: '100px' },
	]

	return (
		<div className='FastingBloodGlucoseView'>
			<div className='FastingBloodGlucoseView-List'>
				<List
					head={headDefaultGlycemia}
					data={glucose_exams}
					noData={Notinformation}
					listCustom={(exam, i) => {
						exam['h_glycemia'] = exam?.glycemia || '-'
						exam['h_glycemia_conclusion'] = exam?.glycemia ? <AlertRowText {...getGlycemiaResult(exam.glycemia, High)} /> : '-'
						exam['h_date'] = exam?.date ? convertDate(exam.date, '-', '/') : '-'
						exam['h_action'] = (
							<div>
								<Button
									color='secondary'
									type='btn circle'
									action={() => openExamWriteModal(exam)}
									title='Editar exame'>
									<IcoPencil />
								</Button>
								<Button
									color='danger'
									type='btn circle'
									action={() => handleDelete(exam?.id)}
									title='Excluir exame'>
									<IcoBin />
								</Button>
							</div>
						)
						return exam
					}}
				/>
				<List
					head={headDefaultTOTG}
					data={glucose_exams}
					noData={Notinformation}
					listCustom={(exam, i) => {
						exam['h_totg'] = exam?.totg || '-'
						exam['h_totg_interval'] = translate[exam?.totg_interval] || '-'
						exam['h_totg_conclusion'] = exam?.totg && exam?.totg_interval 
							? <AlertRowText {...getTotgResult(exam.totg, exam.totg_interval, High)} />
							: '-'
						exam['h_date'] = exam?.date ? convertDate(exam.date, '-', '/') : '-'
						exam['h_action'] = (
							<div>
								<Button
									color='secondary'
									type='btn circle'
									action={() => openExamWriteModal(exam)}
									title='Editar exame'>
									<IcoPencil />
								</Button>
								<Button
									color='danger'
									type='btn circle'
									action={() => handleDelete(exam?.id)}
									title='Excluir exame'>
									<IcoBin />
								</Button>
							</div>
						)
						return exam
					}}
				/>
			</div>
		</div>
	)
}
