import AlertRowText, {
	AlertRowTextType,
} from '../../../../../../../../../../../../component/alertRowText/alertRowText'

const treponemal_test_translate = require('../../../../../../../../../../../data/translation.json')

export function getTreponemalResult(
	exam,
	Notconfirmedsyphillis,
	NotDidTrepomic,
	NOT_EVALUATED
) {
	if (exam?.result === 'NOT_EVALUATED' || !exam?.result) {
		return {
			type: AlertRowTextType.INFO,
			content: NOT_EVALUATED }
	}else if (
		!(exam?.result === 'REAGENT' && exam?.non_treponemal_result?.vdrl === 'REAGENT') && !(exam.result === 'REAGENT' && !exam.previous_treatment)
	) {
		return {
			type: AlertRowTextType.SUCCESS,
			content: Notconfirmedsyphillis }
	} else {
		return {
			type: AlertRowTextType.WARNING,
			content: NotDidTrepomic,
		}
	}
}

export function getTreponemalResults(
	treponemal_test_exams = [],
	selectedLanguage
) {
	let alterations = []
	const { Syphillis } = treponemal_test_translate[selectedLanguage]

	for (let i = 0; i < treponemal_test_exams.length; i++) {
		let treponemal = getTreponemalResult(treponemal_test_exams[i])
		if (treponemal?.type === AlertRowTextType.WARNING) {
			alterations.push({ type: AlertRowTextType.INFO, content: Syphillis })
			break
		}
	}

	return alterations
}
