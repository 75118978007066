import { AlertRowTextType } from '../../../../../../../../../../../../component/alertRowText/alertRowText'

const serological_test_rub_translate = require('../../../../../../../../../../../data/translation.json')

export function getRubellaResult(igm, igg, selectedLanguage) {
	const { Change, Immune, Notimmune } =
		serological_test_rub_translate[selectedLanguage]
	if (
		(igm === 'REAGENT' && igg === 'REAGENT') ||
		(igm === 'REAGENT' && igg === 'NON_REAGENT')
	) {
		return { type: AlertRowTextType.WARNING, content: Change }
	} else if (igm === 'NON_REAGENT' && igg === 'REAGENT') {
		return { type: AlertRowTextType.SUCCESS, content: Immune }
	} else if (igm === 'NON_REAGENT' && igg === 'NON_REAGENT') {
		return { type: AlertRowTextType.INFO, content: Notimmune }
	} else {
		return { type: '', content: '' }
	}
}

export function getToxoplasmosisResult(igm, igg, selectedLanguage) {
	const { Suspected, Immune, Changematernalinfection, Notimmune } =
		serological_test_rub_translate[selectedLanguage]
	if (igm === 'REAGENT' && igg === 'NON_REAGENT') {
		return {
			type: AlertRowTextType.WARNING,
			content: Changematernalinfection,
		}
	} else if (igm === 'REAGENT' && igg === 'REAGENT') {
		return { type: AlertRowTextType.WARNING, content: Suspected }
	} else if (igm === 'NON_REAGENT' && igg === 'REAGENT') {
		return { type: AlertRowTextType.SUCCESS, content: Immune }
	} else if (igm === 'NON_REAGENT' && igg === 'NON_REAGENT') {
		return { type: AlertRowTextType.INFO, content: Notimmune }
	} else {
		return { type: '', content: '' }
	}
}

export function getCytomegalovirusResult(igm, igg, selectedLanguage) {
	const { Change, Immune, Normalnonimmune } =
		serological_test_rub_translate[selectedLanguage]
	if (
		(igm === 'REAGENT' && igg === 'REAGENT') ||
		(igm === 'REAGENT' && igg === 'NON_REAGENT')
	) {
		return { type: AlertRowTextType.WARNING, content: Change }
	} else if (igm === 'NON_REAGENT' && igg === 'REAGENT') {
		return { type: AlertRowTextType.SUCCESS, content: Immune }
	} else if (igm === 'NON_REAGENT' && igg === 'NON_REAGENT') {
		return {
			type: AlertRowTextType.INFO,
			content: Normalnonimmune,
		}
	} else {
		return { type: '', content: '' }
	}
}

export function getRubellaToxoplasmosisCytomegalovirusResults(
	rub_toxo_cito_exams = [],
	selectedLanguage
) {
	let alterations = []
	const { Rubella, Toxoplasmosis, Cvm } =
		serological_test_rub_translate[selectedLanguage]

	for (let i = 0; i < rub_toxo_cito_exams.length; i++) {
		let rubella = getRubellaResult(
			rub_toxo_cito_exams[i].rubella_igm,
			rub_toxo_cito_exams[i].rubella_igg,
			selectedLanguage
		)
		if (rubella?.type === AlertRowTextType.WARNING) {
			alterations.push({ type: AlertRowTextType.INFO, content: Rubella })
			break
		}
	}

	for (let i = 0; i < rub_toxo_cito_exams.length; i++) {
		let toxoplasmosis = getToxoplasmosisResult(
			rub_toxo_cito_exams[i].toxoplasmosis_igm,
			rub_toxo_cito_exams[i].toxoplasmosis_igg,
			selectedLanguage
		)
		if (toxoplasmosis?.type === AlertRowTextType.WARNING) {
			alterations.push({ type: AlertRowTextType.INFO, content: Toxoplasmosis })
			break
		}
	}

	for (let i = 0; i < rub_toxo_cito_exams.length; i++) {
		let cytomegalovirus = getCytomegalovirusResult(
			rub_toxo_cito_exams[i].cytomegalovirus_igm,
			rub_toxo_cito_exams[i].cytomegalovirus_igg,
			selectedLanguage
		)
		if (cytomegalovirus?.type === AlertRowTextType.WARNING) {
			alterations.push({
				type: AlertRowTextType.INFO,
				content: Cvm,
			})
			break
		}
	}

	return alterations
}
