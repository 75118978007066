import './SignDocumentsModal.css'
import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { AddAlert, AlertType, Input } from '../../../../../../../../../../../../component';
import Button, { ButtonModel } from '../../../../../../../../../../../../newcomponents/button/button';
import { MODAL_INIT_PROPS } from '../../../../../../../../../../../../newcomponents/modal/modal';
import api from '../../../../../../../../../../../../helpers/api';
import { loaded, loading } from '../../../../../../../../../../../../layout/redux/AppActions';
import { history } from '../../../../../../../../../../../../helpers';

export default function SignDocumentsModal({ attendance=null, selectedDocument=null, setModalInfo=() => null }) {
    const dispatch = useDispatch()
    const [codeError, setCodeError] = useState(false)

    useEffect(() => {
        const inputElements = [...document.querySelectorAll('#id-codeinput')]
        inputElements.forEach((node, index)=> {
            node.addEventListener('keydown', (event) => {
                if (event.keyCode === 8 && event.target.value === '') inputElements[Math.max(0, index-1)].focus()
            })
            node.addEventListener('input', (event) => {
                setCodeError(false)
                const [first,...rest] = event.target.value
                event.target.value = first ?? ''
                const lastInputBox = index === inputElements.length-1
                const didInsertContent = first !== undefined
                if (didInsertContent && !lastInputBox) {
                    inputElements[index+1].focus()
                    inputElements[index+1].value = rest.join('')
                    inputElements[index+1].dispatchEvent(new Event('input'))
                }
            })
        })
    }, [])

    const handleSubmit = () => {
        const inputElements = [...document.querySelectorAll('#id-codeinput')]
        const code = inputElements.map(node => node.value).join('')
        
        if (!selectedDocument) return dispatch(AddAlert('Assinatura digital', 'Nenhum documento selecionado para assinatura.', AlertType.ERROR))
    
        let payload = { code, activities: [selectedDocument?.id], category: selectedDocument?.category }

        if (code.length !== 6) return setCodeError(true)
        
        dispatch(loading())
        api.post(`appointment/document/sign/${attendance?.id}`, payload)
            .then(() => {
                dispatch([loaded(), AddAlert('Assinatura digital', 'Documentos assinados e salvos com sucesso', AlertType.SUCCESS)])
                document.dispatchEvent(new CustomEvent("DocumentsHistoryFetchActivities"))
                setModalInfo(MODAL_INIT_PROPS)
            })
            .catch(err => {
                setCodeError(true)
                console.error(err)
                dispatch(loaded())
                let error = err?.response?.data?.error || err?.response?.data?.detail
                const errorMessages = {
                    "Patient Address can't be empty": 'O paciente não possui endereço cadastrado, solicite o cadastro do mesmo para criar documentos em atendimento!',
                    "Doctor Address can't be empty": () => {
                        error = 'Cadastre seu endereço para criar documento!';
                        history.push('/configuration/address')
                    },
                    "Health Place Address can't be empty": 'O local de atendimento não possui endereço cadastrado, solicite o cadastro do mesmo em conta administradora para criar documentos em atendimento!',
                    "Route exclusive for health professional": 'Apenas médicos e dentistas podem criar documento(s)',
                    "No documents found for signature": 'Nenhum documento foi achado',
                    "Bird ID token length can't be different 6": 'O Token OTP precisa possuir 6 dígitos',
                    "Unauthorized user": 'O usuário não foi autorizado, verifique seu CPF e código OTP usados na tentativa!',
                    "There was an error signing documents": 'Houve um erro na tentativa de assinar os documento(s)'
                };

                error = errorMessages[error] || 'Houve um erro ao criar documento(s)';

                if (typeof error === 'function') {
                    error();
                }

                dispatch(AddAlert('Assinatura digital', error, AlertType.ERROR))
            })
    }

    return <div className="SignDocumentsModal">
        <div className='SignDocumentsModal-SignInputs'>
            <p>Verifique o código OTP no app do <b>BirdID</b> para assinar digitalmente seus documentos.</p>
            <div className='SignDocumentsModal-VerificationCode'>
                <Input name='codeinput' maxLength={1} errors={{ error: { codeinput: codeError }, message: codeError }} />
                <Input name='codeinput' maxLength={1} errors={{ error: { codeinput: codeError }, message: codeError }} />
                <Input name='codeinput' maxLength={1} errors={{ error: { codeinput: codeError }, message: codeError }} />
                <Input name='codeinput' maxLength={1} errors={{ error: { codeinput: codeError }, message: codeError }} />
                <Input name='codeinput' maxLength={1} errors={{ error: { codeinput: codeError }, message: codeError }} />
                <Input name='codeinput' maxLength={1} errors={{ error: { codeinput: codeError }, message: codeError }} />
            </div>
        </div>
        <div className='SignDocumentsModal-BtnBox'>
            <Button 
                model={ButtonModel.SECONDARY}
                label='Fechar'
                onClick={() => setModalInfo(MODAL_INIT_PROPS)}
            />
            <Button 
                label='Assinar'
                onClick={handleSubmit}
            />
        </div>
    </div>
}