import { AlertRowTextType } from '../../../../../../../../../../component/alertRowText/alertRowText'
import { objectsOnlyWithIDs } from '../../../../../../../../../../utils'
import { buildDate } from '../../../../../../../../../../utils/convertDate'


export const strpPartograph = (partograph = null) => {
	let formatNumField = num => String(num).replace('.', ',')

	let admission_date = partograph?.admission_date
		? new Date(partograph?.admission_date)
		: null
	let birthtime_date = partograph?.birthtime_date
		? new Date(partograph?.birthtime_date)
		: null

	return {
		project: partograph?.project || -1,

		stage_labour: partograph?.stage_labour || '',
		category: partograph?.category || '',
		complications: partograph?.complications || '',
		bleeding: partograph?.bleeding || '',

		birthtime_day: birthtime_date
			? `${birthtime_date.getDate() < 10 ? '0' : ''}${birthtime_date.getDate()}`
			: '',
		birthtime_month: birthtime_date
			? `${birthtime_date.getMonth() + 1 < 10 ? '0' : ''}${
					birthtime_date.getMonth() + 1
			  }`
			: '',
		birthtime_year: birthtime_date ? `${birthtime_date.getFullYear()}` : '',
		birthtime_hour: birthtime_date ? `${birthtime_date.getHours()}` : '',
		birthtime_minute: birthtime_date ? `${birthtime_date.getMinutes()}` : '',

		anesthesia_types: partograph?.anesthesia_types || 'NOT_EVALUATED',
		episiotomy: partograph?.episiotomy || 'NOT_EVALUATED',
		estimated_blood_loss: partograph?.estimated_blood_loss
			? String(partograph.estimated_blood_loss)
			: '',

		cervical_dilation_1: partograph?.cervical_dilation_1
			? formatNumField(partograph.cervical_dilation_1)
			: '',
		cervical_dilation_2: partograph?.cervical_dilation_2
			? formatNumField(partograph.cervical_dilation_2)
			: '',
		cervical_dilation_3: partograph?.cervical_dilation_3
			? formatNumField(partograph.cervical_dilation_3)
			: '',
		cervical_dilation_4: partograph?.cervical_dilation_4
			? formatNumField(partograph.cervical_dilation_4)
			: '',
		cervical_dilation_5: partograph?.cervical_dilation_5
			? formatNumField(partograph.cervical_dilation_5)
			: '',
		cervical_dilation_6: partograph?.cervical_dilation_6
			? formatNumField(partograph.cervical_dilation_6)
			: '',
		cervical_dilation_7: partograph?.cervical_dilation_7
			? formatNumField(partograph.cervical_dilation_7)
			: '',
		cervical_dilation_8: partograph?.cervical_dilation_8
			? formatNumField(partograph.cervical_dilation_8)
			: '',
		cervical_dilation_9: partograph?.cervical_dilation_9
			? formatNumField(partograph.cervical_dilation_9)
			: '',
		cervical_dilation_10: partograph?.cervical_dilation_10
			? formatNumField(partograph.cervical_dilation_10)
			: '',
		cervical_dilation_11: partograph?.cervical_dilation_11
			? formatNumField(partograph.cervical_dilation_11)
			: '',
		cervical_dilation_12: partograph?.cervical_dilation_12
			? formatNumField(partograph.cervical_dilation_12)
			: '',

		amniotic_fluid_1: partograph?.amniotic_fluid_1 || '',
		amniotic_fluid_2: partograph?.amniotic_fluid_2 || '',
		amniotic_fluid_3: partograph?.amniotic_fluid_3 || '',
		amniotic_fluid_4: partograph?.amniotic_fluid_4 || '',
		amniotic_fluid_5: partograph?.amniotic_fluid_5 || '',
		amniotic_fluid_6: partograph?.amniotic_fluid_6 || '',
		amniotic_fluid_7: partograph?.amniotic_fluid_7 || '',
		amniotic_fluid_8: partograph?.amniotic_fluid_8 || '',
		amniotic_fluid_9: partograph?.amniotic_fluid_9 || '',
		amniotic_fluid_10: partograph?.amniotic_fluid_10 || '',
		amniotic_fluid_11: partograph?.amniotic_fluid_11 || '',
		amniotic_fluid_12: partograph?.amniotic_fluid_12 || '',

		contractions_in_10_min_1: partograph?.contractions_in_10_min_1 || '',
		contractions_in_10_min_2: partograph?.contractions_in_10_min_2 || '',
		contractions_in_10_min_3: partograph?.contractions_in_10_min_3 || '',
		contractions_in_10_min_4: partograph?.contractions_in_10_min_4 || '',
		contractions_in_10_min_5: partograph?.contractions_in_10_min_5 || '',
		contractions_in_10_min_6: partograph?.contractions_in_10_min_6 || '',
		contractions_in_10_min_7: partograph?.contractions_in_10_min_7 || '',
		contractions_in_10_min_8: partograph?.contractions_in_10_min_8 || '',
		contractions_in_10_min_9: partograph?.contractions_in_10_min_9 || '',
		contractions_in_10_min_10: partograph?.contractions_in_10_min_10 || '',
		contractions_in_10_min_11: partograph?.contractions_in_10_min_11 || '',
		contractions_in_10_min_12: partograph?.contractions_in_10_min_12 || '',

		fetal_beat_rate_1: partograph?.fetal_beat_rate_1 || '',
		fetal_beat_rate_2: partograph?.fetal_beat_rate_2 || '',
		fetal_beat_rate_3: partograph?.fetal_beat_rate_3 || '',
		fetal_beat_rate_4: partograph?.fetal_beat_rate_4 || '',
		fetal_beat_rate_5: partograph?.fetal_beat_rate_5 || '',
		fetal_beat_rate_6: partograph?.fetal_beat_rate_6 || '',
		fetal_beat_rate_7: partograph?.fetal_beat_rate_7 || '',
		fetal_beat_rate_8: partograph?.fetal_beat_rate_8 || '',
		fetal_beat_rate_9: partograph?.fetal_beat_rate_9 || '',
		fetal_beat_rate_10: partograph?.fetal_beat_rate_10 || '',
		fetal_beat_rate_11: partograph?.fetal_beat_rate_11 || '',
		fetal_beat_rate_12: partograph?.fetal_beat_rate_12 || '',

		urine_eliminated_1: partograph?.urine_eliminated_1
			? formatNumField(partograph.urine_eliminated_1)
			: '',
		urine_eliminated_2: partograph?.urine_eliminated_2
			? formatNumField(partograph.urine_eliminated_2)
			: '',
		urine_eliminated_3: partograph?.urine_eliminated_3
			? formatNumField(partograph.urine_eliminated_3)
			: '',
		urine_eliminated_4: partograph?.urine_eliminated_4
			? formatNumField(partograph.urine_eliminated_4)
			: '',
		urine_eliminated_5: partograph?.urine_eliminated_5
			? formatNumField(partograph.urine_eliminated_5)
			: '',
		urine_eliminated_6: partograph?.urine_eliminated_6
			? formatNumField(partograph.urine_eliminated_6)
			: '',
		urine_eliminated_7: partograph?.urine_eliminated_7
			? formatNumField(partograph.urine_eliminated_7)
			: '',
		urine_eliminated_8: partograph?.urine_eliminated_8
			? formatNumField(partograph.urine_eliminated_8)
			: '',
		urine_eliminated_9: partograph?.urine_eliminated_9
			? formatNumField(partograph.urine_eliminated_9)
			: '',
		urine_eliminated_10: partograph?.urine_eliminated_10
			? formatNumField(partograph.urine_eliminated_10)
			: '',
		urine_eliminated_11: partograph?.urine_eliminated_11
			? formatNumField(partograph.urine_eliminated_11)
			: '',
		urine_eliminated_12: partograph?.urine_eliminated_12
			? formatNumField(partograph.urine_eliminated_12)
			: '',

		body_temperature_1: partograph?.body_temperature_1
			? formatNumField(partograph.body_temperature_1)
			: '',
		body_temperature_2: partograph?.body_temperature_2
			? formatNumField(partograph.body_temperature_2)
			: '',
		body_temperature_3: partograph?.body_temperature_3
			? formatNumField(partograph.body_temperature_3)
			: '',
		body_temperature_4: partograph?.body_temperature_4
			? formatNumField(partograph.body_temperature_4)
			: '',
		body_temperature_5: partograph?.body_temperature_5
			? formatNumField(partograph.body_temperature_5)
			: '',
		body_temperature_6: partograph?.body_temperature_6
			? formatNumField(partograph.body_temperature_6)
			: '',
		body_temperature_7: partograph?.body_temperature_7
			? formatNumField(partograph.body_temperature_7)
			: '',
		body_temperature_8: partograph?.body_temperature_8
			? formatNumField(partograph.body_temperature_8)
			: '',
		body_temperature_9: partograph?.body_temperature_9
			? formatNumField(partograph.body_temperature_9)
			: '',
		body_temperature_10: partograph?.body_temperature_10
			? formatNumField(partograph.body_temperature_10)
			: '',
		body_temperature_11: partograph?.body_temperature_11
			? formatNumField(partograph.body_temperature_11)
			: '',
		body_temperature_12: partograph?.body_temperature_12
			? formatNumField(partograph.body_temperature_12)
			: '',

		beat_rate_1: partograph?.beat_rate_1 || '',
		beat_rate_2: partograph?.beat_rate_2 || '',
		beat_rate_3: partograph?.beat_rate_3 || '',
		beat_rate_4: partograph?.beat_rate_4 || '',
		beat_rate_5: partograph?.beat_rate_5 || '',
		beat_rate_6: partograph?.beat_rate_6 || '',
		beat_rate_7: partograph?.beat_rate_7 || '',
		beat_rate_8: partograph?.beat_rate_8 || '',
		beat_rate_9: partograph?.beat_rate_9 || '',
		beat_rate_10: partograph?.beat_rate_10 || '',
		beat_rate_11: partograph?.beat_rate_11 || '',
		beat_rate_12: partograph?.beat_rate_12 || '',

		membranes_rupture: partograph?.membranes_rupture || '',
		placenta_delivery: partograph?.placenta_delivery || '',
		placental_displacement: partograph?.placental_displacement || '',
		deliverance_spontaneous: partograph?.deliverance_spontaneous || '',
		deliverance_maneuver: partograph?.deliverance_maneuver || '',
		deliverance_manual_extraction:
			partograph?.deliverance_manual_extraction || '',
		deliverance_placenta_exam: partograph?.deliverance_placenta_exam || '',
		deliverance_curettage: partograph?.deliverance_curettage || '',
		placenta_weight: partograph?.placenta_weight || '',
		cord_length: partograph?.cord_length || '',

		admission_day: admission_date
			? `${admission_date.getDate() < 10 ? '0' : ''}${admission_date.getDate()}`
			: '',
		admission_month: admission_date
			? `${admission_date.getMonth() + 1 < 10 ? '0' : ''}${
					admission_date.getMonth() + 1
			  }`
			: '',
		admission_year: admission_date ? `${admission_date.getFullYear()}` : '',
		admission_hour: admission_date ? `${admission_date.getHours()}` : '',
		admission_minute: admission_date ? `${admission_date.getMinutes()}` : '',

		used_drugs: partograph?.used_drugs || [],

		ultrasound_weeks: partograph?.ultrasound_weeks
			? String(partograph.ultrasound_weeks)
			: '',
		ultrasound_days: partograph?.ultrasound_days
			? String(partograph.ultrasound_days)
			: '',
		newborn_weight: partograph?.newborn_weight
			? String(partograph.newborn_weight).replace('.', ',')
			: '',
		newborn_height: partograph?.newborn_height
			? String(partograph.newborn_height)
			: '',
		newborn_oxygen_saturation: partograph?.newborn_oxygen_saturation
			? String(partograph.newborn_oxygen_saturation).replace('.', ',')
			: '',
		newborn_body_temperature: partograph?.newborn_body_temperature
			? String(partograph.newborn_body_temperature).replace('.', ',')
			: '',
		newborn_heart_rate: partograph?.newborn_heart_rate
			? String(partograph.newborn_heart_rate)
			: '',
		newborn_respiration_rate: partograph?.newborn_respiration_rate
			? String(partograph.newborn_respiration_rate)
			: '',

		apgar_first_appearance: partograph?.apgar_first_appearance || '',
		apgar_first_heart_rate: partograph?.apgar_first_heart_rate || '',
		apgar_first_gesticulation: partograph?.apgar_first_gesticulation || '',
		apgar_first_activity: partograph?.apgar_first_activity || '',
		apgar_first_min_respiration: partograph?.apgar_first_min_respiration || '',
		apgar_fifth_appearance: partograph?.apgar_fifth_appearance || '',
		apgar_fifth_heart_rate: partograph?.apgar_fifth_heart_rate || '',
		apgar_fifth_gesticulation: partograph?.apgar_fifth_gesticulation || '',
		apgar_fifth_activity: partograph?.apgar_fifth_activity || '',
		apgar_fifth_min_respiration: partograph?.apgar_fifth_min_respiration || '',
	}
}

export const parsePartograph = (partograph, project_id) => {
	let formatNumField = num => Number(String(num).replace(',', '.'))
	let payload = objectsOnlyWithIDs(partograph)

	let admission_date =
		payload?.admission_year &&
		payload?.admission_month &&
		payload?.admission_day &&
		payload?.admission_hour &&
		payload?.admission_minute
			? buildDate(
					payload?.admission_year,
					payload?.admission_month,
					payload?.admission_day,
					payload?.admission_hour,
					payload?.admission_minute
			  )
			: null
	let birthtime_date =
		payload?.birthtime_year &&
		payload?.birthtime_month &&
		payload?.birthtime_day &&
		payload?.birthtime_hour &&
		payload?.birthtime_minute
			? buildDate(
					payload?.birthtime_year,
					payload?.birthtime_month,
					payload?.birthtime_day,
					payload?.birthtime_hour,
					payload?.birthtime_minute
			  )
			: null

	return {
		project: project_id,
		admission_date: admission_date,
		stage_labour: payload?.stage_labour || '',
		category: payload?.category || '',
		complications: payload?.complications || '',
		birthtime_date: birthtime_date,
		anesthesia_types: payload?.anesthesia_types || '',
		episiotomy: payload?.episiotomy || '',
		estimated_blood_loss: payload?.estimated_blood_loss
			? Number(payload.estimated_blood_loss)
			: null,
		membranes_rupture: payload?.membranes_rupture || '',
		placenta_delivery: payload?.placenta_delivery || '',
		placental_displacement: payload?.placental_displacement || '',
		deliverance_spontaneous: payload?.deliverance_spontaneous || '',
		deliverance_maneuver: payload?.deliverance_maneuver || '',
		deliverance_manual_extraction: payload?.deliverance_manual_extraction || '',
		deliverance_placenta_exam: payload?.deliverance_placenta_exam || '',
		deliverance_curettage: payload?.deliverance_curettage || '',
		placenta_weight: payload?.placenta_weight
			? Number(payload.placenta_weight)
			: null,
		cord_length: payload?.cord_length ? Number(payload.cord_length) : null,

		used_drugs: payload?.used_drugs || [],

		ultrasound_weeks: payload?.ultrasound_weeks
			? Number(payload.ultrasound_weeks)
			: null,
		ultrasound_days: payload?.ultrasound_days
			? Number(payload.ultrasound_days)
			: null,

		cervical_dilation_1: payload?.cervical_dilation_1
			? formatNumField(payload?.cervical_dilation_1)
			: null,
		cervical_dilation_2: payload?.cervical_dilation_2
			? formatNumField(payload?.cervical_dilation_2)
			: null,
		cervical_dilation_3: payload?.cervical_dilation_3
			? formatNumField(payload?.cervical_dilation_3)
			: null,
		cervical_dilation_4: payload?.cervical_dilation_4
			? formatNumField(payload?.cervical_dilation_4)
			: null,
		cervical_dilation_5: payload?.cervical_dilation_5
			? formatNumField(payload?.cervical_dilation_5)
			: null,
		cervical_dilation_6: payload?.cervical_dilation_6
			? formatNumField(payload?.cervical_dilation_6)
			: null,
		cervical_dilation_7: payload?.cervical_dilation_7
			? formatNumField(payload?.cervical_dilation_7)
			: null,
		cervical_dilation_8: payload?.cervical_dilation_8
			? formatNumField(payload?.cervical_dilation_8)
			: null,
		cervical_dilation_9: payload?.cervical_dilation_9
			? formatNumField(payload?.cervical_dilation_9)
			: null,
		cervical_dilation_10: payload?.cervical_dilation_10
			? formatNumField(payload?.cervical_dilation_10)
			: null,
		cervical_dilation_11: payload?.cervical_dilation_11
			? formatNumField(payload?.cervical_dilation_11)
			: null,
		cervical_dilation_12: payload?.cervical_dilation_12
			? formatNumField(payload?.cervical_dilation_12)
			: null,

		amniotic_fluid_1: payload?.amniotic_fluid_1
			? formatNumField(payload.amniotic_fluid_1)
			: null,
		amniotic_fluid_2: payload?.amniotic_fluid_2
			? formatNumField(payload.amniotic_fluid_2)
			: null,
		amniotic_fluid_3: payload?.amniotic_fluid_3
			? formatNumField(payload.amniotic_fluid_3)
			: null,
		amniotic_fluid_4: payload?.amniotic_fluid_4
			? formatNumField(payload.amniotic_fluid_4)
			: null,
		amniotic_fluid_5: payload?.amniotic_fluid_5
			? formatNumField(payload.amniotic_fluid_5)
			: null,
		amniotic_fluid_6: payload?.amniotic_fluid_6
			? formatNumField(payload.amniotic_fluid_6)
			: null,
		amniotic_fluid_7: payload?.amniotic_fluid_7
			? formatNumField(payload.amniotic_fluid_7)
			: null,
		amniotic_fluid_8: payload?.amniotic_fluid_8
			? formatNumField(payload.amniotic_fluid_8)
			: null,
		amniotic_fluid_9: payload?.amniotic_fluid_9
			? formatNumField(payload.amniotic_fluid_9)
			: null,
		amniotic_fluid_10: payload?.amniotic_fluid_10
			? formatNumField(payload.amniotic_fluid_10)
			: null,
		amniotic_fluid_11: payload?.amniotic_fluid_11
			? formatNumField(payload.amniotic_fluid_11)
			: null,
		amniotic_fluid_12: payload?.amniotic_fluid_12
			? formatNumField(payload.amniotic_fluid_12)
			: null,

		contractions_in_10_min_1: payload?.contractions_in_10_min_1
			? formatNumField(payload.contractions_in_10_min_1)
			: null,
		contractions_in_10_min_2: payload?.contractions_in_10_min_2
			? formatNumField(payload.contractions_in_10_min_2)
			: null,
		contractions_in_10_min_3: payload?.contractions_in_10_min_3
			? formatNumField(payload.contractions_in_10_min_3)
			: null,
		contractions_in_10_min_4: payload?.contractions_in_10_min_4
			? formatNumField(payload.contractions_in_10_min_4)
			: null,
		contractions_in_10_min_5: payload?.contractions_in_10_min_5
			? formatNumField(payload.contractions_in_10_min_5)
			: null,
		contractions_in_10_min_6: payload?.contractions_in_10_min_6
			? formatNumField(payload.contractions_in_10_min_6)
			: null,
		contractions_in_10_min_7: payload?.contractions_in_10_min_7
			? formatNumField(payload.contractions_in_10_min_7)
			: null,
		contractions_in_10_min_8: payload?.contractions_in_10_min_8
			? formatNumField(payload.contractions_in_10_min_8)
			: null,
		contractions_in_10_min_9: payload?.contractions_in_10_min_9
			? formatNumField(payload.contractions_in_10_min_9)
			: null,
		contractions_in_10_min_10: payload?.contractions_in_10_min_10
			? formatNumField(payload.contractions_in_10_min_10)
			: null,
		contractions_in_10_min_11: payload?.contractions_in_10_min_11
			? formatNumField(payload.contractions_in_10_min_11)
			: null,
		contractions_in_10_min_12: payload?.contractions_in_10_min_12
			? formatNumField(payload.contractions_in_10_min_12)
			: null,

		fetal_beat_rate_1: payload?.fetal_beat_rate_1
			? formatNumField(payload?.fetal_beat_rate_1)
			: null,
		fetal_beat_rate_2: payload?.fetal_beat_rate_2
			? formatNumField(payload?.fetal_beat_rate_2)
			: null,
		fetal_beat_rate_3: payload?.fetal_beat_rate_3
			? formatNumField(payload?.fetal_beat_rate_3)
			: null,
		fetal_beat_rate_4: payload?.fetal_beat_rate_4
			? formatNumField(payload?.fetal_beat_rate_4)
			: null,
		fetal_beat_rate_5: payload?.fetal_beat_rate_5
			? formatNumField(payload?.fetal_beat_rate_5)
			: null,
		fetal_beat_rate_6: payload?.fetal_beat_rate_6
			? formatNumField(payload?.fetal_beat_rate_6)
			: null,
		fetal_beat_rate_7: payload?.fetal_beat_rate_7
			? formatNumField(payload?.fetal_beat_rate_7)
			: null,
		fetal_beat_rate_8: payload?.fetal_beat_rate_8
			? formatNumField(payload?.fetal_beat_rate_8)
			: null,
		fetal_beat_rate_9: payload?.fetal_beat_rate_9
			? formatNumField(payload?.fetal_beat_rate_9)
			: null,
		fetal_beat_rate_10: payload?.fetal_beat_rate_10
			? formatNumField(payload?.fetal_beat_rate_10)
			: null,
		fetal_beat_rate_11: payload?.fetal_beat_rate_11
			? formatNumField(payload?.fetal_beat_rate_11)
			: null,
		fetal_beat_rate_12: payload?.fetal_beat_rate_12
			? formatNumField(payload?.fetal_beat_rate_12)
			: null,

		urine_eliminated_1: payload?.urine_eliminated_1
			? formatNumField(payload.urine_eliminated_1)
			: null,
		urine_eliminated_2: payload?.urine_eliminated_2
			? formatNumField(payload.urine_eliminated_2)
			: null,
		urine_eliminated_3: payload?.urine_eliminated_3
			? formatNumField(payload.urine_eliminated_3)
			: null,
		urine_eliminated_4: payload?.urine_eliminated_4
			? formatNumField(payload.urine_eliminated_4)
			: null,
		urine_eliminated_5: payload?.urine_eliminated_5
			? formatNumField(payload.urine_eliminated_5)
			: null,
		urine_eliminated_6: payload?.urine_eliminated_6
			? formatNumField(payload.urine_eliminated_6)
			: null,
		urine_eliminated_7: payload?.urine_eliminated_7
			? formatNumField(payload.urine_eliminated_7)
			: null,
		urine_eliminated_8: payload?.urine_eliminated_8
			? formatNumField(payload.urine_eliminated_8)
			: null,
		urine_eliminated_9: payload?.urine_eliminated_9
			? formatNumField(payload.urine_eliminated_9)
			: null,
		urine_eliminated_10: payload?.urine_eliminated_10
			? formatNumField(payload.urine_eliminated_10)
			: null,
		urine_eliminated_11: payload?.urine_eliminated_11
			? formatNumField(payload.urine_eliminated_11)
			: null,
		urine_eliminated_12: payload?.urine_eliminated_12
			? formatNumField(payload.urine_eliminated_12)
			: null,

		body_temperature_1: payload?.body_temperature_1
			? formatNumField(payload.body_temperature_1)
			: null,
		body_temperature_2: payload?.body_temperature_2
			? formatNumField(payload.body_temperature_2)
			: null,
		body_temperature_3: payload?.body_temperature_3
			? formatNumField(payload.body_temperature_3)
			: null,
		body_temperature_4: payload?.body_temperature_4
			? formatNumField(payload.body_temperature_4)
			: null,
		body_temperature_5: payload?.body_temperature_5
			? formatNumField(payload.body_temperature_5)
			: null,
		body_temperature_6: payload?.body_temperature_6
			? formatNumField(payload.body_temperature_6)
			: null,
		body_temperature_7: payload?.body_temperature_7
			? formatNumField(payload.body_temperature_7)
			: null,
		body_temperature_8: payload?.body_temperature_8
			? formatNumField(payload.body_temperature_8)
			: null,
		body_temperature_9: payload?.body_temperature_9
			? formatNumField(payload.body_temperature_9)
			: null,
		body_temperature_10: payload?.body_temperature_10
			? formatNumField(payload.body_temperature_10)
			: null,
		body_temperature_11: payload?.body_temperature_11
			? formatNumField(payload.body_temperature_11)
			: null,
		body_temperature_12: payload?.body_temperature_12
			? formatNumField(payload.body_temperature_12)
			: null,

		beat_rate_1: payload?.beat_rate_1
			? formatNumField(payload.beat_rate_1)
			: null,
		beat_rate_2: payload?.beat_rate_2
			? formatNumField(payload.beat_rate_2)
			: null,
		beat_rate_3: payload?.beat_rate_3
			? formatNumField(payload.beat_rate_3)
			: null,
		beat_rate_4: payload?.beat_rate_4
			? formatNumField(payload.beat_rate_4)
			: null,
		beat_rate_5: payload?.beat_rate_5
			? formatNumField(payload.beat_rate_5)
			: null,
		beat_rate_6: payload?.beat_rate_6
			? formatNumField(payload.beat_rate_6)
			: null,
		beat_rate_7: payload?.beat_rate_7
			? formatNumField(payload.beat_rate_7)
			: null,
		beat_rate_8: payload?.beat_rate_8
			? formatNumField(payload.beat_rate_8)
			: null,
		beat_rate_9: payload?.beat_rate_9
			? formatNumField(payload.beat_rate_9)
			: null,
		beat_rate_10: payload?.beat_rate_10
			? formatNumField(payload.beat_rate_10)
			: null,
		beat_rate_11: payload?.beat_rate_11
			? formatNumField(payload.beat_rate_11)
			: null,
		beat_rate_12: payload?.beat_rate_12
			? formatNumField(payload.beat_rate_12)
			: null,

		newborn_weight: payload?.newborn_weight
			? formatNumField(payload.newborn_weight)
			: null,
		newborn_height: payload?.newborn_height
			? formatNumField(payload.newborn_height)
			: null,
		newborn_oxygen_saturation: payload?.newborn_oxygen_saturation
			? formatNumField(payload.newborn_oxygen_saturation)
			: null,
		newborn_body_temperature: payload?.newborn_body_temperature
			? formatNumField(payload.newborn_body_temperature)
			: null,
		newborn_heart_rate: payload?.newborn_heart_rate
			? Number(payload.newborn_heart_rate)
			: null,
		newborn_respiration_rate: payload?.newborn_respiration_rate
			? Number(payload.newborn_respiration_rate)
			: null,
		apgar_first_appearance: payload?.apgar_first_appearance
			? payload?.apgar_first_appearance
			: '',
		apgar_first_heart_rate: payload?.apgar_first_heart_rate
			? payload?.apgar_first_heart_rate
			: '',
		apgar_first_gesticulation: payload?.apgar_first_gesticulation
			? payload?.apgar_first_gesticulation
			: '',
		apgar_first_activity: payload?.apgar_first_activity
			? payload?.apgar_first_activity
			: '',
		apgar_first_min_respiration: payload?.apgar_first_min_respiration
			? payload?.apgar_first_min_respiration
			: '',
		apgar_fifth_appearance: payload?.apgar_fifth_appearance
			? payload?.apgar_fifth_appearance
			: '',
		apgar_fifth_heart_rate: payload?.apgar_fifth_heart_rate
			? payload?.apgar_fifth_heart_rate
			: '',
		apgar_fifth_gesticulation: payload?.apgar_fifth_gesticulation
			? payload?.apgar_fifth_gesticulation
			: '',
		apgar_fifth_activity: payload?.apgar_fifth_activity
			? payload?.apgar_fifth_activity
			: '',
		apgar_fifth_min_respiration: payload?.apgar_fifth_min_respiration
			? payload?.apgar_fifth_min_respiration
			: '',
	}
}

export const getAPGARResults = (
	appearance,
	heart_rate,
	gesticulation,
	activity,
	min_respiration,
	selectedLanguage
) => {
	const { Medicalemergency, Attentionrequired, Healthycondition } = require('../../../../../../../../../data/translation.json')[selectedLanguage]

	appearance = typeof appearance === 'object' ? appearance.id : appearance
	heart_rate = typeof heart_rate === 'object' ? heart_rate.id : heart_rate
	gesticulation =
		typeof gesticulation === 'object' ? gesticulation.id : gesticulation
	activity = typeof activity === 'object' ? activity.id : activity
	min_respiration =
		typeof min_respiration === 'object' ? min_respiration.id : min_respiration

	let appearanceScore = {
		WITHOUT_CYANOSIS: 2,
		CYANOSIS_IN_EXTREMITIES: 1,
		CYANOSIS: 0,
	}
	let heartRateScore = { EQ_OR_HIGHER_THAN_100: 2, LESS_THAN_100: 1, ABSENT: 0 }
	let gesticulationScore = { CRY_SNEEZE_COUGH: 2, GRIMACE: 1, ABSENT: 0 }
	let activityScore = { A_LOT_OF_ACTIVITY: 2, SOME_MOVEMENTS: 1, ABSENT: 0 }
	let respirationScore = { STRONG: 2, IRREGULAR: 1, ABSENT: 0 }

	let total = 0

	if (appearance) total += appearanceScore[appearance]
	if (heart_rate) total += heartRateScore[heart_rate]
	if (gesticulation) total += gesticulationScore[gesticulation]
	if (activity) total += activityScore[activity]
	if (min_respiration) total += respirationScore[min_respiration]

	if (
		!appearance ||
		!heart_rate ||
		!gesticulation ||
		!activity ||
		!min_respiration
	)
		return { content: '', type: '' }

	if (total >= 7) {
		return { content: Healthycondition, type: AlertRowTextType.SUCCESS }
	} else if (total >= 4) {
		return { content: Attentionrequired, type: AlertRowTextType.INFO }
	} else {
		return { content: Medicalemergency, type: AlertRowTextType.WARNING }
	}
}

export function getNewBornLengthResults(length, selectedLanguage) {
	const { Belownormallength, Abovenormallength } =
		require('../../../../../../../../../data/translation.json')[selectedLanguage]

	if (!length && typeof length !== 'number') return { type: '', content: '' }
	length = Number(String(length).replace(',', '.'))

	if (length < 46) {
		return { content: Belownormallength, type: AlertRowTextType.INFO }
	} else if (length > 56) {
		return {
			content: Abovenormallength,
			type: AlertRowTextType.INFO,
		}
	} else {
		return { content: 'Normal', type: AlertRowTextType.SUCCESS }
	}
}

export function getNewBornWeightResults(weight, selectedLanguage='pt-BR') {
	const { Belownormalweight, Abovenormalweight } =
		require('../../../../../../../../../data/translation.json')[selectedLanguage]
	if (!weight && typeof weight !== 'number') return { type: '', content: '' }
	weight = Number(String(weight).replace(',', '.'))

	if (weight < 2500) {
		return { content: Belownormalweight, type: AlertRowTextType.INFO }
	} else if (weight > 4500) {
		return { content: Abovenormalweight, type: AlertRowTextType.INFO }
	} else {
		return { content: 'Normal', type: AlertRowTextType.SUCCESS }
	}
}
export function getNewBornHeartRate(heart_rate, selectedLanguage) {
	const { Bradycardia, Tachycardia } = require('../../../../../../../../../data/translation.json')[selectedLanguage]

	if (!heart_rate && typeof heart_rate !== 'number')
		return { type: '', content: '' }

	if (heart_rate < 70) {
		return { type: AlertRowTextType.WARNING, content: Bradycardia }
	} else if (heart_rate > 190) {
		return { type: AlertRowTextType.WARNING, content: Tachycardia }
	} else {
		return { type: AlertRowTextType.SUCCESS, content: 'Normal' }
	}
}

export function getNewBornRespirationRateResults(
	respiration_rate,
	selectedLanguage
) {
	const { Bradypnea, Tachypnea } = require('../../../../../../../../../data/translation.json')[selectedLanguage]

	if (!respiration_rate && typeof respiration_rate !== 'number')
		return { type: '', content: '' }

	if (respiration_rate < 30) {
		return { type: AlertRowTextType.WARNING, content: Bradypnea }
	} else if (respiration_rate > 60) {
		return { type: AlertRowTextType.WARNING, content: Tachypnea }
	} else {
		return { type: AlertRowTextType.SUCCESS, content: 'Normal' }
	}
}

export function getNewBornBodyTemperatureResults(
	body_temperature,
	selectedLanguage
) {
	const {
		Severehypothermia,
		Moderatehypothermia,
		Mildhypothermia,
		Lowgradefever,
		Moderatefever,
		Highfever,
		Hyperpyrexia,
	} = require('../../../../../../../../../data/translation.json')[
		selectedLanguage
	]

	if (!body_temperature && typeof body_temperature !== 'number')
		return { type: '', content: '' }
	body_temperature = Number(String(body_temperature).replace(',', '.'))

	if (body_temperature <= 32) {
		return { type: AlertRowTextType.WARNING, content: Severehypothermia }
	} else if (body_temperature < 35.9) {
		return { type: AlertRowTextType.WARNING, content: Moderatehypothermia }
	} else if (body_temperature < 36.4) {
		return { type: AlertRowTextType.WARNING, content: Mildhypothermia }
	} else if (body_temperature <= 37.5) {
		return { type: AlertRowTextType.SUCCESS, content: 'Normal' }
	} else if (body_temperature < 38.1) {
		return { type: AlertRowTextType.WARNING, content: Lowgradefever }
	} else if (body_temperature < 39.4) {
		return { type: AlertRowTextType.WARNING, content: Moderatefever }
	} else if (body_temperature < 40.5) {
		return { type: AlertRowTextType.WARNING, content: Highfever }
	} else {
		return { type: AlertRowTextType.WARNING, content: Hyperpyrexia }
	}
}

export function getNewBornOxygenSaturation(
	oxygen_saturation,
	selectedLanguage
) {
	const { Hypoxemia } =
		require('../../../../../../../../../data/translation.json')[
			selectedLanguage
		]
	if (!oxygen_saturation && typeof oxygen_saturation !== 'number')
		return { type: '', content: '' }
	oxygen_saturation = Number(String(oxygen_saturation).replace(',', '.'))

	if (oxygen_saturation >= 95 && oxygen_saturation <= 100) {
		return { type: AlertRowTextType.SUCCESS, content: 'Normal' }
	} else {
		return { type: AlertRowTextType.WARNING, content: Hypoxemia }
	}
}
