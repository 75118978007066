import React, { useEffect } from 'react'
import { NewCheckbox } from '../../../../../../../../../../../newcomponents'
import { useThousandDays } from '../../../../../ThousandDaysSection'
import { Input } from '../../../../../../../../../../../component'
import { Mask1DecimalPlace } from '../../../../../../../../../../../component/mask/mask'
import { getIMCResult } from '../../../../../../Objective/VitalSignsAnthropometry/utils'
import AlertRowText from '../../../../../../../../../../../component/alertRowText/alertRowText'
import RiskBadge from './RiskBadge'

export function PregnancyRiskGeneralInfoForm() {
	const {
		selectedLanguage,
		pregnancyBackground,
		setPregnancyBackground,
		savePregnancyBackground,
	} = useThousandDays()

	const {
		Unstablefamily,
		Generalinfo,
		Pregnancyacceptance,
		Cannotreadandwrite,
		Smoker,
		Beginningofgestation,
		Weight,
		Height,
	} = require('../../../../../../../../../../data/translation.json')[selectedLanguage]

	useEffect(() => {
		savePregnancyBackground()
	}, [
		pregnancyBackground.unstable_family,
		pregnancyBackground.pregnancy_acceptance,
		pregnancyBackground.cant_read_write,
		pregnancyBackground.tabagism,
	])

	const handleChange = (event) => {
		let cleanedValue =
			event.target.name === 'weight_at_pregnancy_age'
				? Mask1DecimalPlace(event.target.value)
				: event.target.value.replace(/\D/g, '')
		setPregnancyBackground((prev) => ({
			...prev,
			[event.target.name]: cleanedValue,
		}))
	}

	return (
		<>
			<NewCheckbox
				title={<b>{Generalinfo}</b>}
				className='PregnancyRiskCalculator-Checkbox'
				state={[
					{
						id: 'unstable_family',
						name: Unstablefamily,
						checked: pregnancyBackground.unstable_family,
					},
					{
						id: 'pregnancy_acceptance',
						name: Pregnancyacceptance,
						checked: pregnancyBackground.pregnancy_acceptance,
					},
					{
						id: 'cant_read_write',
						name: Cannotreadandwrite,
						checked: pregnancyBackground.cant_read_write,
					},
					{
						id: 'tabagism',
						name: Smoker,
						checked: pregnancyBackground.tabagism,
					},
				]}
				defaultHandleCheckbox={false}
				setState={(event) =>
					setPregnancyBackground((prev) => ({
						...prev,
						[event.target.name]: event.target.checked,
					}))
				}
			/>
			<div className='PregnancyRiskCalculator-Separator' />
			<div>
				<b>{Beginningofgestation}</b>
				<div className='PregnancyRiskCalculator-NutritionRow'>
					<div>
						<b>{Weight}</b>
						<Input
							name='weight_at_pregnancy_age'
							placeholder='kg'
							value={pregnancyBackground.weight_at_pregnancy_age}
							maxLength={5}
							action={handleChange}
							actionBlur={savePregnancyBackground}
							autoComplete='off'
						/>
					</div>
					<div>
						<b>{Height}</b>
						<Input
							name='height_at_pregnancy_age'
							placeholder='cm'
							value={pregnancyBackground.height_at_pregnancy_age}
							maxLength={3}
							action={handleChange}
							actionBlur={savePregnancyBackground}
							autoComplete='off'
						/>
					</div>
				</div>
				<AlertRowText
					{...getIMCResult(
						pregnancyBackground.weight_at_pregnancy_age,
						pregnancyBackground.height_at_pregnancy_age,
						selectedLanguage
					)}
					outlined
				/>
			</div>
			<RiskBadge />
		</>
	)
}
