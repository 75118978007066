import './SerologicalTestRubToxoCitoView.css'
import React from 'react'
import { getCytomegalovirusResult, getRubellaResult, getToxoplasmosisResult } from '../utils'
import { Button, IcoBin, IcoPencil, List } from '../../../../../../../../../../../../../component'
import AlertRowText from '../../../../../../../../../../../../../component/alertRowText/alertRowText'
import { useThousandDays } from '../../../../../../../ThousandDaysSection'
import { convertDate } from '../../../../../../../../../../../../../utils/convertDate'
import SerologicalTestRubToxoCitoWrite from '../Write/SerologicalTestRubToxoCitoWrite'
import api from '../../../../../../../../../../../../../helpers/api'

export default function SerologicalTestRubToxoCitoView() {
	const { 
		selectedLanguage, 
		holdingProject: { rub_toxo_cito_exams }, 
		setModalInfo,
		fetchThousandDaysProjects
	} = useThousandDays()

	const {
		Dates,
		Conclusion,
		Reagent,
		Notreagent,
		Indeterminate,
		Notevaluated,
		Rubella,
		Toxoplasmosis,
		Cvm,
		Notinformation,
		Toxoplasmosisandcytomegalovirus,
	} = require('../../../../../../../../../../../../data/translation.json')[selectedLanguage]

	const headDefaultSerologicalTestRub = [
		{ colunm: 'h_rubella_igm', text: `${Rubella} IgM` },
		{ colunm: 'h_rubella_igg', text: `${Rubella} IgG` },
		{ colunm: 'h_rubella_conclusion', text: Conclusion },
		{ colunm: 'h_date', text: Dates, width: '100px' },
		{ colunm: 'h_action', text: '', width: '100px' }
	]

	const headDefaultSerologicalTestToxo = [
		{ colunm: 'h_toxoplasmosis_igm', text: `${Toxoplasmosis} IgM` },
		{ colunm: 'h_toxoplasmosis_igg', text: `${Toxoplasmosis} IgG` },
		{ colunm: 'h_toxoplasmosis_conclusion', text: Conclusion },
		{ colunm: 'h_date', text: Dates, width: '100px' },
		{ colunm: 'h_action', text: '', width: '100px' }
	]

	const headDefaultSerologicalTestCyto = [
		{ colunm: 'h_cytomegalovirus_igm', text: `${Cvm} IgM` },
		{ colunm: 'h_cytomegalovirus_igg', text: `${Cvm} IgG` },
		{ colunm: 'h_cytomegalovirus_conclusion', text: Conclusion },
		{ colunm: 'h_date', text: Dates, width: '100px' },
		{ colunm: 'h_action', text: '', width: '100px' },
	]

	const translate = {
		REAGENT: Reagent,
		NON_REAGENT: Notreagent,
		INDETERMINATE: Indeterminate,
		NOT_EVALUATED: Notevaluated,
	}

	const handleDelete = async (id) => {
		await api.delete(`exam/serological_test_rub_toxo_cito/${id}/`)
		fetchThousandDaysProjects()
	}

	const openExamWriteModal = (holdingExam) => {
		setModalInfo(prev => ({
			...prev,
			open: true,
			title: `${Rubella}, ${Toxoplasmosisandcytomegalovirus}`,
			content: (
				<div className='SOAP-ThousandDaysExamSubSection-Modal'>
					<SerologicalTestRubToxoCitoWrite
						holdingSerologicalTestRubToxoCito={holdingExam} 
						setModalInfo={setModalInfo} 
					/>
				</div>
			),
		}))
	}

	return (
		<div className='SerologicalTestRubToxoCitoView'>
			<div className='SerologicalTestRubToxoCitoView-List'>
				<List
					head={headDefaultSerologicalTestRub}
					data={rub_toxo_cito_exams}
					noData={Notinformation}
					listCustom={(exam, i) => {
						exam['h_rubella_igm'] = translate[exam?.rubella_igm] || '-'
						exam['h_rubella_igg'] = translate[exam?.rubella_igg] || '-'
						exam['h_rubella_conclusion'] = getRubellaResult(exam.rubella_igm, exam.rubella_igg, selectedLanguage)?.content
							? <AlertRowText {...getRubellaResult(exam.rubella_igm, exam.rubella_igg, selectedLanguage)} />
							: '-'
						exam['h_date'] = exam?.date ? convertDate(exam.date, '-', '/') : '-'
						exam['h_action'] = (
							<div>
								<Button
									color='secondary'
									type='btn circle'
									action={() => openExamWriteModal(exam)}
									title='Editar exame'>
									<IcoPencil />
								</Button>
								<Button
									color='danger'
									type='btn circle'
									action={() => handleDelete(exam?.id)}
									title='Excluir exame'>
									<IcoBin />
								</Button>
							</div>
						)
						return exam
					}}
				/>
				<List
					head={headDefaultSerologicalTestToxo}
					data={rub_toxo_cito_exams}
					noData={Notinformation}
					listCustom={(exam, i) => {
						exam['h_toxoplasmosis_igm'] = translate[exam?.toxoplasmosis_igm] || '-'
						exam['h_toxoplasmosis_igg'] = translate[exam?.toxoplasmosis_igg] || '-'
						exam['h_toxoplasmosis_conclusion'] = getToxoplasmosisResult(exam.toxoplasmosis_igm, exam.toxoplasmosis_igg, selectedLanguage)?.content
							? <AlertRowText {...getToxoplasmosisResult(exam.toxoplasmosis_igm, exam.toxoplasmosis_igg, selectedLanguage)} />
							: '-'
						exam['h_date'] = exam?.date ? convertDate(exam.date, '-', '/') : '-'
						exam['h_action'] = (
							<div>
								<Button
									color='secondary'
									type='btn circle'
									action={() => openExamWriteModal(exam)}
									title='Editar exame'>
									<IcoPencil />
								</Button>
								<Button
									color='danger'
									type='btn circle'
									action={() => handleDelete(exam?.id)}
									title='Excluir exame'>
									<IcoBin />
								</Button>
							</div>
						)
						return exam
					}}
				/>
				<List
					head={headDefaultSerologicalTestCyto}
					data={rub_toxo_cito_exams}
					noData={Notinformation}
					listCustom={(exam, i) => {
						exam['h_cytomegalovirus_igm'] = translate[exam?.cytomegalovirus_igm] || '-'
						exam['h_cytomegalovirus_igg'] = translate[exam?.cytomegalovirus_igg] || '-'
						exam['h_cytomegalovirus_conclusion'] = getCytomegalovirusResult(exam.cytomegalovirus_igm, exam.cytomegalovirus_igg, selectedLanguage)?.content
							? <AlertRowText {...getCytomegalovirusResult(exam.cytomegalovirus_igm, exam.cytomegalovirus_igg, selectedLanguage)} />
							: '-'
						exam['h_date'] = exam?.date ? convertDate(exam.date, '-', '/') : '-'
						exam['h_action'] = (
							<div>
								<Button
									color='secondary'
									type='btn circle'
									action={() => openExamWriteModal(exam)}
									title='Editar exame'>
									<IcoPencil />
								</Button>
								<Button
									color='danger'
									type='btn circle'
									action={() => handleDelete(exam?.id)}
									title='Excluir exame'>
									<IcoBin />
								</Button>
							</div>
						)
						return exam
					}}
				/>
			</div>
		</div>
	)
}
