import './AttendanceManager.css'
import React, { useEffect } from "react";
import AttendanceHistoryPage from './subcomponents/AttendanceHistory/AttendanceHistory';
import WaitingRoom from './subcomponents/WaitingRoom/WaitingRoom';
import { useParams, useHistory } from 'react-router-dom'
import BookingAppointment from './subcomponents/BookingAppointment/BookingAppointment';
import TicketsManager from './subcomponents/TicketsManager/TicketsManager';


export default function AttendanceManager() {
	const history = useHistory()
	const { tab } = useParams()

	useEffect(() => {
		if (!tab) {
			history.push('/atendimentos/agendamento')
		}
	}, [])
    
    const content = {
		'sala-de-espera': <WaitingRoom />,
        'historico': <AttendanceHistoryPage />,
		'agendamento': <BookingAppointment />,
		'paineis-de-chamada': <TicketsManager />
    }

    return <div>
		<div className='AttendanceManager-TabManager'>
			<div
				className={`AttendanceManager-TabManager-btn ${tab === 'agendamento' ? 'active' : ''}`}
				onClick={() => history.push('/atendimentos/agendamento')}
            >
				Agenda
			</div>
			<div
				className={`AttendanceManager-TabManager-btn ${tab === 'sala-de-espera' ? 'active' : ''}`}
				onClick={() => history.push('/atendimentos/sala-de-espera')}
            >
				Sala de espera
			</div>
			<div
				className={`AttendanceManager-TabManager-btn ${tab === 'historico' ? 'active' : ''}`}
				onClick={() => history.push('/atendimentos/historico')}
            >
				Histórico de atendimentos
			</div>
			<div
				className={`AttendanceManager-TabManager-btn ${tab === 'paineis-de-chamada' ? 'active' : ''}`}
				onClick={() => history.push('/atendimentos/paineis-de-chamada')}
            >
				Últimos chamados
			</div>
		</div>
        { content[tab] }
    </div>
}