import './ABOGroupRHFactorWrite.css'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import api from '../../../../../../../../../../../../../helpers/api'
import NewSelect from '../../../../../../../../../../../../../newcomponents/form/select/select'
import Button from '../../../../../../../../../../../../../newcomponents/button/button'
import { AddAlert, AlertType } from '../../../../../../../../../../../../../component'
import { AlertRowTextType } from '../../../../../../../../../../../../../component/alertRowText/alertRowText'
import { useThousandDays } from '../../../../../../../ThousandDaysSection'
import getCurrentDate from '../../../../../../../../../../../../../utils/getCurrentDate'
import { buildDate, formatISO8601 } from '../../../../../../../../../../../../../utils/convertDate'
import { MODAL_INIT_PROPS } from '../../../../../../../../../../../../../newcomponents/modal/modal'
import { getYearsStart1900 } from '../../../../../../../../../../../../../utils/getYearsStart1900'

const selectOptions_data = require('../../../../../../../../../../../../../component/form/select/options.json')

function strpABOGroupRHFactor(exam={}) {
		let { day, month, year } = getCurrentDate()
		
		let date = exam?.date ? new Date(exam.date) : ''

		return {
			"id": exam?.id,
			"abo_group": exam?.abo_group || 'NOT_EVALUATED',
			"rh_factor": exam?.rh_factor || 'NOT_EVALUATED',
			'date_day': date ? `${date.getDate() < 10 ? '0': ''}${date.getDate()}` : day,
			'date_month': date ? `${date.getMonth() + 1 < 10 ? '0': ''}${date.getMonth() + 1}` : month,
			'date_year': date ? `${date.getFullYear()}` : year,
		}
}

function parseABOGroupRHFactor(exam={}) {
	let date = buildDate(exam?.date_year, exam?.date_month, exam?.date_day, 10, 10)

	return {
		"id": exam?.id,
		"abo_group": exam?.abo_group || 'NOT_EVALUATED',
		"rh_factor": exam?.rh_factor || 'NOT_EVALUATED',
		"date": formatISO8601(date),
	}
}

export default function ABOGroupRHFactorWrite({ holdingABOGroupRHFactor=null, setModalInfo= () => null }) {
	const dispatch = useDispatch()
	const [aboGroupRHFactor, setABOGroupRHFactor] = useState(strpABOGroupRHFactor(holdingABOGroupRHFactor))
	const { selectedLanguage, fetchThousandDaysProjects, holdingProject } = useThousandDays()

	const {
		Savedexam,
		Notsavedexam,
		Abobloodgroupandrhfactor,
		Notevaluated,
		Rhfactor,
		Allfieldsmustbefilledin,
		Saveabobloodgroupandrhfactor,
		Select_Months_Options,
		Day,
		Week, 
		Year,
		date,
	} = require('../../../../../../../../../../../../data/translation.json')[selectedLanguage]

	const handleSelect = event => {
		setABOGroupRHFactor(prev => ({ ...prev, [event.target.id]: event.target.selected }))
	}

	const handleSubmit = async () => {
		if (aboGroupRHFactor.abo_group === 'NOT_EVALUATED' || aboGroupRHFactor.rh_factor === 'NOT_EVALUATED') {
			dispatch(AddAlert(Abobloodgroupandrhfactor, Allfieldsmustbefilledin, AlertRowTextType.INFO))
			return
		}
		setModalInfo(MODAL_INIT_PROPS)
		const payload = {
			...parseABOGroupRHFactor(aboGroupRHFactor),
			person: holdingProject.patient.id,
			project: holdingProject.id,
		}

		try {
			if (payload?.id) {
				await api.patch(`/exam/abo_group_rh_factor/${payload.id}/`, payload)
			} else {
				await api.post('/exam/abo_group_rh_factor/', payload)
			}
			dispatch(AddAlert(Abobloodgroupandrhfactor, Savedexam, AlertType.SUCCESS))
			fetchThousandDaysProjects()
			setModalInfo(MODAL_INIT_PROPS)
		} catch (err) {
			dispatch(AddAlert(Abobloodgroupandrhfactor, Notsavedexam, AlertType.ERROR))
			console.error("ABOGroupRHFactorWrite ~ handleSubmit: ", err)
		}
	}

	const date_months_options = selectOptions_data.months.map((option) => ({ id: option.id, name: Select_Months_Options[option.id] }))

	return (
		<div className='ABOGroupRHFactorWrite'>
			<div>
				<b>{date}</b>
				<div className='ABOGroupRHFactorWrite-Date'>
					<NewSelect
						id='date_day'
						options={selectOptions_data.days}
						selected={aboGroupRHFactor.date_day}
						onSelect={handleSelect}
						canBeEmpty={false}
						defaultText={Day}
					/>
					<NewSelect
						id='date_month'
						options={date_months_options}
						selected={aboGroupRHFactor.date_month}
						onSelect={handleSelect}
						canBeEmpty={false}
						defaultText={Week}
					/>
					<NewSelect
						id='date_year'
						options={getYearsStart1900()}
						selected={aboGroupRHFactor.date_year}
						onSelect={handleSelect}
						canBeEmpty={false}
						defaultText={Year}
					/>
				</div>
			</div>
			<div className='ABOGroupRHFactorWrite-DataRow'>
				<div>
					<b>ABO</b>
					<div>
						<NewSelect
							id='abo_group'
							options={[
								{ id: 'NOT_EVALUATED', name: Notevaluated },
								{ id: 'A', name: 'A' },
								{ id: 'B', name: 'B' },
								{ id: 'AB', name: 'AB' },
								{ id: 'O', name: 'O' },
							]}
							onSelect={handleSelect}
							selected={aboGroupRHFactor.abo_group}
							canBeEmpty={false}
						/>
					</div>
				</div>
				<div>
					<b>{Rhfactor}</b>
					<div>
						<NewSelect
							id='rh_factor'
							options={[
								{ id: 'NOT_EVALUATED', name: Notevaluated },
								{ id: 'POSITIVE', name: '+' },
								{ id: 'NEGATIVE', name: '-' },
							]}
							onSelect={handleSelect}
							selected={aboGroupRHFactor.rh_factor}
							canBeEmpty={false}
						/>
					</div>
				</div>
			</div>
			<div className='AddNewExamModal-ButtonRow'>
				<Button
					label={Saveabobloodgroupandrhfactor}
					onClick={handleSubmit}
				/>
			</div>
		</div>
	)
}
