import './RoutineReportsPageModal.css'
import React, { useEffect, useState } from "react";
import { deleteReportsRoutine, getReportsRoutine } from "../../../../http";
import Loading from "../../../../../../newcomponents/loading/loading";
import { IcoBin, MaskDataHora, Paginate } from "../../../../../../component";
import { EditingIcon } from "../../../../../../component/icon/editing";
import { RoutineReportsIcon } from "../../../../../../component/icon/routineReports";
import NewTaskIcon from '../../../../../../component/icon/new_task';
import Modal, { MODAL_INIT_PROPS } from '../../../../../../newcomponents/modal/modal';
import NewEditRoutineModal from './NewEditRoutine/NewEditRoutine';
import { getParams } from '../../../../../../utils';

const defaultOptions = require('../../../../../../component/form/select/options.json')

export const INIT_FILTER_REPORTS = {
	"doctor__id__in": [],
	"specialty__id__in": [],
	"health_insurance__id__in": [],
	"person__address__city__in": [],
	"classification__in": [],
	"decision__in": [],
	"priority__in": [],
	"result__in": [],
	"related_diseases_and_problems__code__in": [],
}

export default function RoutineReportsPageModal() {
    const [loading, setLoading] = useState(false)
	const [routines, setRoutines] = useState([])
    const [secondaryModal, setSecondaryModal] = useState(MODAL_INIT_PROPS)
    const [pagination, setPagination] = useState({ pageNumber: 0, totalPages: 1, totalElements: 0 })

    const handleNextPage = e => {
		fetchRoutines({ offset: e * 3 })
	}

    useEffect(() => {
		fetchRoutines({ offset: 0 })
	}, [])

    const handleDeleteRoutine = async (routineID) => {
		await deleteReportsRoutine(routineID)
			.then(() => fetchRoutines())
	}

	const fetchRoutines = async (params={}) => {
        params = {
            offset: 0,
            limit: 3,
            ...params
        }
        setLoading(true)
		try {
			const res = await getReportsRoutine(params)
			setRoutines(res.data.results)
            setPagination({
				totalElements: res.data.count,
				totalPages: Math.ceil(res.data.count / 3),
				pageNumber: params.offset / 3,
			})
		} catch (err) {
			setRoutines([])
			console.error('RoutineReportsPageModal ~ fetchRoutines ~ ', err)
		}
		setLoading(false)
	}

    const openNewEditRoutineModal = (instance=null) => {
        setSecondaryModal(prev => ({
            ...prev,
            open: true,
            title: "Salvar Rotina",
            content: <NewEditRoutineModal 
                initRoutine={instance} 
                fetchRoutines={fetchRoutines} 
                setModalInfo={setSecondaryModal} 
            />
        }))
    }

    return <div className='RoutineReportsPageModal'>
            <Modal {...secondaryModal} dismissFn={() => setSecondaryModal(MODAL_INIT_PROPS)} />
			<div className='RoutineReportsPageModal-ActionRow'>
				<div
					onClick={() => openNewEditRoutineModal()}
					title="Nova Rotina"
				>
					<NewTaskIcon />
				</div>
			</div>
            <div className='RoutineReportsPageModal-Body'>
				{
					loading 
					? <div className='RoutineReportsPageModal-Loading'><Loading /></div>
					: <div>
						{
							routines?.length
							? <div className='RoutineReportsPageModal-ReportUnitWrapper'>
								{
									routines.map((routine, idx) => <div key={idx} className='RoutineReportsPageModal-ReportUnitWrapper-Unit'>
										<div className='RoutineReportsPageModal-ReportUnitWrapper-Unit-FirstBox'>
											<b className='RoutineReportsPageModal-ReportUnitWrapper-Unit-Subtitle'>{routine?.description || '-'}</b>
											<span>
												{defaultOptions.PERIODICITY_CHOICES.find(({ id }) => id === routine?.reports_periodicity)?.name || '-' } | {routine?.reports_email || '-'}
											</span>
											<span>{routine?.last_time === '1950-01-01' ? 'Primeiro envio agendado' : `Último envio: ${MaskDataHora(routine?.last_time)?.data}`}</span>
										</div>
										<div className='AttendanceUnit-ActionRow'>
											<div 
												className='AttendanceUnit-ActionRow-Btn' 
												onClick={() => openNewEditRoutineModal(routine)}
											>
												<EditingIcon /> <b>Editar</b>
											</div>
											<div 
												className='AttendanceUnit-ActionRow-Btn' 
												onClick={() => handleDeleteRoutine(routine?.id)}
											>
												<IcoBin /> <b>Remover</b>
											</div>
										</div>
									</div>)
								}
                                <div>
                                    <Paginate data={pagination} action={handleNextPage} />

                                </div>
							</div>
							: <div className='RoutineReportsPageModal-NotFound'>
								<div>
									<RoutineReportsIcon />
									<p><b>Nenhuma rotina de relatório cadastrada</b></p>
								</div>
							</div>
						}
					</div> 
				}
			</div>
    </div>
}