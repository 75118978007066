import './HealthProfileSection.css'
import React from 'react'
import InfoLine from '../../../../component/info-line/info-line'
import { useAttendance } from '../../consultation'
import AlertRowText, { AlertRowTextType } from '../../../../component/alertRowText/alertRowText'
import jsonPerfilSaude from '../../../datailPatient/perfilSaude.json'

export default function HealthProfileSection() {
  const { attendance } = useAttendance()

  const valueText = ({valor, titulo}) => {
    return jsonPerfilSaude[titulo][valor] ? jsonPerfilSaude[titulo][valor] : valor
  }

  return (
    <div className='health-profile'>
      <div className='subtitle'>Perfil de saúde</div>
      <div className='health-profile-content'>
        {
          attendance.perfilSaude?.[0] ?
            Object.keys(jsonPerfilSaude).map(e => {
              const resp = jsonPerfilSaude[e]?.dependToAt ? jsonPerfilSaude[e].dependToAt : 'Y'
              let resposta = null
              const item = (
                <InfoLine key={`info-${e}`}
                  label={jsonPerfilSaude[e].title}
                  name={e}
                  value={valueText({ valor: attendance.perfilSaude[0][e], titulo: e })}
                />
              )
              if (attendance.perfilSaude[0][e] !== null) {
                if (!jsonPerfilSaude[e].dependTo) {
                  resposta = item
                } else if (attendance.perfilSaude[0]?.[jsonPerfilSaude[e].dependTo] === resp) {
                  resposta = item
                }
              }
              return resposta
            })
            : <AlertRowText 
              content='Sem informações de perfil de saúde do paciente'
              type={AlertRowTextType.INFO}
              outlined
            />
        }
      </div>
    </div>
  )
}
