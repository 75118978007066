import { parsePregnancyBackground } from './subsections/GestationSubSection/subcomponents/PregnancyRiskCalculator/utils';
import { parsePartnerPreNatal } from './subsections/GestationSubSection/subcomponents/PartnerPrenatal/utils';
import { parseObstetricDate } from './subsections/InitSubSection/utils';
import api from "../../../../../../helpers/api";
import { parsePartograph } from "./subsections/PartographSubSection/subcomponents/Childbirth/utils";
import { parseImediatePostPartum } from "./subsections/PartographSubSection/subcomponents/ImediatePostPartum/utils";
import { parseBirthForecast } from "./subsections/PartographSubSection/subcomponents/Birthforecast/utils";
import { parsePreNatalAttendanceEditor } from "./subsections/GestationSubSection/subcomponents/PrenatalAttendance/utils";
import { getParams } from '../../../../../../utils';

export async function getPatientExams(person) {
    const url = `/exam/person/?patient__id=${person.id}`
    const response = await api.get(url)
    return response.data
}

export async function getThousandDaysProjects(params) {
    const url = `/thousanddays/consultation/${getParams(params)}`
    const response = await api.get(url)
    return response.data.results
}

export async function putPreNatalAttendance(preNatalAttendance, project_id) {
    const url = `/thousanddays/pre_natal_attendance/${project_id}`
    let payload = parsePreNatalAttendanceEditor(preNatalAttendance, project_id)
    const result = await api.put(url, payload)
    return result
}

export async function deletePreNatalAttendance(preNatalAttendance, project_id) {    
    const url = `thousanddays/pre_natal_attendance/${project_id}?pre_natal_id=${preNatalAttendance?.id}`
    const result = await api.delete(url, preNatalAttendance)
    return result
}

export async function putPregnancyBackground(pregnancyBackground, project_id) {
    const url = `/thousanddays/pregnancybackground/${project_id}`
    const payload = parsePregnancyBackground(pregnancyBackground, project_id)
    const response = api.put(url, payload)
    return response.data
}

export async function putPartnerPrenatal(partnerPrenatal, project_id) {
    const url = `/thousanddays/partnerprenatal/${project_id}`
    const payload = parsePartnerPreNatal(partnerPrenatal, project_id)
    const response = api.put(url, payload)
    return response.data
}

export async function putImediatePostPartum(imediatePostPartum, project_id) {
    const url = `/thousanddays/imediatepostpartum/${project_id}`
    const payload = parseImediatePostPartum(imediatePostPartum, project_id)
    const response = api.put(url, payload)
    return response.data
}

export async function putPartograph(partograph, project_id) {
    const url = `/thousanddays/partograph/${project_id}`
    const payload = parsePartograph(partograph, project_id)
    const response = api.put(url, payload)
    return response.data
}

export async function putObstetricData(obstetricData, project_id) {
    const url = `/thousanddays/obstetricdata/${project_id}`
    const payload = parseObstetricDate(obstetricData, project_id)
    const response = api.put(url, payload)
    return response.data
}

export async function putBirthForescast(birthforescast, project_id) {
    const url = `/thousanddays/birthforecast/${project_id}`
    let payload = parseBirthForecast(birthforescast, project_id)
    const result = api.put(url, payload)
    return result
}