import React, { useEffect } from 'react'
import ReactQuill from 'react-quill'
import NewSelect from '../../../../../../../../../../../../newcomponents/form/select/select'
import { RadioButton } from '../../../../../../../../../../../../component'
import { useThousandDays } from '../../../../../../ThousandDaysSection'

const {
	stage_labour_options,
	category_options,
} = require('./GeneralInfo.json')

const selectOptions_data = require('../../../../../../../../../../../../component/form/select/options.json')

const getYears = () => {
	let years = []
	for (
		let i = new Date().getFullYear() - 1;
		i <= new Date().getFullYear();
		i++
	) {
		years.push({ id: `${i}`, name: `${i}` })
	}
	return years
}

export default function GeneralInfo({ handleChange, handleSelect }) {
	const { selectedLanguage, partograph, setPartograph, savePartograph } =
		useThousandDays()

	const {
		Admissionhour,
		Workinternship,
		Stage_Labour_Options,
		Typeofdelivery,
		Category_Options,
		Complications,
		SelectOptions_Data_Months_Options,
		Day,
		Month,
		Year,
		Hour,
	} = require('../../../../../../../../../../../data/translation.json')[
		selectedLanguage
	]

	const stages_labour_options = stage_labour_options.map(option => ({
		id: option.id,
		name: Stage_Labour_Options[option.id],
	}))

	const selectOptions_data_months_options = selectOptions_data.months.map(
		option => ({
			id: option.id,
			name: SelectOptions_Data_Months_Options[option.id],
		})
	)

	const categories_options = category_options.map(option => ({
		id: option.id,
		name: Category_Options[option.id],
	}))

	useEffect(() => {
		savePartograph()
	}, [
		partograph.admission_day,
		partograph.admission_month,
		partograph.admission_year,
		partograph.admission_hour,
		partograph.admission_minute,
		partograph.stage_labour,
		partograph.category,
	])

	return (
		<>
			<div className='ThousandDaysSection-DatetimeRow'>
				<div>
					<b>{Admissionhour}</b>
					<div className='ThousandDaysSection-DatetimeRow-SelectRow'>
						<div className='ThousandDaysSection-Childbirth-Datetime'>
							<NewSelect
								id='admission_day'
								options={selectOptions_data.days}
								onSelect={handleSelect}
								selected={partograph.admission_day}
								canBeEmpty={false}
								defaultText={Day}
							/>
							<NewSelect
								id='admission_month'
								options={selectOptions_data_months_options}
								onSelect={handleSelect}
								selected={partograph.admission_month}
								defaultText={Month}
								canBeEmpty={false}
							/>
							<NewSelect
								id='admission_year'
								options={getYears()}
								onSelect={handleSelect}
								selected={partograph.admission_year}
								defaultText={Year}
								canBeEmpty={false}
							/>
						</div>
						<div className='ThousandDaysSection-Childbirth-InfoHour'>
							<NewSelect
								id='admission_hour'
								options={selectOptions_data.hours}
								onSelect={handleSelect}
								selected={partograph.admission_hour}
								defaultText={Hour}
								canBeEmpty={false}
							/>
							<NewSelect
								id='admission_minute'
								options={selectOptions_data.minutes}
								onSelect={handleSelect}
								selected={partograph.admission_minute}
								defaultText='Min.'
								canBeEmpty={false}
							/>
						</div>
					</div>
				</div>
			</div>

			<div>
				<RadioButton
					label={
						<p>
							<b>{Workinternship}</b>
						</p>
					}
					name='stage_labour'
					action={handleChange}
					checked={partograph.stage_labour}
					options={stages_labour_options}
				/>
				<RadioButton
					label={
						<p>
							<b>{Typeofdelivery}</b>
						</p>
					}
					name='category'
					action={handleChange}
					checked={partograph.category}
					options={categories_options}
				/>
			</div>
			<div>
				<p>
					<b>{Complications}</b>
				</p>
				<ReactQuill
					name='complications'
					theme='snow'
					value={partograph.complications}
					onChange={text =>
						setPartograph(prev => ({ ...prev, complications: text }))
					}
					onBlur={savePartograph}
				/>
			</div>
		</>
	)
}
