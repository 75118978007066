import './GlassesPrescription.css'
import React, { useState } from "react";
import { MODAL_INIT_PROPS } from '../../../../../../../../../../../../../../../newcomponents/modal/modal';
import { Glasses, validateGlasses } from './helpers';
import { useAttendance } from '../../../../../../../../../../../../../consultation';
import NewButton, { ButtonModel } from '../../../../../../../../../../../../../../../newcomponents/button/button';
import { handleSignal } from '../../../../../../../../../../../../../ophthalmology-components/ophthalmology-table';
import { handleAxis, handleCDegree } from '../../../../../../../../../../../../../ophthalmology-tab';
import { Input, RadioButton, TextareaEditor } from '../../../../../../../../../../../../../../../component';

const glassesTypes = [
    { id: 'FAR', name: 'Longe' },
    { id: 'CLOSE', name: 'Perto' },
    { id: 'MULTIFOCAL', name: 'Multifocal'},
    { id: 'BIFOCAL', name: 'Bifocal' }
]

const strpHoldingGlassesDocument = (document) => {
    return {
        "id": document?.id || "",
        "category": document?.category || "",
        "title": document?.title || "",
        "glasses": new Glasses(document?.glasses)
    }
}

export default function GlassesPrescription({ saveDocument, initHoldingGlassesDocument=null }) {
    const { attendance, setModalInfo } = useAttendance()
    const [holdingGlassesDocument, setHoldingGlassesDocument] = useState(strpHoldingGlassesDocument(initHoldingGlassesDocument))
    const [glassesErrors, setGlassesErrors] = useState({})

    const handleChangeGlasses = (event) => {
        setHoldingGlassesDocument(prev => ({ ...prev, glasses: { ...prev.glasses, [event.target.name]: event.target.value } }))
        setGlassesErrors({})
    }

    const saveGlassesPrescription = async () => {
        const errors = validateGlasses(holdingGlassesDocument.glasses)
        setGlassesErrors(errors)
        if (Object.keys(errors).length > 0) return
        let payload = { glasses: holdingGlassesDocument.glasses }
        
        await saveDocument(payload)
        setModalInfo(MODAL_INIT_PROPS)
    }

    let { rightEye, leftEye } = attendance?.ophthalmology || {} 
    return <div className='GlassesPrescription'>
        <div>
            <RadioButton
                label='Tipo de óculos' 
                name='tipo'
                action={event => setHoldingGlassesDocument(prev => ({ ...prev, glasses: { ...prev.glasses, type: event.target.value } }))}
                checked={{ 'id': holdingGlassesDocument.glasses.type.id }}
                options={glassesTypes}
            />

            <div className='GlassesPrescription-Row'>
                <div>
                    <p><b>Refração Dinâmica</b></p>
                    <div className='glasses-container glasses-alignRefractionTitles'>
                        <div className='glasses-space'><b>HD</b></div>
                        <div className='glasses-degreeE'><b>Grau E</b></div>
                        <div className='glasses-degreeC'><b>Grau C</b></div>
                        <div className='glasses-axis'><b>Eixo</b></div>

                        <div className='glasses-rEyeFlag'><p>OD</p></div>
                        <div className='glasses-rEDegreeInput'>{handleSignal(rightEye?.dynamicRefraction?.eDegree) || '-' }</div>
                        <div className='glasses-rCDegreeInput'>{handleCDegree(rightEye?.dynamicRefraction?.cDegree) || '-'}</div>
                        <div className='glasses-rAxisInput'>{handleAxis(rightEye?.dynamicRefraction?.axis) || '-'}{rightEye?.dynamicRefraction?.axis && '°'}</div>

                        <div className='glasses-lEyeFlag'><p>OE</p></div>
                        <div className='glasses-lEDegreeInput'>{handleSignal(leftEye?.dynamicRefraction?.eDegree) || '-'}</div>
                        <div className='glasses-lCDegreeInput'>{handleCDegree(leftEye?.dynamicRefraction?.cDegree) || '-'}</div>
                        <div className='glasses-lAxisInput'>{handleAxis(leftEye?.dynamicRefraction?.axis) || '-'}{leftEye?.dynamicRefraction?.axis && '°'}</div>
                    </div>
                </div>
                <div>
                    <p><b>Refração Estática</b></p>
                    <div className='glasses-container glasses-alignRefractionTitles'>
                        <div className='glasses-space'><b>HD</b></div>
                        <div className='glasses-degreeE'><b>Grau E</b></div>
                        <div className='glasses-degreeC'><b>Grau C</b></div>
                        <div className='glasses-axis'><b>Eixo</b></div>
                        
                        <div className='glasses-rEyeFlag'><p>OD</p></div>
                        <div className='glasses-rEDegreeInput'>{handleSignal(rightEye?.staticRefraction?.eDegree) || '-'}</div>
                        <div className='glasses-rCDegreeInput'>{handleCDegree(rightEye?.staticRefraction?.cDegree) || '-'}</div>
                        <div className='glasses-rAxisInput'>{handleAxis(rightEye?.staticRefraction?.axis) || '-'}{rightEye?.staticRefraction?.axis && '°'}</div>

                        <div className='glasses-lEyeFlag'><p>OE</p></div>
                        <div className='glasses-lEDegreeInput'>{handleSignal(leftEye?.staticRefraction?.eDegree) || '-'}</div>
                        <div className='glasses-lCDegreeInput'>{handleCDegree(leftEye?.staticRefraction?.cDegree) || '-'}</div>
                        <div className='glasses-lAxisInput'>{handleAxis(leftEye?.staticRefraction?.axis) || '-'}{leftEye?.staticRefraction?.axis && '°'}</div>
                    </div>
                </div>
            </div>
            <div className='GlassesPrescription-Row'>
                <div className='glasses-container-2'>
                    <div className='glasses-space'><b>HD</b></div>
                    <div className='glasses-degreeE'><b>Grau E</b></div>
                    <div className='glasses-degreeC'><b>Grau C</b></div>
                    <div className='glasses-axis'><b>Eixo</b></div>
                    <div className='glasses-DNP'><b>DNP</b></div>
                    <div className='glasses-rEyeFlag'><p>OD</p></div>
                    <div className='glasses-rEDegreeInput'>
                        <Input
                            name='rightEyeEDegree'
                            action={handleChangeGlasses}
                            actionBlur={handleChangeGlasses}
                            value={handleSignal(holdingGlassesDocument.glasses.rightEyeEDegree)}
                            errors={{error: glassesErrors, message: true}}
                        />
                    </div>
                    <div className='glasses-rCDegreeInput'>
                        <Input
                            name='rightEyeCDegree'
                            action={handleChangeGlasses}
                            actionBlur={handleChangeGlasses}
                            value={handleCDegree(holdingGlassesDocument.glasses.rightEyeCDegree)}
                            errors={{error: glassesErrors, message: true}}
                        />
                    </div>
                    <div className='glasses-rAxisInput'>
                        <Input
                            name='rightEyeAxis'
                            action={handleChangeGlasses}
                            actionBlur={handleChangeGlasses}
                            value={handleAxis(holdingGlassesDocument.glasses.rightEyeAxis)}
                            type='Number'
                            errors={{error: glassesErrors, message: true}}
                        />
                    </div>
                    <div className='glasses-rDNPInput'>
                        <Input
                            name='rightEyeDNP'
                            action={handleChangeGlasses}
                            actionBlur={handleChangeGlasses}
                            value={holdingGlassesDocument.glasses.rightEyeDNP}
                            errors={{error: glassesErrors, message: true}}
                        />
                    </div>

                    <div className='glasses-lEyeFlag'><p>OE</p></div>
                    <div className='glasses-lDegreeEInput'>
                        <Input
                            name='leftEyeEDegree'
                            action={handleChangeGlasses}
                            actionBlur={handleChangeGlasses}
                            value={handleSignal(holdingGlassesDocument.glasses.leftEyeEDegree)}
                            errors={{error: glassesErrors, message: true}}
                        />
                    </div>
                    <div className='glasses-lDegreeCInput'>
                        <Input
                            name='leftEyeCDegree'
                            action={handleChangeGlasses}
                            actionBlur={handleChangeGlasses}
                            value={handleCDegree(holdingGlassesDocument.glasses.leftEyeCDegree)}
                            errors={{error: glassesErrors, message: true}}
                        />
                    </div>
                    <div className='glasses-lAxisInput'>
                        <Input
                            name='leftEyeAxis'
                            action={handleChangeGlasses}
                            actionBlur={handleChangeGlasses}
                            value={handleAxis(holdingGlassesDocument.glasses.leftEyeAxis)}
                            type='Number'
                            errors={{error: glassesErrors, message: true}}
                        />
                    </div>
                    <div className='glasses-lDNPInput'>
                        <Input
                            name='leftEyeDNP'
                            action={handleChangeGlasses}
                            actionBlur={handleChangeGlasses}
                            value={holdingGlassesDocument.glasses.leftEyeDNP}
                            errors={{error: glassesErrors, message: true}}
                        />
                    </div>
                </div>
                <div>
                    <b>Adição</b>
                    <div className='glasses-addition'>
                        <Input
                            name='addition'
                            action={handleChangeGlasses}
                            actionBlur={handleChangeGlasses}
                            value={handleSignal(holdingGlassesDocument.glasses.addition)}
                            errors={{error: glassesErrors, message: true}}
                        />
                    </div>
                </div>
            </div>
            <div className='glasses-notes'>
                {
                    /*
                                    <TextareaEditor 
                    name='notes'
                    action={handleChangeGlasses} 
                    value={holdingGlassesDocument.glasses.notes}
                />
                    */
                }
            </div>
            <p>{`${attendance.doctor?.sex === 'M'? 'Dr.' : 'Dra.'} ${attendance.doctor?.name}`}</p>
        </div>
        <div className='GlassesPrescription-BtnBox'>
            <NewButton label='Salvar' onClick={saveGlassesPrescription} />
        </div>
    </div>
}