import './DocumentsEmission.css'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import MevoPrescription from './subcomponents/Mevo/MevoPrescription'
import { useAttendance } from '../../../../../../consultation'
import { patchSignatureMevo, postSignatureMevo } from '../../../../../../http'
import { isInvalidAddress, isInvalidContact } from '../../../../../../../../utils/validators'
import NewButton, { ButtonModel } from '../../../../../../../../newcomponents/button/button'
import Loading from '../../../../../../../../newcomponents/loading/loading'
import { AddAlert, AlertType } from '../../../../../../../../component'
import api from '../../../../../../../../helpers/api'
import DocumentsHistory from './subcomponents/DocumentsHistory/DocumentsHistory'
import Modal, { MODAL_INIT_PROPS } from '../../../../../../../../newcomponents/modal/modal'
import EditAddress from '../../../../../../../datailPatient/subcomponents/EditAddress/EditAddress'
import { useApp } from '../../../../../../../../layout/App'

export default function DocumentsEmission() {
    const dispatch = useDispatch()
    const history = useHistory()
    const { fetchAttendance, attendance, isEditable, setMainOpenConsultationTab } = useAttendance()
    const [modalInfo, setModalInfo] = useState(MODAL_INIT_PROPS)
    const { currentHealthPlaceUser } = useApp()
    const user_profile = currentHealthPlaceUser?.profile
    const [loading, setLoading] = useState(false)
    const [mevoModalURL, setMevoModalURL] = useState('')
    const [openSection, setOpenSection] = useState('documents')
    const hideMevo = user_profile !== 'DOCTOR' || !isEditable

    const cancelMevo = async () => {
        console.log("Evento lido: Terminar Mais Tarde")
        try {
            setMevoModalURL('cancel')
            await patchSignatureMevo(attendance.id)
        } catch (err) {
            dispatch(AddAlert('Receituário Mevo', 'Falha ao salvar informações para terminar mais tarde', AlertType.ERROR))
        }
    }

    const excludedMevo = () => {
        console.log("Evento lido: Cancelado")
        setMevoModalURL('excluded')
    }

    const saveMevo = async (documents) => {
        console.log("Evento lido: Salvar")
        setLoading(true)
        try {
            await postSignatureMevo(attendance.id, documents)
            document.dispatchEvent(new CustomEvent("DocumentsHistoryFetchActivities"))
            setMevoModalURL('')
            setLoading(false)
            dispatch(AddAlert('Receituário Mevo', 'Documento(s) criado(s) com sucesso!', AlertType.SUCCESS))
        } catch (err) {
            setLoading(false)
            dispatch(AddAlert('Receituário Mevo', 'Falha ao fazer upload dos Documentos, tente novamente!', AlertType.ERROR))
        }
    }
    
    const getMevoModalURL = () => {
        api.get(`/signature/mevo/${attendance.id}/`)
            .then(res => setMevoModalURL(res.data.ModalURL))
            .catch(err => err.response.data.error ? setMevoModalURL(err.response.data.error) : setMevoModalURL('error'))
    }

    useEffect(() => {
        const setupEvents = (event) => {
            const validEvents = ['cancel','excluded','prescricao']
            if (validEvents.includes(event.data.type)) {
                const actions = {
                    'cancel': cancelMevo,
                    'excluded': excludedMevo,
                    'prescricao': () => saveMevo(event.data.Documentos)
                }
                const action = actions[event.data.type]
                if (action) action()
            }
        }

        window.addEventListener("message", setupEvents)
        return _ => {
            window.removeEventListener("message", setupEvents)
        }
    })

    const isInvalidDoctorAddress = isInvalidAddress(attendance?.doctor?.address)
    if (isInvalidDoctorAddress && attendance?.doctor?.id != currentHealthPlaceUser?.user?.person?.id) { // Invalid Doctor Address
        if (attendance?.extra_kwargs?.external_doctor_name) return <p>Emissão não disponível para esse atendimento.</p>
        return <p>[{ attendance?.doctor?.name }] Cadastrar o <b>endereço do profissional</b> para emissão de documetos.</p>
    } else if (isInvalidDoctorAddress) {
        return <>
            <Modal {...modalInfo} dismissFn={() => setModalInfo(MODAL_INIT_PROPS)} />
            <p>Precisamos saber o <b>seu endereço profissional</b> para acessar documentos.</p>
            <NewButton
                label='Cadastrar meu endereço'
                onClick={() => history.push('/configuration/address')}
            />
        </>
    }
    
    if (isInvalidAddress(attendance?.person?.address)) { // Invalid Patient Address
        return <>
            <Modal {...modalInfo} dismissFn={() => setModalInfo(MODAL_INIT_PROPS)} />
            <p>Precisamos saber o <b>endereço do paciente</b> para acessar documentos</p>
            <NewButton 
                onClick={() => setModalInfo(prevData => ({ 
                ...prevData, 
                open: true, 
                title: 'Endereço do Paciente', 
                content: <EditAddress setModalInfo={setModalInfo} initHoldingPatient={attendance?.person} fetchExtraAction={() => fetchAttendance()} />,
                confirmText: ''
                }))}
                label='Cadastrar'
            />
        </>
    }

    if (attendance?.person?.document_type != 'CPF') {
        return <>
            <p><b>CPF do paciente</b> é um campo obrigatório para gerar documentos assinados digitalmente</p>
            <NewButton 
                label='Ir para dados pessoais'
                onClick={() => setMainOpenConsultationTab('patientdata')}
                model={ButtonModel.SECONDARY}
            />
        </>   
    }

    if (isInvalidContact(attendance?.person?.contact_phone)) { // Invalid Patient Contact
        return <>
            <p>Precisamos saber o <b>contato do paciente</b> para acessar documentos</p>
            <NewButton  
                label='Ir para dados pessoais'
                onClick={() =>  setMainOpenConsultationTab('patientdata')}
                model={ButtonModel.SECONDARY}
            />
        </>
    }

    if (loading) return <div className='DocumentsEmission-Loading'>
        <Loading />
    </div>

    const sections = {
        'documents': <div className='DocumentsEmission-Body'>
            <DocumentsHistory
                attendance={attendance} 
                setModalInfo={setModalInfo}
            />
        </div>,
        'mevo': <div className='DocumentsEmission-Body'>
            <MevoPrescription 
                mevoModalURL={mevoModalURL} 
                setMevoModalURL={setMevoModalURL} 
                getMevoModalURL={getMevoModalURL} 
                setModalInfo={setModalInfo}
            />
        </div>
    }
    
    return <>
        <Modal {...modalInfo} dismissFn={() => setModalInfo(MODAL_INIT_PROPS)} />
        <div className='DocumentsEmission-row'>
            <div className={`DocumentsEmission-row-btn ${openSection === 'documents' ? 'active' : ''}`} onClick={() => setOpenSection('documents')}>
                Documentos
            </div>
            {
                !hideMevo
                ? <div className={`DocumentsEmission-row-btn ${openSection === 'mevo' ? 'active' : ''}`} onClick={() => setOpenSection('mevo')}>
                    Mevo
                </div>
                : null
            }
        </div>
        {sections[openSection]}
    </>
}