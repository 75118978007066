import './ThousandDaysExamSubSection.css'
import React, { useState } from 'react'
import Button, {
	ButtonModel,
} from '../../../../../../../../../../newcomponents/button/button'
import Modal, { MODAL_INIT_PROPS } from '../../../../../../../../../../newcomponents/modal/modal'
import CompleteHemogramView from './forms/CompleteHemogram/View/CompleteHemogramView'
import FastingBloodGlucoseView from './forms/FastingBloodGlucose/View/FastingBloodGlucoseView'
import SerologicalTestHIVHbsAgAntiHCVVDRLView from './forms/SerologicalTestHIVHbsAgAntiHCVVDRL/View/HIVHbsAgAntiHCVVDRLView'
import SerologicalTestRubToxoCitoView from './forms/SerologicalTestRubToxoCito/View/SerologicalTestRubToxoCitoView'
import StreptococcusGBSView from './forms/StreptococcusGBS/View/StreptococcusGBSView'
import TreponemalTestView from './forms/TreponemalTest/View/TreponemalTestView'
import ObstetricUltrasonographyView from './forms/ObstetricUltrasonography/ObstetricUltrasonographyView/UltrasonographyView'
import ABOGroupRHFactorView from './forms/ABOGroupRHFactor/View/ABOGroupRHFactorView'
import CompleteHemogramWrite from './forms/CompleteHemogram/Write/CompleteHemogramWrite'
import FastingBloodGlucoseWrite from './forms/FastingBloodGlucose/Write/FastingBloodGlucoseWrite'
import SerologicalTestHIVHbsAgAntiHCVVDRLWrite from './forms/SerologicalTestHIVHbsAgAntiHCVVDRL/Write/HIVHbsAgAntiHCVVDRLWrite'
import SerologicalTestRubToxoCitoWrite from './forms/SerologicalTestRubToxoCito/Write/SerologicalTestRubToxoCitoWrite'
import StreptococcusGBSWrite from './forms/StreptococcusGBS/Write/StreptococcusGBSWrite'
import TreponemalTestWrite from './forms/TreponemalTest/Write/TreponemalTestWrite'
import ObstetricUltrasonographyWrite from './forms/ObstetricUltrasonography/ObstetricUltrasonographyWrite/UltrasonographyWrite'
import ABOGroupRHFactorWrite from './forms/ABOGroupRHFactor/Write/ABOGroupRHFactorWrite'
import AlertRowText, {
	AlertRowTextType,
} from '../../../../../../../../../../component/alertRowText/alertRowText'
import { useThousandDays } from '../../../../ThousandDaysSection'

const initModalInfo = { title: '', open: false, confirmText: '', content: null }

function analyzeExams(holdingProject, pregnancyAgeInDays) {
	let exams = {
		hemogram_exams: holdingProject.hemogram_exams,
		glucose_exams: holdingProject.glucose_exams,
		hiv_hbs_antihcv_vdrl_exams: holdingProject.hiv_hbs_antihcv_vdrl_exams,
		gbs_exams: holdingProject.gbs_exams,
		rub_toxo_cito_exams: holdingProject.rub_toxo_cito_exams,
		treponemal_test_exams: holdingProject.treponemal_test_exams,
		obstetric_ultrasonography_exams:
			holdingProject.obstetric_ultrasonography_exams,
		abo_group_rh_factor_exams: holdingProject.abo_group_rh_factor_exams,
	}

	// Get the start date of the pregnancy.
	let startDate = new Date()
	startDate.setDate(startDate.getDate() - pregnancyAgeInDays)

	// Define the quarter ranges in days.
	const quarterRanges = [
		{ start: 0, end: 90 },
		{ start: 91, end: 180 },
		{ start: 181, end: 365 },
	]

	// Initialize result.
	let result = {
		quarter_1: [],
		quarter_2: [],
		quarter_3: [],
	}

	// Iterate over each exam type.
	for (let examType in exams) {
		// Iterate over each exam of the current type.
		for (let exam of exams[examType]) {
			let examDate = new Date(exam.date)

			// Calculate the day difference between the exam date and the start date of the pregnancy.
			let diffTime = Math.abs(examDate - startDate)
			let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

			// Check which quarter the exam date falls into.
			for (let i = 0; i < quarterRanges.length; i++) {
				if (
					diffDays >= quarterRanges[i].start &&
					diffDays <= quarterRanges[i].end
				) {
					// The exam date falls into this quarter.
					// Append the exam type to the corresponding quarter in the result, if it's not already present.
					if (result[`quarter_${i + 1}`].indexOf(examType) < 0) {
						result[`quarter_${i + 1}`].push(examType)
					}
					break
				}
			}
		}
	}

	return result
}

export default function ThousandDaysExamSubSection() {
	const { selectedLanguage, holdingProject, getPregnancyAgeAt } =
		useThousandDays()
	const [modalInfo, setModalInfo] = useState(initModalInfo)
	const [openExam, setOpenExam] = useState('ABORH')
	let init_quarter =
		getPregnancyAgeAt().age > 186
			? 'quarter_3'
			: getPregnancyAgeAt().age > 93
			? 'quarter_2'
			: 'quarter_1'
	const [openQuarterExamTracker, setOpenQuarterExamTracker] =
		useState(init_quarter)
	const { quarter_1, quarter_2, quarter_3 } = analyzeExams(
		holdingProject,
		getPregnancyAgeAt().age
	)
	const {
		Aboandrhfactor,
		Bloodcount,
		Bloodglucose,
		Hiv,
		HbsAg,
		Antihcvandvdrl,
		Rubella,
		Toxoplasmosisandcytomegalovirus,
		Obstetricultrasound,
		Firsttrimestergestational,
		Completebloodcount,
		Fastingbloodglucose,
		Groupbstreptococcustest,
		Serologicaltestingforhivhbsagantihcvandvrdl,
		Groupbstreptococcus,
		Serologicaltestingforrubellatoxoplasmosisandcytomegalovirus,
		Treponemaltest,
		Secondtrimestergestational,
		Thirdtrimestergestational,
		Add,
		Exam,
	} = require('../../../../../../../../../data/translation.json')[selectedLanguage]

	const examView = {
		ABORH: <ABOGroupRHFactorView />,
		HC: <CompleteHemogramView />,
		GJ: <FastingBloodGlucoseView />,
		TSHHAV: <SerologicalTestHIVHbsAgAntiHCVVDRLView />,
		TSGN: <StreptococcusGBSView />,
		TSRTC: <SerologicalTestRubToxoCitoView />,
		TT: <TreponemalTestView />,
		UO: <ObstetricUltrasonographyView />,
	}

	const examWrite = {
		ABORH: {
			title: Aboandrhfactor,
			content: <ABOGroupRHFactorWrite setModalInfo={setModalInfo} />,
		},
		HC: {
			title: Bloodcount,
			content: <CompleteHemogramWrite setModalInfo={setModalInfo} />,
		},
		GJ: {
			title: Bloodglucose,
			content: <FastingBloodGlucoseWrite setModalInfo={setModalInfo} />,
		},
		TSHHAV: {
			title: `${Hiv}, ${HbsAg}, ${Antihcvandvdrl}`,
			content: <SerologicalTestHIVHbsAgAntiHCVVDRLWrite setModalInfo={setModalInfo} />,
		},
		TSGN: {
			title: Groupbstreptococcustest,
			content: <StreptococcusGBSWrite setModalInfo={setModalInfo} />,
		},
		TSRTC: {
			title: `${Rubella}, ${Toxoplasmosisandcytomegalovirus}`,
			content: <SerologicalTestRubToxoCitoWrite setModalInfo={setModalInfo} />,
		},
		TT: {
			title: Treponemaltest,
			content: <TreponemalTestWrite setModalInfo={setModalInfo} />,
		},
		UO: {
			title: Obstetricultrasound,
			content: <ObstetricUltrasonographyWrite setModalInfo={setModalInfo} />,
		},
	}

	return (
		<div className='SOAP-ThousandDaysExamSubSection'>
			<Modal {...modalInfo} dismissFn={() => setModalInfo(MODAL_INIT_PROPS)} />
			<p className='LastMenstruation-Subtitle'>{Exam}</p>
			<div className='PreNatalAttendance-Separator' />
			<div className='SOAP-ThousandDaysExamSubSection-ExamSelection'>
				<div className='SOAP-ThousandDaysExamSubSection-ExamSelection-TrackingBar'>
					<div
						className={openQuarterExamTracker === 'quarter_1' ? 'active' : ''}
						onClick={() => setOpenQuarterExamTracker('quarter_1')}
					/>
					<div
						className={openQuarterExamTracker === 'quarter_2' ? 'active' : ''}
						onClick={() => setOpenQuarterExamTracker('quarter_2')}
					/>
					<div
						className={openQuarterExamTracker === 'quarter_3' ? 'active' : ''}
						onClick={() => setOpenQuarterExamTracker('quarter_3')}
					/>
				</div>
				<div className='SOAP-ThousandDaysExamSubSection-ExamSelection-Tracking'>
					{openQuarterExamTracker === 'quarter_1' ? (
						<div>
							<p>
								<b>{Firsttrimestergestational}</b>
							</p>
							<div className='SOAP-ThousandDaysExamSubSection-ExamSelection-TrackingList'>
								<AlertRowText
									type={
										quarter_1.includes('hemogram_exams')
											? AlertRowTextType.SUCCESS
											: AlertRowTextType.INFO
									}
									content={Completebloodcount}
									outlined
								/>
								<AlertRowText
									type={
										quarter_1.includes('glucose_exams')
											? AlertRowTextType.SUCCESS
											: AlertRowTextType.INFO
									}
									content={Fastingbloodglucose}
									outlined
								/>
								<AlertRowText
									type={
										quarter_1.includes('abo_group_rh_factor_exams')
											? AlertRowTextType.SUCCESS
											: AlertRowTextType.INFO
									}
									content={Aboandrhfactor}
									outlined
								/>
								<AlertRowText
									type={
										quarter_1.includes('hiv_hbs_antihcv_vdrl_exams')
											? AlertRowTextType.SUCCESS
											: AlertRowTextType.INFO
									}
									content={Serologicaltestingforhivhbsagantihcvandvrdl}
									outlined
								/>
								<AlertRowText
									type={
										quarter_1.includes('gbs_exams')
											? AlertRowTextType.SUCCESS
											: AlertRowTextType.INFO
									}
									content={Groupbstreptococcus}
									outlined
								/>
								<AlertRowText
									type={
										quarter_1.includes('rub_toxo_cito_exams')
											? AlertRowTextType.SUCCESS
											: AlertRowTextType.INFO
									}
									content={
										Serologicaltestingforrubellatoxoplasmosisandcytomegalovirus
									}
									outlined
								/>
								<AlertRowText
									type={
										quarter_1.includes('treponemal_test_exams')
											? AlertRowTextType.SUCCESS
											: AlertRowTextType.INFO
									}
									content={Treponemaltest}
									outlined
								/>
								<AlertRowText
									type={
										quarter_1.includes('obstetric_ultrasonography_exams')
											? AlertRowTextType.SUCCESS
											: AlertRowTextType.INFO
									}
									content={Obstetricultrasound}
									outlined
								/>
							</div>
						</div>
					) : null}
					{openQuarterExamTracker === 'quarter_2' ? (
						<div>
							<p>
								<b>{Secondtrimestergestational}</b>
							</p>
							<div className='SOAP-ThousandDaysExamSubSection-ExamSelection-TrackingList'>
								<AlertRowText
									type={
										quarter_2.includes('hemogram_exams')
											? AlertRowTextType.SUCCESS
											: AlertRowTextType.INFO
									}
									content={Completebloodcount}
									outlined
								/>
								<AlertRowText
									type={
										quarter_2.includes('glucose_exams')
											? AlertRowTextType.SUCCESS
											: AlertRowTextType.INFO
									}
									content={Fastingbloodglucose}
									outlined
								/>
								<AlertRowText
									type={
										quarter_2.includes('hiv_hbs_antihcv_vdrl_exams')
											? AlertRowTextType.SUCCESS
											: AlertRowTextType.INFO
									}
									content={Serologicaltestingforhivhbsagantihcvandvrdl}
									outlined
								/>
								<AlertRowText
									type={
										quarter_2.includes('treponemal_test_exams')
											? AlertRowTextType.SUCCESS
											: AlertRowTextType.INFO
									}
									content={Treponemaltest}
									outlined
								/>
								<AlertRowText
									type={
										quarter_2.includes('obstetric_ultrasonography_exams')
											? AlertRowTextType.SUCCESS
											: AlertRowTextType.INFO
									}
									content={Obstetricultrasound}
									outlined
								/>
							</div>
						</div>
					) : null}
					{openQuarterExamTracker === 'quarter_3' ? (
						<div>
							<p>
								<b>{Thirdtrimestergestational}</b>
							</p>
							<div className='SOAP-ThousandDaysExamSubSection-ExamSelection-TrackingList'>
								<AlertRowText
									type={
										quarter_3.includes('hemogram_exams')
											? AlertRowTextType.SUCCESS
											: AlertRowTextType.INFO
									}
									content={Completebloodcount}
									outlined
								/>
								<AlertRowText
									type={
										quarter_3.includes('glucose_exams')
											? AlertRowTextType.SUCCESS
											: AlertRowTextType.INFO
									}
									content={Fastingbloodglucose}
									outlined
								/>
								<AlertRowText
									type={
										quarter_3.includes('hiv_hbs_antihcv_vdrl_exams')
											? AlertRowTextType.SUCCESS
											: AlertRowTextType.INFO
									}
									content={Serologicaltestingforhivhbsagantihcvandvrdl}
									outlined
								/>
								<AlertRowText
									type={
										quarter_3.includes('treponemal_test_exams')
											? AlertRowTextType.SUCCESS
											: AlertRowTextType.INFO
									}
									content={Treponemaltest}
									outlined
								/>
								<AlertRowText
									type={
										quarter_3.includes('obstetric_ultrasonography_exams')
											? AlertRowTextType.SUCCESS
											: AlertRowTextType.INFO
									}
									content={Obstetricultrasound}
									outlined
								/>
							</div>
						</div>
					) : null}
				</div>
				<div className='SOAP-ThousandDaysExamSubSection-ExamSelection-Separator' />
			</div>
			<div className='SOAP-ThousandDaysExamSubSection-View'>
				<div className='SOAP-ThousandDaysExamSubSection-View-SideLeft'>
					{Object.keys(examWrite).map(key => (
						<div
							className={`SOAP-ThousandDaysExamSubSection-View-SideLeft-btn ${
								openExam === key ? 'active' : ''
							}`}
							onClick={() => setOpenExam(key)}>
							{examWrite[key].title}
						</div>
					))}
				</div>
				<div>
					{examView[openExam]}
					<div className='SOAP-ThousandDaysExamSubSection-View-Btn'>
						<Button
							model={ButtonModel.SECONDARY}
							label={`${Add} ${examWrite[openExam].title}`}
							onClick={() =>
								setModalInfo(prev => ({
									...prev,
									title: examWrite?.[openExam]?.title,
									open: true,
									content: (
										<div className='SOAP-ThousandDaysExamSubSection-Modal'>
											{examWrite[openExam]?.content}
										</div>
									),
								}))
							}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}
