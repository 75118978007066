import './VitalSignsAnthropometry.css'
import React from 'react'
import { Input, idade } from '../../../../../../../component'
import AlertRowText from '../../../../../../../component/alertRowText/alertRowText'
import { useAttendance } from '../../../../../consultation'
import { Mask1DecimalPlace } from '../../../../../../../component/mask/mask'
import {
	getBodyTemperatureResults,
	getHeartRateResults,
	getIMCResult,
	getOxygenSaturationResults,
	getPressureResult,
	getRespirationRateResults,
} from './utils'

export const VitalSigns = ({ selectedLanguage = 'pt-BR' }) => {
	const { attendance, vitalSigns, setVitalSigns, saveVitalSign, isEditable } = useAttendance()

	let age = idade(attendance?.person?.birthday)

	const handleChange = event => {
		let cleanedValue =
			event.target.name === 'oxygen_saturation' ||
			event.target.name === 'body_temperature'
				? Mask1DecimalPlace(event.target.value)
				: event.target.value.replace(/\D/g, '')
		setVitalSigns(prev => ({ ...prev, [`${event.target.name}`]: cleanedValue }))
	}

	return (
		<div>
			<div className='SOAP-VitalSignsSubSection-MainRow'>
				<div>
					<b>Saturação de oxigênio</b>
					<div className='SOAP-VitalSignsSubSection-MainRow-Input'>
						<Input
							name='oxygen_saturation'
							placeholder='%'
							maxLength={4}
							value={vitalSigns.oxygen_saturation}
							action={handleChange}
							actionBlur={saveVitalSign}
							autoComplete='off'
							disabled={!isEditable}
						/>
					</div>
					{vitalSigns.oxygen_saturation ? (
						<AlertRowText
							{...getOxygenSaturationResults(
								vitalSigns.oxygen_saturation,
								selectedLanguage
							)}
							outlined
						/>
					) : null}
				</div>
				<div>
					<b>Temperatura corporal</b>
					<div className='SOAP-VitalSignsSubSection-MainRow-Input'>
						<Input
							name='body_temperature'
							placeholder='°C'
							maxLength={4}
							value={vitalSigns.body_temperature}
							action={handleChange}
							actionBlur={saveVitalSign}
							autoComplete='off'
							disabled={!isEditable}
						/>
					</div>
					{vitalSigns.body_temperature ? (
						<AlertRowText
							{...getBodyTemperatureResults(
								vitalSigns.body_temperature,
								age,
								selectedLanguage
							)}
							outlined
						/>
					) : null}
				</div>
				<div>
					<b>Frequência cardíaca</b>
					<div className='SOAP-VitalSignsSubSection-MainRow-Input'>
						<Input
							name='heart_rate'
							placeholder='bpm'
							maxLength={3}
							value={vitalSigns.heart_rate}
							action={handleChange}
							actionBlur={saveVitalSign}
							autoComplete='off'
							disabled={!isEditable}
						/>
					</div>
					{vitalSigns.heart_rate ? (
						<AlertRowText
							{...getHeartRateResults(vitalSigns.heart_rate, age)}
							outlined
						/>
					) : null}
				</div>
				<div>
					<b>Frequência respiratória</b>
					<div className='SOAP-VitalSignsSubSection-MainRow-Input'>
						<Input
							name='respiration_rate'
							placeholder='mpm'
							maxLength={2}
							value={vitalSigns.respiration_rate}
							action={handleChange}
							actionBlur={saveVitalSign}
							autoComplete='off'
							disabled={!isEditable}
						/>
					</div>
					{vitalSigns.respiration_rate ? (
						<AlertRowText
							{...getRespirationRateResults(
								vitalSigns.respiration_rate,
								age,
								selectedLanguage
							)}
							outlined
						/>
					) : null}
				</div>
				<div>
					<b className='SOAP-ConsultationSubtitle'>Pressão Arterial (mmHg)</b>
					<div className='SOAP-VitalSignsSubSection-Pressure'>
						<Input
							name='systolic'
							placeholder='Sistólica'
							maxLength={3}
							value={vitalSigns.systolic}
							action={handleChange}
							actionBlur={saveVitalSign}
							autoComplete='off'
							disabled={!isEditable}
						/>
						<Input
							name='diastolic'
							placeholder='Diastólica'
							maxLength={3}
							value={vitalSigns.diastolic}
							action={handleChange}
							actionBlur={saveVitalSign}
							autoComplete='off'
							disabled={!isEditable}
						/>
					</div>
					{vitalSigns.systolic && vitalSigns.diastolic ? (
						<AlertRowText
							{...getPressureResult(
								vitalSigns.systolic,
								vitalSigns.diastolic,
								selectedLanguage
							)}
							outlined
						/>
					) : null}
				</div>
			</div>
		</div>
	)
}

export const Anthropometry = ({ selectedLanguage = 'pt-BR' }) => {
	const {
		attendanceExtraData: { anthropometry },
		setAttendanceExtraData,
		saveAnthropometry,
		isEditable,
	} = useAttendance()

	const handleChange = event => {
		let cleanedValue =
			event.target.name === 'weight'
				? Mask1DecimalPlace(event.target.value)
				: event.target.value.replace(/\D/g, '')
		setAttendanceExtraData(prev => ({
			...prev,
			anthropometry: {
				...prev.anthropometry,
				[event.target.name]: cleanedValue,
			},
		}))
	}

	return (
		<div>
			<div className='SOAP-VitalSignsSubSection-Anthropometry'>
				<div>
					<div className='SOAP-VitalSignsSubSection-IMCCalculator'>
						<div>
							<b>Peso</b>
							<Input
								name='weight'
								placeholder='kg'
								value={anthropometry.weight}
								maxLength={5}
								action={handleChange}
								actionBlur={saveAnthropometry}
								autoComplete='off'
								disabled={!isEditable}
							/>
						</div>
						<div>
							<b>Altura</b>
							<Input
								name='height'
								placeholder='cm'
								value={anthropometry.height}
								maxLength={3}
								action={handleChange}
								actionBlur={saveAnthropometry}
								autoComplete='off'
								disabled={!isEditable}
							/>
						</div>
					</div>
					<AlertRowText
						{...getIMCResult(
							anthropometry.weight,
							anthropometry.height,
							selectedLanguage
						)}
						outlined
					/>
				</div>
				<div>
					<b>Circunferência abdominal</b>
					<div className='SOAP-VitalSignsSubSection-MainRow-Input'>
						<Input
							name='abdominal_circumference'
							placeholder='cm'
							value={anthropometry.abdominal_circumference}
							maxLength={3}
							action={handleChange}
							actionBlur={saveAnthropometry}
							autoComplete='off'
							disabled={!isEditable}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}
