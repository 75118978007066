import './HoldingPatientInfoView.css'
import React, { useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams, useLocation } from 'react-router'
import { idade } from '../../component'
import MedicalRecordHistory from './subcomponents/MedicalRecordHistory/MedicalRecordHistory'
import Modal, { MODAL_INIT_PROPS } from '../../newcomponents/modal/modal'
import { getPatientDetails } from '../pacientes/http'
import { loaded, loading } from '../../layout/redux/AppActions'
import DocumentsHistory from '../consultation/subcomponents/SOAP/subcomponents/Plan/subcomponents/DocumentEmission/subcomponents/DocumentsHistory/DocumentsHistory'
import PatientInformation from './subcomponents/PatientInformation/PatientInformation'
import EditPatient from './subcomponents/EditPatient/EditPatient'
import EditAddress from './subcomponents/EditAddress/EditAddress'
import EditProfileImage from './subcomponents/EditProfileImage/EditProfileImage'
import { useApp } from '../../layout/App'
import NextAppointment from './subcomponents/NextAppointment/NextAppointment'

const HoldingPatientContext = React.createContext()

export function useHoldingPatient() {
  return useContext(HoldingPatientContext)
}

export default function HoldingPatientInfoView({ initHoldingPatientID=null }) {
  const dispatch = useDispatch()
  const [holdingPatient, setHoldingPatient] = useState({})
  const { idPaciente, idStatusTab } = useParams()
  const [modalProps, setModalProps] = useState(MODAL_INIT_PROPS)
  const [openSection, setOpenSection] = useState(idStatusTab ||'informacoesPaciente')
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search);
  const hasDuplicate = queryParams.get('hasDuplicate');
  const defaultUser = require('../../media/DefaultUser.png')
  const { isHealthProfessional } = useApp()

  const getAge = () => {
    if (holdingPatient?.birthday) {
        const age = idade(holdingPatient.birthday)
        return (age.anos ? `${age.anos} ${age.anos <= 1 ? 'ano' : 'anos'}` : age.mes ? `${age.mes} ${age.mes <= 1 ? 'mes' : 'meses'}` : '0')
    } else {
        return '-'
    }
  }

  const fetchHoldingPatientData = async () => {
    dispatch(loading())
    try {
      let response = await getPatientDetails( initHoldingPatientID || idPaciente)
      setHoldingPatient(response.data)
      //console.log(response.data.merge_reference);
    } catch (err) {
      console.error('fetchHoldingPatientData error', err)
    }
    dispatch(loaded())
  }

  useEffect(() => {
    fetchHoldingPatientData()
  }, [])
  

  const sections = {
    'informacoesPaciente': <PatientInformation />,
    'prontuario': isHealthProfessional ? <MedicalRecordHistory person={holdingPatient} setModalInfo={setModalProps} /> : null,
    'documents': <DocumentsHistory setModalInfo={setModalProps} person={holdingPatient} />,
    'proximosAtendimentos': <NextAppointment person={holdingPatient} />
  }

  const openEditPatient = () => {
    setModalProps({
      open: true,
      title: 'Editar Paciente',
      content: <EditPatient initHoldingPatient={holdingPatient} setModalInfo={setModalProps} fetchHoldingPatientData={fetchHoldingPatientData} />,
    })
  }

  const openEditAddress = () => {
    setModalProps({
      open: true,
      title: 'Editar Paciente',
      content: <EditAddress initHoldingPatient={holdingPatient} setModalInfo={setModalProps} fetchExtraAction={fetchHoldingPatientData} />
    })
  }

  const openEditProfileImage = (event) => {
    event.preventDefault();
    let files;
    if (event.dataTransfer) {
        files = event.dataTransfer.files;
    } else if (event.target) {
        files = event.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
        setModalProps({
            open: true,
            title: 'Editar Paciente',
            content: <EditProfileImage initHoldingPatient={{ id: holdingPatient?.id, base64_avatar: reader.result }} setModalInfo={setModalProps} fetchExtraAction={fetchHoldingPatientData} />
        });
    };
    reader.readAsDataURL(files[0]);
}

  if (!holdingPatient?.id) return <div></div>

  return (
    <HoldingPatientContext.Provider 
      value={{
        holdingPatient,
        fetchHoldingPatientData,
        openEditPatient,
        openEditAddress,
        openEditProfileImage,
        setModalProps,
      }}
    >
		<div>
			<Modal
				{...modalProps}
				dismissFn={() => setModalProps(MODAL_INIT_PROPS)}
			/>
      {
        idPaciente
        ? <div className='HoldingPatientInfoView-Header'>
          <div className='HoldingPatientInfoView-Header-Profile'>
              <div className='HoldingPatientInfoView-Header-Profile-Avatar'>
                  <div className='img'>
                    <img
                        className='HoldingPatientInfoView-Header-Profile-Avatar-Img'
                        alt='Paciente foto de perfil'
                        src={holdingPatient?.avatar_img_url || defaultUser}
                    />
                  </div>
              </div>
              <div className='HoldingPatientInfoView-Header-Profile-PatientInfoBox'>
                  <b className='HoldingPatientInfoView-Header-Profile-PatientInfoBox-Name'>{holdingPatient?.social_name || holdingPatient?.name || 'Nome não registrado' }</b>
                  <span className='HoldingPatientInfoView-Header-Profile-PatientInfoBox-AltText'>
                    {getAge()} | {holdingPatient?.sex === 'M' ? 'Masculino' : 'Feminino' }{holdingPatient.medical_record_number ? `| Prontuário ${holdingPatient.medical_record_number}` : ''}
                    {
                      hasDuplicate 
                      ? <span className='HoldingPatientInfoView-Header-Profile-PatientInfoBox-DuplicateFlag'>Duplicidade no registro</span>
                      : null
                    }
                  </span>
              </div>
          </div>
      </div>
        : null
      }
			<div className='HoldingPatientInfoView-TabManager'>
        <div
					className={`HoldingPatientInfoView-TabManager-btn ${openSection === 'informacoesPaciente' ? 'active' : ''}`}
					onClick={() => setOpenSection('informacoesPaciente')}>
					Dados Pessoais
				</div>
        {
          isHealthProfessional
          ? <div
            className={`HoldingPatientInfoView-TabManager-btn ${openSection === 'prontuario' ? 'active' : ''}`}
            onClick={() => setOpenSection('prontuario')}
          >
            Prontuário
          </div>
          : null
        }

				<div
					className={`HoldingPatientInfoView-TabManager-btn ${openSection === 'documents' ? 'active' : ''}`}
					onClick={() => setOpenSection('documents')}>
					Documentos
				</div>
        <div
					className={`HoldingPatientInfoView-TabManager-btn ${openSection === 'proximosAtendimentos' ? 'active' : ''}`}
					onClick={() => setOpenSection('proximosAtendimentos')}>
					Próximos Atendimentos
				</div>
			</div>
      <div className='HoldingPatientInfoView-Content'>
        {sections[openSection]}
      </div>
		</div>
  </HoldingPatientContext.Provider>
	)
}
