import './SubjectiveUnit.css'
import React from "react";
import { renderCIAP2Field, renderSession } from '../helpers';

export default function SubjectiveUnit({ attendance }) {

    return <div className='SubjectiveUnit'>
        <div className='SubjectiveUnit-Subtitle'><b>Subjetivo</b></div>
        <div className='SubjectiveUnit-Content' style={{ color: '#aaa'}}>
            {
                attendance?.reason_for_consultation?.length
                ? <div>{renderCIAP2Field(attendance.reason_for_consultation)}</div>
                : null
            }
            {
                attendance?.main_complaint
                ? <div>{renderSession('Queixa principal', attendance.main_complaint)}</div>
                : null
            }
            {
                attendance?.history_of_current_illness
                ? <div>{renderSession('História da doença atual', attendance.history_of_current_illness)}</div>
                : null
            }
            {
                attendance?.past_pathological_history
                ? <div>{renderSession('História patológica pregressa', attendance.past_pathological_history)}</div>
                : null
            }
        </div>
    </div>
}