import React, { useEffect, useRef } from 'react'
import ConsultationTextField from '../../../../consultation-text-field';
import DocumentsEmission from './subcomponents/DocumentEmission/DocumentsEmission';
import { useAttendance } from '../../../../consultation';
import { Input } from '../../../../../../component';
import NewMultiSelect from '../../../../../../newcomponents/form/select/multiselect';
import AlertRowText, { AlertRowTextType } from '../../../../../../component/alertRowText/alertRowText';
import MedicalTeamIco from '../../../../../../component/icon/medicalTeam';
import PreceptorshipEvaluation from '../subcomponents/PreceptorshipEvaluation/PreceptorshipEvaluation';

const {
    PreceptorshipSections,
} = require('../../SOAPSection.json')

function uniqueCIAPs(combined) {
    let uniqueDict = {};
    let uniqueByCode = [];

    for (let item of combined) {
        if (!uniqueDict[item.code]) {
            uniqueDict[item.code] = true;
            uniqueByCode.push(item);
        }
    }

    return uniqueByCode
}

export default function SOAPPlan() {
    const searchTimeout = useRef(null)
    const { 
        attendance, 
        setAttendance, 
        savePartialAppointment,
        savePartialAppointmentForeignKey,
        isEditable, 
        fetchCIAPTwentyCodes,
        displayPreceptorship,
        attendanceExtraData: { ciaps } 
    } = useAttendance()

    useEffect(() => {
        savePartialAppointmentForeignKey('intervention_clinical_procedures')
    }, [attendance.intervention_clinical_procedures])

    const searchInterventionClinicalProcedures = (search) => {
        if (searchTimeout.current) clearTimeout(searchTimeout.current)

        searchTimeout.current = setTimeout((search) => {
            fetchCIAPTwentyCodes({ search });
        }, 400, search)
    };

    const toggleOption = ({ optionRef }) => {
        let selected = ciaps.find(ciap => ciap.code === optionRef)
        let isExisting = attendance.intervention_clinical_procedures.find(ciap => ciap.code === optionRef)

        if (isExisting) {
            setAttendance(prev => ({ ...prev, 'intervention_clinical_procedures': prev.intervention_clinical_procedures.filter(ciap => ciap.code !== optionRef) }));
        } else {
            setAttendance(prev => ({ ...prev, 'intervention_clinical_procedures': [...prev.intervention_clinical_procedures, selected] }));
        }
        fetchCIAPTwentyCodes();
    };

    const handleChange = (id, value) => {
        setAttendance(prev => ({ ...prev, [id]: value }))

        if (searchTimeout.current) {
            clearTimeout(searchTimeout.current);
        }

        searchTimeout.current = setTimeout(() => {
            savePartialAppointment(id, value);
        }, 500)
    };

    const handleBlur = (id) => {
        savePartialAppointment(id, attendance[id]);
    }

    return <>
        <p><b>Intervenção e/ou procedimentos clínicos (CIAP-2)</b></p>
        <div className='SOAP-Subjective-NewMultiSelect'>
            <NewMultiSelect
                filterNode={<div className='SOAP-Subjective-NewMultiSelect-FilterNode'>
                    <Input 
                        placeholder='Pesquisar por CIAP ou Título'
                        action={({ target: { value } }) => searchInterventionClinicalProcedures(value)}
                        actionFocus={()=> fetchCIAPTwentyCodes()}
                    />
                </div>}
                defaultText='Selecione'
                onlyDefaultText
                onSelect={toggleOption}
                selectedOptions={attendance?.intervention_clinical_procedures?.map(ciap => ciap.code) || []}
                optionRefKey='code'
                optionStrKey='title'
                options={attendance?.intervention_clinical_procedures 
                    ? uniqueCIAPs([...attendance.intervention_clinical_procedures, ...ciaps]).map(ciap => ({ ...ciap, title: `${ciap.code} | ${ciap.title}` }))
                    : []
                }
                disabled={!isEditable}
            />
        </div>
        <div className='SOAP-Subjective-NewMultiSelect-Selecteds'>
            { attendance?.intervention_clinical_procedures?.map(({ title, code }, idx) => <div key={idx} className='SOAP-Subjective-NewMultiSelect-Selecteds-Option'><AlertRowText content={`${code} | ${title}`} type={AlertRowTextType.INFO} outlined /></div>) }
        </div>
        <div className='SOAP-Section-Separator' />
        <ConsultationTextField
            id='conduct'
            title='Conduta'
            value={attendance.conduct}
            handleChange={(value) => handleChange('conduct', value)}
            readOnly={!isEditable}
            onBlur={() => handleBlur('conduct')}
        />
        <div className='SOAP-Section-Separator' />
        <DocumentsEmission />
        <div className='SOAP-Section-Separator' />
        {
            displayPreceptorship
            ? <div className='SOAP-Subjective-Preceptorship'>
                <div className='SOAP-Subjective-Preceptorship-Label'>
                    <MedicalTeamIco style={{ width: '21px', fill: '#CED4DA', marginRight: '8px' }} /> Preceptoria
                </div> 
                <PreceptorshipEvaluation 
                    options={PreceptorshipSections.Subjective.options}
                    feedbackKey={PreceptorshipSections.Subjective.feedbackKey}
                />
            </div>
            : null
        }
    </>
}
