import './FastingBloodGlucoseWrite.css'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
	AddAlert,
	AlertType,
	Input,
} from '../../../../../../../../../../../../../component'
import AlertRowText from '../../../../../../../../../../../../../component/alertRowText/alertRowText'
import Button from '../../../../../../../../../../../../../newcomponents/button/button'
import NewSelect from '../../../../../../../../../../../../../newcomponents/form/select/select'
import api from '../../../../../../../../../../../../../helpers/api'
import { getGlycemiaResult, getTotgResult } from '../utils'
import { useThousandDays } from '../../../../../../../ThousandDaysSection'
import { MODAL_INIT_PROPS } from '../../../../../../../../../../../../../newcomponents/modal/modal'
import getCurrentDate from '../../../../../../../../../../../../../utils/getCurrentDate'
import { buildDate, formatISO8601 } from '../../../../../../../../../../../../../utils/convertDate'
import { getYearsStart1900 } from '../../../../../../../../../../../../../utils/getYearsStart1900'

const selectOptions_data = require('../../../../../../../../../../../../../component/form/select/options.json')

function strpFastingBloodGlucose(exam={}) {
	let { day, month, year } = getCurrentDate()

	let date = exam?.date ? new Date(exam.date) : ''

	return {
		"id": exam?.id,
		"glycemia": exam?.glycemia || '',
		"totg": exam?.totg || '',
		"totg_interval": exam?.totg_interval || 'NOT_EVALUATED',
		"date_day": date ? `${date.getDate() < 10 ? '0': ''}${date.getDate()}` : day,
		"date_month": date ? `${date.getMonth() + 1 < 10 ? '0': ''}${date.getMonth() + 1}` : month,
		"date_year": date ? `${date.getFullYear()}` : year,
	}
}

function parseFastingBloodGlucose(exam={}) {
	let date = buildDate(exam?.date_year, exam?.date_month, exam?.date_day, 10, 10)

	return {
		"id": exam?.id,
		"glycemia": Number(exam.glycemia),
		"totg": Number(exam.totg),
		"totg_interval": exam?.totg_interval || 'NOT_EVALUATED',
		"date": formatISO8601(date),
	}
}

export default function FastingBloodGlucoseWrite({ holdingFastingBloodGlucose=null, setModalInfo = () => null }) {
	const dispatch = useDispatch()
	const [fastBloodGlucose, setFastBloodGlucose] = useState(strpFastingBloodGlucose(holdingFastingBloodGlucose))
	const { selectedLanguage, fetchThousandDaysProjects, holdingProject } = useThousandDays()

	const {
		Notevaluated,
		High,
		Collectedafteronehour,
		Collectedaftertwohour,
		Bloodglucose,
		Dextrolgap,
		Savedexam,
		Notsavedexam,
		Savebloodglucose,
		Fastingbloodglucose,
		Day,
		Week,
		Year,
		date,
		Select_Months_Options
	} = require('../../../../../../../../../../../../data/translation.json')[
		selectedLanguage
	]

	const handleChange = event => {
		setFastBloodGlucose(prev => ({
			...prev,
			[`${event.target.name}`]: event.target.value.replace(/\D/g, ''),
		}))
	}

	const handleSelect = event => {
		setFastBloodGlucose(prev => ({
			...prev,
			[`${event.target.id}`]: event.target.selected,
		}))
	}

	const handleSubmit = async () => {
		const payload = {
			...parseFastingBloodGlucose(fastBloodGlucose),
			person: holdingProject.patient.id,
			project: holdingProject.id,
		}

		try {
			if (payload?.id) {
				await api.patch(`/exam/fasting_blood_glucose/${payload.id}/`, payload)
			} else {
				await api.post('/exam/fasting_blood_glucose/', payload)
			}
			dispatch(AddAlert(Fastingbloodglucose, Savedexam, AlertType.SUCCESS))
			fetchThousandDaysProjects()
			setModalInfo(MODAL_INIT_PROPS)
		} catch (err) {
			dispatch(AddAlert(Fastingbloodglucose, Notsavedexam, AlertType.ERROR))
			console.error("FastingbloodglucoseWrite ~ handleSubmit: ", err)
		}
	}

	const date_months_options = selectOptions_data.months.map((option) => ({ id: option.id, name: Select_Months_Options[option.id] }))

	return (
		<div>
			<div>
				<b>{date}</b>
				<div className='ABOGroupRHFactorWrite-Date'>
					<NewSelect
						id='date_day'
						options={selectOptions_data.days}
						selected={fastBloodGlucose.date_day}
						onSelect={handleSelect}
						canBeEmpty={false}
						defaultText={Day}
					/>
					<NewSelect
						id='date_month'
						options={date_months_options}
						selected={fastBloodGlucose.date_month}
						onSelect={handleSelect}
						canBeEmpty={false}
						defaultText={Week}
					/>
					<NewSelect
						id='date_year'
						options={getYearsStart1900()}
						selected={fastBloodGlucose.date_year}
						onSelect={handleSelect}
						canBeEmpty={false}
						defaultText={Year}
					/>
				</div>
			</div>
			<div className='FastingBloodGlucoseWrite-DataRowDefault'>
				<Input
					name='glycemia'
					action={handleChange}
					maxLength={3}
					value={fastBloodGlucose.glycemia}
					label={Bloodglucose}
					placeholder='mg/dL'
					autoComplete='off'
				/>
				{fastBloodGlucose.glycemia ? (
					<AlertRowText
						{...getGlycemiaResult(fastBloodGlucose.glycemia, High)}
					/>
				) : null}
			</div>
			<div className='FastingBloodGlucoseWrite-DataRowDefault'>
				<Input
					name='totg'
					action={handleChange}
					maxLength={3}
					value={fastBloodGlucose.totg}
					label='TOTG'
					placeholder='mg/dL'
					autoComplete='off'
				/>
				{fastBloodGlucose.totg && fastBloodGlucose.totg_interval ? (
					<AlertRowText
						{...getTotgResult(
							fastBloodGlucose.totg,
							fastBloodGlucose.totg_interval,
							High
						)}
					/>
				) : null}
			</div>
			<div className='FastingBloodGlucoseWrite-DataSelectRow'>
				<NewSelect
					id='totg_interval'
					label={`TOTG - ${Dextrolgap}`}
					options={[
						{ id: 'NOT_EVALUATED', name: Notevaluated },
						{ id: 'ONE_HOUR', name: Collectedafteronehour },
						{ id: 'TWO_HOURS', name: Collectedaftertwohour },
					]}
					onSelect={handleSelect}
					selected={fastBloodGlucose.totg_interval}
					canBeEmpty={false}
				/>
				{fastBloodGlucose.totg_interval ? null : null}
			</div>
			<div className='AddNewExamModal-ButtonRow'>
				<Button
					label={Savebloodglucose}
					onClick={handleSubmit}
				/>
			</div>
		</div>
	)
}
