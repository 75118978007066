import './StreptococcusGBSView.css'
import React from 'react'
import AlertRowText from '../../../../../../../../../../../../../component/alertRowText/alertRowText'
import { getGBSResult } from '../utils'
import { Button, IcoBin, IcoPencil, List } from '../../../../../../../../../../../../../component'
import { useThousandDays } from '../../../../../../../ThousandDaysSection'
import { convertDate } from '../../../../../../../../../../../../../utils/convertDate'
import StreptococcusGBSWrite from '../Write/StreptococcusGBSWrite'
import api from '../../../../../../../../../../../../../helpers/api'

export default function StreptococcusGBSView() {
	const { 
		selectedLanguage, 
		holdingProject: { gbs_exams }, 
		setModalInfo,
		fetchThousandDaysProjects
	} = useThousandDays()

	const {
		Dates,
		Conclusion,
		Positive,
		Negative,
		Notinvestigated,
		Groupbstreptococcustest,
		Notinformation 
	} = require('../../../../../../../../../../../../data/translation.json')[selectedLanguage]

	const translate = {
		POSITIVE: Positive,
		NEGATIVE: Negative,
		NOT_SEARCHED: Notinvestigated,
	}

	const headDefaultStreptococcusGBS = [
		{ colunm: 'h_gbs', text: 'GBS' },
		{ colunm: 'h_gbs_conclusion', text: Conclusion },
		{ colunm: 'h_date', text: Dates, width: '100px' },
		{ colunm: 'h_action', text: '', width: '100px' },
	]

	const handleDelete = async (id) => {
		await api.delete(`exam/streptococcus_gbs/${id}/`)
		fetchThousandDaysProjects()
	}

	const openExamWriteModal = (holdingExam) => {
		setModalInfo(prev => ({
			...prev,
			open: true,
			title: Groupbstreptococcustest,
			content: (
				<div className='SOAP-ThousandDaysExamSubSection-Modal'>
					<StreptococcusGBSWrite
						holdingStreptococcusGBS={holdingExam} 
						setModalInfo={setModalInfo} 
					/>
				</div>
			),
		}))
	}

	return (
		<div className='StreptococcusGBSView'>
			<div>
				<List
					head={headDefaultStreptococcusGBS}
					data={gbs_exams}
					noData={Notinformation}
					listCustom={(exam, i) => {
						exam['h_gbs'] = translate[exam?.gbs] || '-'
						exam['h_gbs_conclusion'] = getGBSResult(exam.gbs, selectedLanguage)?.content
							? <AlertRowText {...getGBSResult(exam.gbs, selectedLanguage)} />
							: '-'
						exam['h_date'] = exam?.date ? convertDate(exam.date, '-', '/') : '-'
						exam['h_action'] = (
							<div>
								<Button
									color='secondary'
									type='btn circle'
									action={() => openExamWriteModal(exam)}
									title='Editar exame'>
									<IcoPencil />
								</Button>
								<Button
									color='danger'
									type='btn circle'
									action={() => handleDelete(exam?.id)}
									title='Excluir exame'>
									<IcoBin />
								</Button>
							</div>
						)
						return exam
					}}
				/>
			</div>
		</div>
	)
}
