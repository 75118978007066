import React from 'react'

export default function IcoBoxDelete({ style={} }) {
    return <svg style={style} viewBox="0 0 512 512">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
            <path d="M3673 4785 c-201 -36 -383 -134 -524 -283 -359 -375 -357 -956 5
            -1330 169 -176 393 -276 641 -289 455 -23 853 273 977 726 31 110 31 352 0
            462 -103 377 -397 649 -769 714 -102 18 -232 18 -330 0z m-56 -607 l223 -222
            223 222 c199 198 227 222 255 222 44 0 82 -38 82 -82 0 -28 -24 -56 -222 -255
            l-222 -223 222 -223 c198 -199 222 -227 222 -255 0 -44 -38 -82 -82 -82 -28 0
            -56 24 -255 222 l-223 222 -223 -222 c-199 -198 -227 -222 -255 -222 -44 0
            -82 38 -82 82 0 28 24 56 222 255 l222 223 -222 223 c-198 199 -222 227 -222
            255 0 22 9 41 25 57 16 16 35 25 57 25 28 0 56 -24 255 -222z"/>
            <path d="M2175 4306 c-174 -77 -1752 -799 -1758 -805 -4 -4 404 -198 908 -430
            l916 -423 407 188 c224 103 409 189 411 190 2 2 -19 30 -46 62 -154 177 -246
            370 -278 582 -22 139 -19 247 9 400 l7 35 -239 108 c-246 111 -278 119 -337
            93z"/>
            <path d="M320 2304 c0 -1147 -3 -1096 56 -1143 16 -13 421 -207 899 -431 l870
            -407 7 666 c4 366 7 859 5 1094 l-2 429 -790 364 c-434 201 -847 391 -917 424
            l-128 59 0 -1055z"/>
            <path d="M2765 2714 l-440 -203 -7 -873 c-4 -480 -7 -973 -5 -1095 l2 -222
            885 410 c487 226 896 421 909 433 52 47 51 29 51 844 l0 759 -77 -18 c-162
            -40 -358 -35 -540 12 -74 19 -243 94 -308 137 l-30 20 -440 -204z"/>
        </g>
    </svg>
}
