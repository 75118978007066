import './ImediatePostPartum.css'
import React, { useEffect } from 'react'
import { RadioButton } from '../../../../../../../../../../component'
import NewSelect from '../../../../../../../../../../newcomponents/form/select/select'
import ReactQuill from 'react-quill'
import { useThousandDays } from '../../../../ThousandDaysSection'

const {
	default_options,
	transvaginal_bleeding_options,
} = require('./ImediatePostPartum.json')

export default function ImediatePostPartum() {
	const {
		selectedLanguage,
		imediatePostPartum,
		setImediatePostPartum,
		saveImediatePostPartum,
	} = useThousandDays()

	const {
		Transvaginal_Bleeding_Options,
		Default_Options,
		Transvaginalbleeding,
		Pinardhorn,
		Oxytocic,
		Breastfeedinginthefirsthour,
		Observations,
		Immediatepostpartum,
	} = require('../../../../../../../../../data/translation.json')[selectedLanguage]

	const transvaginal_bleeding_option = transvaginal_bleeding_options.map(
		(option) => ({
			id: option.id,
			name: Transvaginal_Bleeding_Options[option.id],
		})
	)

	const default_option = default_options.map((option) => ({
		id: option.id,
		name: Default_Options[option.id],
	}))
	useEffect(() => {
		saveImediatePostPartum()
	}, [
		imediatePostPartum.pinard_global,
		imediatePostPartum.transvaginal_bleeding,
		imediatePostPartum.oxytocic,
		imediatePostPartum.breastfeeding_in_frst_hour,
	])

	const handleChange = (event) => {
		setImediatePostPartum((prev) => ({
			...prev,
			[event.target.name]: event.target.value,
		}))
	}

	const handleSelect = (event) => {
		setImediatePostPartum((prev) => ({
			...prev,
			[event.target.id]: event.target.selected,
		}))
	}

	return (
		<div className='Partograph-ImediatePostPartum'>
			<p className='ImediatePostPartum-Subtitle'>{Immediatepostpartum}</p>
			<div className='PregnancyRiskCalculator-Separator' />
			<div className='ImediatePostPartum-2fr'>
				<div>
					<b>{Transvaginalbleeding}</b>
					<div className='Partograph-ActiveLabour-Input'>
						<NewSelect
							id='transvaginal_bleeding'
							options={transvaginal_bleeding_option}
							onSelect={handleSelect}
							selected={imediatePostPartum.transvaginal_bleeding}
							canBeEmpty={false}
						/>
					</div>
				</div>
				<RadioButton
					label={
						<p>
							<b>{Pinardhorn}</b>
						</p>
					}
					name='pinard_global'
					action={handleChange}
					checked={imediatePostPartum.pinard_global}
					options={default_option}
				/>
			</div>
			<div className='ImediatePostPartum-2fr'>
				<RadioButton
					label={
						<p>
							<b>{Oxytocic}</b>
						</p>
					}
					name='oxytocic'
					action={handleChange}
					checked={imediatePostPartum.oxytocic}
					options={default_option}
				/>
				<RadioButton
					label={
						<p>
							<b>{Breastfeedinginthefirsthour}</b>
						</p>
					}
					name='breastfeeding_in_frst_hour'
					action={handleChange}
					checked={imediatePostPartum.breastfeeding_in_frst_hour}
					options={default_option}
				/>
			</div>
			<div>
				<p>
					<b>{Observations}</b>
				</p>
				<ReactQuill
					name='notes'
					theme='snow'
					value={imediatePostPartum.notes}
					onChange={(text) =>
						setImediatePostPartum((prev) => ({ ...prev, notes: text }))
					}
					onBlur={saveImediatePostPartum}
				/>
			</div>
		</div>
	)
}
