import { buildDate } from '../../../../../../../../../../utils/convertDate'

export const strpBirthForecast = (birthforescast) => {
    let birth_date = new Date(birthforescast?.birth_date)

    return {
        'birthforecast_year': birth_date ? `${birth_date.getFullYear()}` : '',
        'birthforecast_month': birth_date ? `${birth_date.getMonth() + 1 < 10 ? '0': ''}${birth_date.getMonth() + 1}` : '',
        'birthforecast_day': birth_date ? `${birth_date.getDate() < 10 ? '0': ''}${birth_date.getDate()}` : '',
    }
}

export function parseBirthForecast(birthforescast, project_id) {
    return {
        project: project_id,
        birth_date: buildDate(birthforescast?.birthforecast_year, birthforescast?.birthforecast_month, birthforescast?.birthforecast_day, '10', '10')
    }
}

export function getBirthForecastCurrentDate() {
    let currentDate = new Date()
    let birthforecast_day = currentDate.getDate().toString();
    birthforecast_day = birthforecast_day.length === 1 ? '0' + birthforecast_day : birthforecast_day;
    
    let birthforecast_month = (currentDate.getMonth() + 1).toString();
    birthforecast_month = birthforecast_month.length === 1 ? '0' + birthforecast_month : birthforecast_month;

    let birthforecast_year = currentDate.getFullYear().toString();

    return { birthforecast_day, birthforecast_month, birthforecast_year };
}