import './PreNatalAttendance.css'
import React, { useState } from 'react'
import { Mask1DecimalPlace } from '../../../../../../../../../../component/mask/mask'
import AlertRowText, {
	AlertRowTextType,
} from '../../../../../../../../../../component/alertRowText/alertRowText'
import { getFetalMovementsResult, getHeartbeatResult, getPresentationResult, getUterineHeightResult } from './utils'
import { useThousandDays } from '../../../../ThousandDaysSection'
import Button, { ButtonModel } from '../../../../../../../../../../newcomponents/button/button'
import { formatDate } from '../../../../../../../../../../utils/convertDate'
import { MaskXXWeekYDay } from '../../../InitSubSection/utils'
import ArrowDownIco from '../../../../../../../../../../component/icon/arrow_down'
import PreNatalAttendanceEditor from './subcomponents/PreNatalAttendanceEditor'
import { deletePreNatalAttendance } from '../../../../http'
import { DataTransform } from '../../../../../../../../../../component'

const renderSession = (title, content) => {
	return (
		<div>
			<p>
				<b>{title}</b>
			</p>
			{!content ||
			content === '' ||
			content === '<p><br></p>' ||
			content === 'Não avaliado' ? (
				<p>-</p>
			) : (
				<p dangerouslySetInnerHTML={{ __html: content }}></p>
			)}
		</div>
	)
}

export default function PreNatalAttendance() {
	const {
		selectedLanguage,
		setModalInfo,
		getPregnancyAgeAt,
		holdingProject,
		fetchThousandDaysProjects
	} = useThousandDays()
	const [activeIndex, setActiveIndex] = useState(null)

	const previousPrenatalAttendances = holdingProject?.previous_prenatal_attendances || []

	const {
		MedicalCareOn,
		Newattention,
		Editattetion,
		Deleteattetion,
		Attentions,
		Achieved,
		Nomedicalcareprovided,
		Gestationalage,
		Maternalweight,
		Fetalmovements,
		Fetalheartbeats,
		Presentation,
		Uterineheight,
		BloodPressure,
		Systolic,
		Diastolic,
		Maternalcomplaints,
		Change,
		Fetalmovements_Options,
		Presentation_Options,
	} = require('../../../../../../../../../data/translation.json')[selectedLanguage]

	const toggleAccordion = index => {
		setActiveIndex(index === activeIndex ? null : index)
	}

	const handleDelete = async (preNatal) => {
		await deletePreNatalAttendance(preNatal, holdingProject?.id)
		await fetchThousandDaysProjects()
	}

	return (
		<div>
			<p className='LastMenstruation-Subtitle'>{Attentions}</p>
			<div className='PreNatalAttendance-Separator' />
			<Button
				label={Newattention}
				onClick={() =>
					setModalInfo(prev => ({
						...prev,
						title: '',
						open: true,
						content: <PreNatalAttendanceEditor previousPrenatalAttendances={previousPrenatalAttendances} />,
					}))
				}
			/>
			<p className='PreNatalAttendance-Subtitle'>
				<strong>{Achieved}</strong>
			</p>
			<div className='PreNatalAttendance-Separator' />
			{previousPrenatalAttendances.length ? (
				previousPrenatalAttendances.map((previousPrenatalAttendanceCursor, index) => (
					<div key={index}>
						<div className='PreNatalAttendance-AccordionNav' onClick={() => toggleAccordion(index)}>
						
							{MedicalCareOn} {DataTransform(previousPrenatalAttendanceCursor.prenatal_attendance_date, { format: selectedLanguage === 'pt-BR' ? 'DD/MM/YYYY' : 'MM/DD/YYYY' })} <ArrowDownIco /></div>
						{
							activeIndex === index
							? <div className={`PreNatalAttendance-Section ${activeIndex === index ? 'open' : ''}`}>
							<div className='PreNatalAttendance-Grid'>
								<div className='PreNatalAttendance-Grid-Session'>
									{renderSession(Gestationalage,
										getPregnancyAgeAt(previousPrenatalAttendanceCursor.prenatal_attendance_date)?.age
											? MaskXXWeekYDay(getPregnancyAgeAt(previousPrenatalAttendanceCursor.prenatal_attendance_date).age)
											: '-'
									)}
								</div>
								<div className='PreNatalAttendance-Grid-Session'>
									{renderSession(
										Maternalweight,
										typeof previousPrenatalAttendanceCursor?.weight == 'number' ||
											previousPrenatalAttendanceCursor?.weight
											? `${Mask1DecimalPlace(previousPrenatalAttendanceCursor.weight)} kg`
											: '-'
									)}
								</div>
								<div className='PreNatalAttendance-Grid-Session'>
									{renderSession(
										Fetalmovements,
										previousPrenatalAttendanceCursor?.fetal_movements
											? Fetalmovements_Options[previousPrenatalAttendanceCursor.fetal_movements]
											: '-'
									)}
									{previousPrenatalAttendanceCursor.fetal_movements ? (
										<AlertRowText
											{...getFetalMovementsResult(
												previousPrenatalAttendanceCursor,
												previousPrenatalAttendances,
												selectedLanguage
											)}
										/>
									) : (
										<div />
									)}
								</div>
								<div className='PreNatalAttendance-Grid-Session'>
									{renderSession(Fetalheartbeats,typeof previousPrenatalAttendanceCursor?.heartbeat == 'number' || previousPrenatalAttendanceCursor?.heartbeat ? previousPrenatalAttendanceCursor.heartbeat : '-')}
									{previousPrenatalAttendanceCursor.heartbeat ? (
										<AlertRowText {...getHeartbeatResult(previousPrenatalAttendanceCursor.heartbeat, selectedLanguage)} />
									) : (
										<div />
									)}
								</div>
								<div className='PreNatalAttendance-Grid-Session'>
									{renderSession(
										Presentation,
										previousPrenatalAttendanceCursor?.presentation
											? Presentation_Options[
													previousPrenatalAttendanceCursor.presentation
											]
											: '-'
									)}
									{previousPrenatalAttendanceCursor.presentation ? (
										<AlertRowText
											{...getPresentationResult(
												previousPrenatalAttendanceCursor,
												getPregnancyAgeAt(
													previousPrenatalAttendanceCursor.prenatal_attendance_date
												).age,
												selectedLanguage
											)}
										/>
									) : (
										<div />
									)}
								</div>
								<div className='PreNatalAttendance-Grid-Session'>
									{renderSession(
										Uterineheight,
										typeof previousPrenatalAttendanceCursor?.uterine_height ==
											'number' || previousPrenatalAttendanceCursor?.uterine_height
											? Mask1DecimalPlace(
													previousPrenatalAttendanceCursor.uterine_height
											)
											: '-'
									)}
									{previousPrenatalAttendanceCursor.uterine_height ? (
										<AlertRowText
											{...getUterineHeightResult(
												previousPrenatalAttendanceCursor,
												getPregnancyAgeAt(
													previousPrenatalAttendanceCursor.prenatal_attendance_date
												).age,
												Change
											)}
										/>
									) : (
										<div />
									)}
								</div>
							</div>
							<div>
								<p><b>{BloodPressure}</b></p>
								<p>
									<span>{Systolic}: { previousPrenatalAttendanceCursor?.systolic || '-'} </span>
									<br />
									<span>{Diastolic}: { previousPrenatalAttendanceCursor?.diastolic || '-'}</span>
								</p>
							</div>
							<div>
								{renderSession(Maternalcomplaints, previousPrenatalAttendanceCursor.patient_complaints)}
							</div>
							<div className='PreNatalAttendance-BtnBox'>
								<Button 
									label={Deleteattetion}
									model={ButtonModel.SECONDARY}
									onClick={() => handleDelete(previousPrenatalAttendanceCursor)}
								/>
								<Button
									label={Editattetion}
									model={ButtonModel.PRIMARY}
									onClick={() =>
										setModalInfo(prev => ({
											...prev,
											title: '',
											open: true,
											content: <PreNatalAttendanceEditor 
												initPreNatalAttendanceEditor={previousPrenatalAttendanceCursor} 
												previousPrenatalAttendances={previousPrenatalAttendances}
											/>,
										}))
									}
								/>
							</div>
						</div>
						: null
						}
					</div>
				))
			) : (
				<AlertRowText
					type={AlertRowTextType.INFO}
					content={Nomedicalcareprovided}
					outlined
				/>
			)}
		</div>
	)
}
