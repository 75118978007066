import { buildDate } from "../../../../../../../../../../utils/convertDate"

export const strpPartnerPrenatal = (partner_prenatal=null) => {
    let birthdate = partner_prenatal?.birthdate ? new Date(partner_prenatal.birthdate) : ''

    return {
        'project': partner_prenatal?.project || -1,
        'unknownpartner': partner_prenatal?.unknownpartner || false,
        'name': partner_prenatal?.name || '',
        'instruction': partner_prenatal?.instruction || 'NOT_EVALUATED',
        'birthdate_day': birthdate ? `${birthdate.getDate() < 10 ? '0': ''}${birthdate.getDate()}` : '',
        'birthdate_month': birthdate ? `${birthdate.getMonth() + 1 < 10 ? '0': ''}${birthdate.getMonth() + 1}` : '',
        'birthdate_year': birthdate ? `${birthdate.getFullYear()}` : '',
        'vdrl_or_syphilis_test': partner_prenatal?.vdrl_or_syphilis_test || 'vdrl',
        'vdrl': partner_prenatal?.vdrl || 'NOT_EVALUATED',
        'syphilis_test': partner_prenatal?.syphilis_test || 'NOT_EVALUATED',
        'hiv': partner_prenatal?.hiv || 'NOT_EVALUATED',
        'hbsag': partner_prenatal?.hbsag || 'NOT_EVALUATED',
        'family_background': partner_prenatal?.family_background || [],
        'status': partner_prenatal?.status || ''
    }
}

export const parsePartnerPreNatal = (partner_prenatal, project_id) => {

    return {
        'project': project_id,
        'unknownpartner': partner_prenatal?.unknownpartner || false,
        'name': partner_prenatal?.name || '',
        'instruction': partner_prenatal?.instruction || '',
        'birthdate': buildDate(partner_prenatal?.birthdate_year, partner_prenatal?.birthdate_month, partner_prenatal?.birthdate_day, '10', '10'),
        'vdrl_or_syphilis_test': partner_prenatal?.vdrl_or_syphilis_test 
            ? (typeof partner_prenatal.vdrl_or_syphilis_test === 'object' ? partner_prenatal.vdrl_or_syphilis_test.id : partner_prenatal.vdrl_or_syphilis_test) 
            : '',
        'vdrl': partner_prenatal?.vdrl || 'NOT_EVALUATED',
        'syphilis_test': partner_prenatal?.syphilis_test || 'NOT_EVALUATED',
        'hiv': partner_prenatal?.hiv || 'NOT_EVALUATED',
        'hbsag': partner_prenatal?.hbsag || 'NOT_EVALUATED',
        'family_background': partner_prenatal?.family_background || [],
        'status': partner_prenatal?.status === 'CREATED_BY_PATIENT' || partner_prenatal?.status === 'UPDATED_BY_PATIENT'
            ? 'CONFIRMED_BY_DOCTOR'
            : 'CREATED_BY_DOCTOR'
    }
}