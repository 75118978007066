import { AlertRowTextType } from '../../../../../../../../../../../../component/alertRowText/alertRowText'

const gbs_translate = require('../../../../../../../../../../../data/translation.json')

export function getGBSResult(gbs, selectedLanguage) {
	const { Change } = gbs_translate[selectedLanguage]
	if (gbs === 'NOT_SEARCHED') return { type: '', content: '' }

	return gbs === 'POSITIVE'
		? { type: AlertRowTextType.WARNING, content: Change }
		: { type: AlertRowTextType.SUCCESS, content: 'Normal' }
}

export function getGBSResults(gbs_exams = [], selectedLanguage) {
	let alterations = []

	for (let i = 0; i < gbs_exams.length; i++) {
		let gbs = getGBSResult(gbs_exams[i].gbs, selectedLanguage)
		const { Groupbstreptococcus } = gbs_translate[selectedLanguage]
		if (gbs?.type === AlertRowTextType.WARNING) {
			alterations.push({
				type: AlertRowTextType.INFO,
				content: Groupbstreptococcus,
			})
			break
		}
	}

	return alterations
}
