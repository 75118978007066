import './NewRequestModal.css'
import React, { useRef, useState } from "react";
import { useDispatch } from 'react-redux';
import { AddAlert, AlertType, Input, MaskDataHora } from '../../../../../../../../component';
import NewButton, { ButtonModel } from '../../../../../../../../newcomponents/button/button';
import { postInventoryMaterialAssignment } from '../../../../http';
import { useApp } from '../../../../../../../../layout/App';
import { loaded, loading } from '../../../../../../../../layout/redux/AppActions';
import { useInventoryMaterials } from '../../../../InventoryMaterials';
import { MODAL_INIT_PROPS } from '../../../../../../../../newcomponents/modal/modal';
import IcoBoxBarcode from '../../../../../../../../component/icon/box_barcode';
import SelectPatient from '../../../../../../../../component/SelectPatient/SelectPatient';

export default function NewRequestModal({ holdingInventoryMaterial, fetchMaterials=async () => null }) {
    const [currentPage, setCurrentPage] = useState(1)
    const [holdingRequest, setHoldingRequest] = useState({ requested_amount: 0, justification: '', patient: {} })

    const pages = {
        1: <RequestModalPart1 
            holdingInventoryMaterial={holdingInventoryMaterial}
            holdingRequest={holdingRequest}
            setHoldingRequest={setHoldingRequest}
            setCurrentPage={setCurrentPage}
        />,
        2: <RequestModalPart2
            holdingInventoryMaterial={holdingInventoryMaterial}
            holdingRequest={holdingRequest}
            setHoldingRequest={setHoldingRequest}
            setCurrentPage={setCurrentPage}
            fetchMaterials={fetchMaterials}
        />
    }
    
    return <div className='NewRequestModal'>
        {
            /*
        <div className='NewRequestModal-Subtitle'>
            <div><IcoBoxBarcode /></div>
             <b>Alocar Material</b>
        </div>
            */
        }
        { pages?.[currentPage] || null }
    </div>
}

/**
 * 
 * RequestModalPart1
 * 
 */
function RequestModalPart1({ holdingInventoryMaterial=null, holdingRequest=null, setHoldingRequest=() => null, setCurrentPage=() => null }) {
    const textareaRef = useRef(null)

    const handleChange = (event) => {
        let value = event.target.value
        if ((event.target.name === 'requested_amount') && (value > holdingInventoryMaterial?.remaining || value > 10000000)) {
            value = holdingInventoryMaterial?.remaining || 10000000
        } else if ((event.target.name === 'requested_amount') && value < 0) {
            value = 0
        } else if (event.target.name === 'requested_amount') {
            value = Number(value)
        } 
        setHoldingRequest(prev => ({ ...prev, [event.target.name]: value }))
    }

    const adjustTextareaHeight = () => {
		textareaRef.current.style.height = 'auto'
		const scrollHeight = textareaRef.current.scrollHeight
		const maxHeight = parseInt(window.getComputedStyle(textareaRef.current).maxHeight, 10)
		
        if (scrollHeight > maxHeight) {
			textareaRef.current.style.height = `${maxHeight}px`
		} else {
			textareaRef.current.style.height = `${scrollHeight}px`
		}
	}

	const handleChangeById = (event) => {
		adjustTextareaHeight()
		setHoldingRequest(prev => ({ ...prev, [event.target.id]: event.target.value }))
	}

    return <div className='NewRequestModal-Body'>
        <div>
            <div className='NewRequestModal-Body-Parameters'>
                <div><strong>Nome </strong><br /><span>{holdingInventoryMaterial?.name || '-'}</span></div>
                <div><strong>Código </strong><br /><span>{holdingInventoryMaterial?.code || '-'}</span></div>
                <div><strong>Categoria </strong><br /><span>{holdingInventoryMaterial?.category?.name || '-'}</span></div>
                <div><strong>Localização </strong><br /><span>{holdingInventoryMaterial?.location?.name || '-'}</span></div>
                <div><strong>Fabricante </strong><br /><span>{holdingInventoryMaterial?.manufacturer?.name || '-'}</span></div>
                <div><strong>Apresentação </strong><br /><span>{holdingInventoryMaterial?.presentation || '-'}</span></div>
                <div><strong>Validade </strong><br /><span>{holdingInventoryMaterial?.expiration_date ? MaskDataHora(holdingInventoryMaterial.expiration_date, 'DD/MMM/AAAA', '-')?.data : null}</span> </div>
                <div><strong>Amostra Grátis </strong><br /><span>{holdingInventoryMaterial?.is_a_free_sample ? 'Sim' : 'Não'}</span></div>
                <div><strong>Restante </strong><br /><span>{holdingInventoryMaterial?.remaining || '-'}</span></div>
            </div>
        </div>
        <div>
            <p><b>Detalhes</b></p>
            <div>
                <div className='NewRequestModal-Body-Detail-Box'>
                    <p>Quantidade:</p>
                    <div className='NewRequestModal-Body-Detail-Box-Input'>
                        <Input
                            name='requested_amount'
                            action={handleChange}
                            value={holdingRequest.requested_amount}
                            type='number'
                            min={'0'}
                            max={holdingInventoryMaterial?.remaining || '10000000'}
                        />
                    </div>
                </div>
                <div className='NewRequestModal-Body-Detail-Box'>
                    <p>Justificativa:</p>
                    <div className='NewRequestModal-Body-Detail-Box-Justification'>
                        <textarea
                            ref={textareaRef}
                            id='justification'
                            placeholder='Motivo de solicitação (opcional)'
                            className='NewRequestModal-Body-Detail-Box-Justification-TextEditor'
                            value={holdingRequest.justification}
                            onChange={handleChangeById}
                            rows='5'
                        />
                    </div>
                </div>
            </div>
        </div>
        <div className='NewRequestModal-BtnBox'>
            <NewButton 
                label='Próximo'
                onClick={() => setCurrentPage(2)}
                disabled={!holdingRequest?.requested_amount}
            />
        </div>
    </div>
}

/**
 * 
 * RequestModalPart2
 * 
 */
function RequestModalPart2({ holdingInventoryMaterial=null, holdingRequest=null, setHoldingRequest=() => null, setCurrentPage=() => null, fetchMaterials=async () => null }) {
    const dispatch = useDispatch()
    const { setModalInfo } = useInventoryMaterials()
    const { currentHealthPlaceUser } = useApp()

    const handleSubmit = async () => {
        const payload = {
            material: holdingInventoryMaterial?.id,
            health_place_user: currentHealthPlaceUser?.id,
            health_place: currentHealthPlaceUser?.health_place?.id,
            patient: holdingRequest?.patient?.id,
            requested_amount: holdingRequest?.requested_amount || 0,
            justification: holdingRequest?.justification || ''
        }

        dispatch(loading())
        try {
            await postInventoryMaterialAssignment(payload)
            await fetchMaterials({ "offset": 0 })
            dispatch(AddAlert('Alocação de Material', 'Material alocado com sucesso!', AlertType.SUCCESS))
        } catch (err) {
            dispatch(AddAlert('Alocação de Material', 'Falha ao alocar material', AlertType.SUCCESS))
            console.error('NewRequestModal ~ postInventoryMaterialAssignment ~ ', err)
        }
        dispatch(loaded())
        setModalInfo(MODAL_INIT_PROPS)
    }

    return <div className='NewRequestModal-Body'>
        <div className='NewRequestModal-Body-SearchPatient'>
            <p>Apesar de recomendado, o preenchimento do campo de paciente é opcional.</p>
            <SelectPatient 
                selectedPatient={holdingRequest?.patient} 
                handleSelectPatient={newSelectedPatient => setHoldingRequest(prev => ({ ...prev, patient: newSelectedPatient }))}
                inputErr={false}
            />
        </div>
        <div className='NewRequestModal-BtnBox'>
            <NewButton 
                label='Voltar'
                onClick={() => setCurrentPage(1)}
                model={ButtonModel.SECONDARY}
            />
            <NewButton 
                label='Alocar material'
                onClick={handleSubmit}
                disabled={!holdingRequest?.requested_amount}
            />
        </div>
    </div>
}