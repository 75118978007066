import { AlertRowTextType } from "../../../../../../../../../../../../component/alertRowText/alertRowText"

export function getHIVResult(hiv, Change) {
    if (hiv === 'REAGENT') return { type: AlertRowTextType.WARNING, content: Change}
    if (hiv === 'NON_REAGENT') return { type: AlertRowTextType.SUCCESS, content: 'Normal'}
    return { type: '', content: ''}
}

export function getVDRLResult(vdrl, Change) {
    const IS_VDRL_CHANGE = ['REAGENT','ONE_BY_ONE','ONE_BY_TWO','ONE_BY_FOUR','ONE_BY_EIGHT','ONE_BY_SIXTEEN','ONE_BY_THIRTY_TWO','ONE_BY_SIXTY_FOUR','GREATER_ONE_BY_SIXTY_FOUR'].includes(vdrl)

    if (IS_VDRL_CHANGE) return { type: AlertRowTextType.WARNING, content: Change }
    if (vdrl === 'NOT_EVALUATED' || vdrl === 'INDETERMINATE') return { type: '', content: '' }
    return { type: AlertRowTextType.SUCCESS, content: 'Normal' }
}

export function getHbsAgResult(hbsag, Change) {
    if (hbsag === 'REAGENT') return { type: AlertRowTextType.WARNING, content: Change}
    if (hbsag === 'NON_REAGENT') return { type: AlertRowTextType.SUCCESS, content: 'Normal'}
    return { type: '', content: ''}
}

export function getAntiHCVResult(anti_hcv, Change) {
    if (anti_hcv === 'REAGENT') return { type: AlertRowTextType.WARNING, content: Change}
    if (anti_hcv === 'NON_REAGENT') return { type: AlertRowTextType.SUCCESS, content: 'Normal'}
    return { type: '', content: ''}
}

export function getHIVVDRLHbsAgAntiHCVResults(hiv_hbs_antihcv_vdrl_exams=[], selectedLanguage) {
    let alterations = []

    for (let i = 0; i < hiv_hbs_antihcv_vdrl_exams.length; i++) {
        let hiv = getHIVResult(hiv_hbs_antihcv_vdrl_exams[i].hiv)
        if (hiv?.type === AlertRowTextType.WARNING) {
            alterations.push({ type: AlertRowTextType.INFO, content: 'HIV' })
            break
        }
    }

    for (let i = 0; i < hiv_hbs_antihcv_vdrl_exams.length; i++) {
        let vdrl = getVDRLResult(hiv_hbs_antihcv_vdrl_exams[i].vdrl)
        if (vdrl?.type  === AlertRowTextType.WARNING) {
            alterations.push({ type: AlertRowTextType.INFO, content: 'VDRL' })
            break
        }
    }

    for (let i = 0; i < hiv_hbs_antihcv_vdrl_exams.length; i++) {
        let hbsag = getHbsAgResult(hiv_hbs_antihcv_vdrl_exams[i].hbsag)
        if (hbsag?.type === AlertRowTextType.WARNING) {
            alterations.push({ type: AlertRowTextType.INFO, content: 'HbsAg' })
            break
        }
    }

    for (let i = 0; i < hiv_hbs_antihcv_vdrl_exams.length; i++) {
        let anti_hcv = getAntiHCVResult(hiv_hbs_antihcv_vdrl_exams[i].anti_hcv)
        if (anti_hcv?.type === AlertRowTextType.WARNING) {
            alterations.push({ type: AlertRowTextType.INFO, content: 'Anti-HCV' })
            break
        }
    }

    return alterations;
}