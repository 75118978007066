import './InitSubSection.css'
import React from 'react'
import { DataTransform } from '../../../../../../../../component'
import PregnantIcon from '../../../../../../../../component/icon/pregnant'
import { MaskXXWeekYDay, getAlterations, translateABOAndRHFactor } from './utils'
import { useThousandDays } from '../../ThousandDaysSection'
import AlertRowText, { AlertRowTextType } from '../../../../../../../../component/alertRowText/alertRowText'
import ObstetricUltrasonographyWrite from '../GestationSubSection/subcomponents/Exam/forms/ObstetricUltrasonography/ObstetricUltrasonographyWrite/UltrasonographyWrite'
import ABOGroupRHFactorWrite from '../GestationSubSection/subcomponents/Exam/forms/ABOGroupRHFactor/Write/ABOGroupRHFactorWrite'
import { LastMenstruationModal } from '../GestationSubSection/subcomponents/LastMenstruation/LastMenstruation'
import { buildDate } from '../../../../../../../../utils/convertDate'
import BloodDropIco from '../../../../../../../../component/icon/blood'
import RiskBadge from '../GestationSubSection/subcomponents/PregnancyRiskCalculator/subcomponents/RiskBadge'

export default function InitSubSection() {
	const {
		selectedLanguage,
		holdingProject,
		obstetricData,
		setModalInfo,
		getPregnancyAgeAt,
	} = useThousandDays()

	const today_pregnancy_age = getPregnancyAgeAt()
	const abo_group_rh_factor_exam = holdingProject?.abo_group_rh_factor_exams?.[0]
	const obstetric_ultrasonography_exam = holdingProject?.obstetric_ultrasonography_exams?.[0]

	const {
		Init,
		GeneralInfo,
		LastMenstruation,
		LastUltrasound,
		Abobloodgroupandrhfactor,
		ABOGroupFactorRH,
		Add,
		NoChangesIdentified,
		Alterations,
		Weekss,
		Dayss,
		UltrasoundType_Options,
		Obstetricultrasound,
		Dateoflastmenstrualperiod,
	} = require('../../../../../../../data/translation.json')[selectedLanguage]

	const openLastMenstruationModal = () => {
		setModalInfo(prev => ({
			...prev,
			title: Dateoflastmenstrualperiod,
			open: true,
			content: (
				<div className='SOAP-ThousandDaysExamSubSection-Modal'>
					<LastMenstruationModal />
				</div>
			),
		}))
	}

	const openUltrassongraphyModal = () => {
		setModalInfo(prev => ({
			...prev,
			title: Obstetricultrasound,
			open: true,
			content: (
				<div className='SOAP-ThousandDaysExamSubSection-Modal'>
					<ObstetricUltrasonographyWrite setModalInfo={setModalInfo} />
				</div>
			),
		}))
	}

	const openABORHFactorModal = () => {
		setModalInfo(prev => ({
			...prev,
			title: Abobloodgroupandrhfactor,
			open: true,
			content: (
				<div className='SOAP-ThousandDaysExamSubSection-Modal'>
					<ABOGroupRHFactorWrite setModalInfo={setModalInfo} />
				</div>
			),
		}))
	}

	function formatDate(dateStr) {
		const parts = dateStr.split('-')
		const [year, month, day] = parts
		return `${day}/${month}/${year}`
	}

	const isThereValidABO =
		(abo_group_rh_factor_exam?.abo_group && abo_group_rh_factor_exam?.abo_group != 'NOT_EVALUATED') ||
		(abo_group_rh_factor_exam?.rh_factor && abo_group_rh_factor_exam?.rh_factor != 'NOT_EVALUATED')

	return (
		<div className='InitSubSection'>
			<p className='LastMenstruation-Subtitle'>{Init}</p>
			<div className='InitSubSection-Separator' />
			<div>
				<p>
					<b>{GeneralInfo}</b>
				</p>
				<p>
					<b>{LastUltrasound}:</b>{' '}
					<span
						onClick={openUltrassongraphyModal}
						className='InitSubSection-CTA'>
						{obstetric_ultrasonography_exam
							? `${obstetric_ultrasonography_exam.ultrasound_weeks} ${Weekss} 
							${obstetric_ultrasonography_exam.ultrasound_days} ${Dayss} 
							${DataTransform(obstetric_ultrasonography_exam.date, { format: selectedLanguage === 'pt-BR' ? 'DD/MM/YYYY' : 'MM/DD/YYYY' })}`
							: Add}
					</span>{' '}
					<br />
					<b>{LastMenstruation}: </b>
					<span
						onClick={openLastMenstruationModal}
						className='InitSubSection-CTA'>
						
						{!buildDate(obstetricData.last_menstruation_year,obstetricData.last_menstruation_month,obstetricData.last_menstruation_day)
							? Add
							: DataTransform(buildDate(obstetricData.last_menstruation_year, obstetricData.last_menstruation_month, obstetricData.last_menstruation_day), { format: selectedLanguage === 'pt-BR' ? 'DD/MM/YYYY' : 'MM/DD/YYYY' })
						}
						<br />
					</span>
					{!isThereValidABO ? (
						<span>
							<b>{ABOGroupFactorRH}: </b>
							<span
								onClick={openABORHFactorModal}
								className='InitSubSection-CTA'>
								{Add}
							</span>
							<br />
						</span>
					) : null}
				</p>
				<p> </p>
			</div>
			<div className='InitSubSection-AlertsRow'>
				{today_pregnancy_age.age && today_pregnancy_age.type ? (
					<div className='InitSubSection-PregnancyAge'>
						<div className='InitSubSection-PregnancyAge-Icon'>
							<PregnantIcon style={{ fill: '#80a6e5', fontSize: '2rem' }} />
						</div>
						<div>
							{MaskXXWeekYDay(today_pregnancy_age.age, selectedLanguage)}{' '}
							{today_pregnancy_age.type
								? `| ${UltrasoundType_Options[today_pregnancy_age.type]}`
								: ''}
						</div>
					</div>
				) : null}
				{abo_group_rh_factor_exam?.abo_group &&
				abo_group_rh_factor_exam?.rh_factor ? (
					<div className='InitSubSection-PregnancyAge'>
						<div className='InitSubSection-PregnancyAge-Icon'>
							<BloodDropIco style={{ fill: '#80a6e5', fontSize: '1rem' }} />
						</div>
						<div>
							{translateABOAndRHFactor[abo_group_rh_factor_exam?.abo_group]}
							{translateABOAndRHFactor[abo_group_rh_factor_exam?.rh_factor]}
						</div>
					</div>
				) : null}
				<RiskBadge />
			</div>
			<p className='PreNatalAttendance-Subtitle'>
				<strong>{Alterations}</strong>
			</p>
			<div className='InitSubSection-Separator' />
			{getAlterations(holdingProject, selectedLanguage).length ? (
				<div className='InitSubSection-Alerts'>
					{getAlterations(holdingProject, selectedLanguage).map(
						(alteration, idx) => (
							<div key={idx}>
								<AlertRowText {...alteration} outlined />
							</div>
						)
					)}
				</div>
			) : (
				<AlertRowText
					type={AlertRowTextType.INFO}
					content={NoChangesIdentified}
					outlined
				/>
			)}
		</div>
	)
}
