import api from "../../../../helpers/api"
import { getParams } from "../../../../utils"

export async function getInventoryMaterials(params={}) {
    params = getParams({ ...params })
    const url = `/inventory/material/${params}`
    let response = api.get(url)

    return response
}

export async function postInventoryMaterial(payload={}) {
    const url = '/inventory/material/'
    let response = api.post(url, payload)
    
    return response
}

export async function patchInventoryMaterial(id, payload={}) {
    const url = `/inventory/material/${id}/`
    let response = api.patch(url, payload)

    return response
}


export async function getInventoryMaterialsAssignment(params={}) {
    params = getParams({ ...params })
    const url = `/inventory/material_assignment/${params}`
    let response = api.get(url)

    return response
}

export async function postInventoryMaterialAssignment(payload={}) {
    const url = '/inventory/material_assignment/'
    let response = api.post(url, payload)

    return response
}

export async function patchInventoryMaterialAssignment(id, payload={}) {
    const url = `/inventory/material_assignment/${id}/`
    let response = api.patch(url, payload)

    return response
}
