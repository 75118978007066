import './RemoveDocumentModal.css'
import React from "react"
import { useDispatch } from "react-redux"
import { loaded, loading } from "../../../../../../../../../../../../layout/redux/AppActions"
import { patchActivity } from "../../../../../../../../../../http"
import { AddAlert, AlertType } from "../../../../../../../../../../../../component"
import NewButton, { ButtonModel } from "../../../../../../../../../../../../newcomponents/button/button"
import { MODAL_INIT_PROPS } from '../../../../../../../../../../../../newcomponents/modal/modal'

export default function RemoveDocumentModal({ activity=null, setModalInfo=() => null }) {
    const dispatch = useDispatch()

    const handleSubmit = async () => {
        dispatch(loading())
        try {
            await patchActivity(activity?.id, { active: false })
            setModalInfo(MODAL_INIT_PROPS)
            document.dispatchEvent(new CustomEvent("DocumentsHistoryFetchActivities"))
            dispatch(loaded())
        } catch (err) {
            dispatch([loaded(), AddAlert('Salvar documento', 'Falha ao salvar documento.', AlertType.ERROR)])
            console.error('removeDocument', err)
        }
    }

    return <div>
        <p>Tem certeza que deseja <b>remover</b> esse documento?</p>
        <div className="RemoveDocumentModal-BtnBox">
            <NewButton
                label='Remover'
                onClick={handleSubmit}
                model={ButtonModel.DANGER}
            />
        </div>
    </div>
}
