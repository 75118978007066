import './PartnerPrenatal.css'
import React, { useEffect } from 'react'
import { Input, RadioButton } from '../../../../../../../../../../component'
import NewSelect from '../../../../../../../../../../newcomponents/form/select/select'
import AlertRowText from '../../../../../../../../../../component/alertRowText/alertRowText'
import { useThousandDays } from '../../../../ThousandDaysSection'
import {
	getHIVResult,
	getHbsAgResult,
	getVDRLResult,
} from '../Exam/forms/SerologicalTestHIVHbsAgAntiHCVVDRL/utils'
import { getYearsStart1900 } from '../../../../../../../../../../utils/getYearsStart1900'

const selectOptions_data = require('../../../../../../../../../../component/form/select/options.json')

export default function PartnerPrenatal() {
	const {
		selectedLanguage,
		partnerPrenatal,
		setPartnerPrenatal,
		savePartnerPrenatal,
	} = useThousandDays()

	const {
		Partnersprenatal,
		Name,
		Birthday,
		Day,
		Month,
		Year,
		Educationallevel,
		Chooseoneoftheoptions,
		Syphilistest,
		SelectOptions_Data_Months_Options,
		Notevaluated,
		Indeterminate,
		Nonreactive,
		Reactive,
		Unknowpartner,
		Instructions_Options,
		Change,
		Greaterthan,
	} = require('../../../../../../../../../data/translation.json')[
		selectedLanguage
	]

	useEffect(() => {
		savePartnerPrenatal()
	}, [
		partnerPrenatal.birthdate_year,
		partnerPrenatal.birthdate_month,
		partnerPrenatal.birthdate_day,
		partnerPrenatal.instruction,
		partnerPrenatal.vdrl,
		partnerPrenatal.syphilis_test,
		partnerPrenatal.hiv,
		partnerPrenatal.hbsag,
	])

	const selectOptions_data_months_options = selectOptions_data.months.map(
		option => ({
			id: option.id,
			name: SelectOptions_Data_Months_Options[option.id],
		})
	)

	const instructions_options = selectOptions_data.instructions.map(option => ({
		id: option.id,
		name: Instructions_Options[option.id],
	}))

	const handleChangeButton = event => {
		setPartnerPrenatal(prev => ({
			...prev,
			[event.target.name]: event.target.value.id,
			vdrl: 'NOT_EVALUATED',
			syphilis_test: 'NOT_EVALUATED',
		}))
	}

	const handleUnknownPartner = ({ target: { checked } }) => {
		let today_date = new Date()

		setPartnerPrenatal(prev => ({
			...prev,
			unknownpartner: checked,
			name: checked ? { Unknowpartner } : '',
			birthdate_day: checked
				? ''
				: `${today_date.getDate() < 10 ? '0' : ''}${today_date.getDate()}`,
			birthdate_month: checked
				? ''
				: `${today_date.getMonth() + 1 < 10 ? '0' : ''}${
						today_date.getMonth() + 1
				  }`,
			birthdate_year: checked ? '' : `${today_date.getFullYear()}`,
			instruction: '',
		}))
	}

	const handleChange = event => {
		setPartnerPrenatal(prev => ({
			...prev,
			[event.target.name]: event.target.value,
		}))
	}

	const handleSelect = event => {
		setPartnerPrenatal(prev => ({
			...prev,
			[event.target.id]: event.target.selected,
		}))
	}

	return (
		<div className='ThousandDays_Consultation_PartnerPrenatal'>
			<p className='PartnerPrenatal-Subtitle'>{Partnersprenatal}</p>
			<div className='PreNatalAttendance-Separator' />
			<div className='ThousandDays_Consultation_PartnerPrenatal_Action'>
				<div>
					<b>{Name}</b>
					<div className='ThousandDaysPrenatalPartner_Name'>
						<Input
							name='name'
							value={partnerPrenatal.name}
							action={handleChange}
							actionBlur={savePartnerPrenatal}
							disabled={partnerPrenatal.unknownpartner}
							autoComplete='off'
						/>
					</div>
					<div className='ThousandDaysSection-PrenatalPartner-SelectRow'>
						<div>
							<b>{Birthday}</b>
							<div className='ThousandDaysPrenatalPartner_Birthdate'>
								<NewSelect
									id='birthdate_day'
									options={selectOptions_data.days}
									onSelect={handleSelect}
									selected={partnerPrenatal.birthdate_day}
									disabled={partnerPrenatal.unknownpartner}
									defaultText={Day}
									canBeEmpty={false}
								/>
								<NewSelect
									id='birthdate_month'
									options={selectOptions_data_months_options}
									onSelect={handleSelect}
									selected={partnerPrenatal.birthdate_month}
									disabled={partnerPrenatal.unknownpartner}
									defaultText={Month}
									canBeEmpty={false}
								/>
								<NewSelect
									id='birthdate_year'
									options={getYearsStart1900()}
									onSelect={handleSelect}
									selected={partnerPrenatal.birthdate_year}
									disabled={partnerPrenatal.unknownpartner}
									defaultText={Year}
									canBeEmpty={false}
								/>
							</div>
						</div>
						<div>
							<b>{Educationallevel}</b>
							<div className='ThousandDaysPrenatalPartner_Instruction'>
								<NewSelect
									id='instruction'
									options={instructions_options}
									onSelect={handleSelect}
									selected={partnerPrenatal.instruction}
									disabled={partnerPrenatal.unknownpartner}
									canBeEmpty={false}
								/>
							</div>
						</div>
					</div>
					<div className='ThousandDaysPrenatalPartner-PartnerPrenatal-ExamsSubsection-RadioBtn'>
						<b>{Chooseoneoftheoptions}</b>
						<RadioButton
							name='vdrl_or_syphilis_test'
							action={handleChangeButton}
							checked={partnerPrenatal.vdrl_or_syphilis_test}
							options={[
								{ id: 'vdrl', name: 'VDRL' },
								{ id: 'syphilis_test', name: Syphilistest },
							]}
						/>
						{partnerPrenatal.vdrl_or_syphilis_test === 'vdrl' ? (
							<div>
								<b>VDRL</b>
								<div className='ThousandDaysPrenatalPartner_SelectDefaultCSS'>
									<NewSelect
										id='vdrl'
										options={[
											{ id: 'NOT_EVALUATED', name: Notevaluated },
											{ id: 'INDETERMINATE', name: Indeterminate },
											{ id: 'NON_REAGENT', name: Nonreactive },
											{ id: 'REAGENT', name: Reactive },
											{ id: 'ONE_BY_ONE', name: '1 / 1' },
											{ id: 'ONE_BY_TWO', name: '1 / 2' },
											{ id: 'ONE_BY_FOUR', name: '1 / 4' },
											{ id: 'ONE_BY_EIGHT', name: '1 / 8' },
											{ id: 'ONE_BY_SIXTEEN', name: '1 / 16' },
											{ id: 'ONE_BY_THIRTY_TWO', name: '1 / 32' },
											{ id: 'ONE_BY_SIXTY_FOUR', name: '1 / 64' },
											{
												id: 'GREATER_ONE_BY_SIXTY_FOUR',
												name: `${Greaterthan} 1 / 64`,
											},
										]}
										onSelect={handleSelect}
										selected={partnerPrenatal.vdrl}
										canBeEmpty={false}
									/>
									<AlertRowText
										{...getVDRLResult(partnerPrenatal.vdrl, Change)}
									/>
								</div>
							</div>
						) : null}
						{partnerPrenatal.vdrl_or_syphilis_test === 'syphilis_test' ? (
							<div>
								<b>{Syphilistest}</b>
								<div className='ThousandDaysPrenatalPartner_SelectDefaultCSS'>
									<NewSelect
										id='syphilis_test'
										options={[
											{ id: 'NOT_EVALUATED', name: Notevaluated },
											{ id: 'INDETERMINATE', name: Indeterminate },
											{ id: 'NON_REAGENT', name: Nonreactive },
											{ id: 'REAGENT', name: Reactive },
										]}
										onSelect={handleSelect}
										selected={partnerPrenatal.syphilis_test}
										canBeEmpty={false}
									/>
									<AlertRowText
										{...getHbsAgResult(partnerPrenatal.syphilis_test, Change)}
									/>
								</div>
							</div>
						) : null}
					</div>
					<div className='ThousandDaysSection-PartnerPrenatal-ExamsSubsection-Selectors'>
						<div>
							<b>HIV</b>
							<div className='ThousandDaysPrenatalPartner_SelectDefaultCSS'>
								<NewSelect
									id='hiv'
									options={[
										{ id: 'NOT_EVALUATED', name: Notevaluated },
										{ id: 'INDETERMINATE', name: Indeterminate },
										{ id: 'NON_REAGENT', name: Nonreactive },
										{ id: 'REAGENT', name: Reactive },
									]}
									onSelect={handleSelect}
									selected={partnerPrenatal.hiv}
									canBeEmpty={false}
								/>
								<AlertRowText {...getHIVResult(partnerPrenatal.hiv, Change)} />
							</div>
						</div>
						<div>
							<b>HbsAg</b>
							<div className='ThousandDaysPrenatalPartner_SelectDefaultCSS'>
								<NewSelect
									id='hbsag'
									options={[
										{ id: 'NOT_EVALUATED', name: Notevaluated },
										{ id: 'INDETERMINATE', name: Indeterminate },
										{ id: 'NON_REAGENT', name: Nonreactive },
										{ id: 'REAGENT', name: Reactive },
									]}
									onSelect={handleSelect}
									selected={partnerPrenatal.hbsag}
									canBeEmpty={false}
								/>
								<AlertRowText
									{...getHbsAgResult(partnerPrenatal.hbsag, Change)}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
