import './SOAPAssessment.css'
import React, { useEffect, useRef } from 'react'
import { Input } from '../../../../../../component'
import ConsultationTextField from '../../../../consultation-text-field'
import { useAttendance } from '../../../../consultation'
import NewMultiSelect from '../../../../../../newcomponents/form/select/multiselect'
import AlertRowText, { AlertRowTextType } from '../../../../../../component/alertRowText/alertRowText'
import { uniqueCIDs } from './helpers'
import MedicalTeamIco from '../../../../../../component/icon/medicalTeam'
import PreceptorshipEvaluation from '../subcomponents/PreceptorshipEvaluation/PreceptorshipEvaluation'

const {
    PreceptorshipSections
} = require('../../SOAPSection.json')

export default function SOAPAssessment() {
    const searchTimeout = useRef(null)
    const { 
        attendance, 
        setAttendance, 
        savePartialAppointment,
        savePartialAppointmentForeignKey,
        isEditable, 
        fetchCIDTenCodes,
        displayPreceptorship,
        attendanceExtraData: { cids } 
    } = useAttendance()

    useEffect(() => {
        savePartialAppointmentForeignKey('related_diseases_and_problems')
    }, [attendance.related_diseases_and_problems])

    const searchRelatedDiseasesProblems = (search) => {
        if (searchTimeout.current) clearTimeout(searchTimeout.current)

        searchTimeout.current = setTimeout((search) => {
            fetchCIDTenCodes({ search });
        }, 400, search)
    };

    const toggleOption = ({ optionRef }) => {
        let selected = cids.find(cid => cid.code === optionRef)
        let isExisting = attendance.related_diseases_and_problems.find(cid => cid.code === optionRef)

        if (isExisting) {
            setAttendance(prev => ({ ...prev, 'related_diseases_and_problems': prev.related_diseases_and_problems.filter(cid => cid.code !== optionRef) }));
        } else {
            setAttendance(prev => ({ ...prev, 'related_diseases_and_problems': [...prev.related_diseases_and_problems, selected] }));
        }
        fetchCIDTenCodes();
    };
    
    const handleChange = (id, value) => {
        setAttendance(prev => ({ ...prev, [id]: value }))

        if (searchTimeout.current) {
            clearTimeout(searchTimeout.current);
        }

        searchTimeout.current = setTimeout(() => {
            savePartialAppointment(id, value);
        }, 500)
    };

    const handleBlur = (id) => {
        savePartialAppointment(id, attendance[id]);
    }

    return <React.Fragment>
            <p><b>Doenças e Problemas Relacionados (CID-10)</b></p>
            <div className='SOAP-Assessment-NewMultiSelect'>
                <NewMultiSelect
                    filterNode={<div className='SOAP-Assessment-NewMultiSelect-FilterNode'>
                        <Input 
                            placeholder='Pesquisar por CID ou Título'
                            action={({ target: { value } }) => searchRelatedDiseasesProblems(value)}
                            actionFocus={()=> fetchCIDTenCodes()}
                        />
                    </div>}
                    defaultText='Selecione'
                    onlyDefaultText
                    onSelect={toggleOption}
                    selectedOptions={attendance.related_diseases_and_problems?.map(cid => cid.code) || []}
                    optionRefKey='code'
                    optionStrKey='title'
                    options={attendance.related_diseases_and_problems
                        ? uniqueCIDs([...attendance.related_diseases_and_problems, ...cids]).map(cid => ({ ...cid, title: `${cid.code} | ${cid.title}` }))
                        : []
                    }
                    disabled={!isEditable}
                />
            </div>
            <div className='SOAP-Subjective-NewMultiSelect-Selecteds'>
                { attendance?.related_diseases_and_problems?.map(({ code, title }, idx) => <div key={idx} className='SOAP-Subjective-NewMultiSelect-Selecteds-Option'><AlertRowText content={`${code} | ${title}`} type={AlertRowTextType.INFO} outlined /></div>) }
            </div>
            <div className='SOAP-Section-Separator' />
            <ConsultationTextField
                id='diagnosis'
                title='Hipóteses diagnósticas'
                value={attendance.diagnosis}
                handleChange={(value) => handleChange('diagnosis', value)}
                readOnly={!isEditable}
                onBlur={() => handleBlur('diagnosis')}

            />
            <div className='SOAP-Section-Separator' />
            {
                displayPreceptorship
                ? <div className='SOAP-Subjective-Preceptorship'>
                    <div className='SOAP-Subjective-Preceptorship-Label'>
                        <MedicalTeamIco style={{ width: '21px', fill: '#CED4DA', marginRight: '8px' }} /> Preceptoria
                    </div>
                    <PreceptorshipEvaluation 
                        options={PreceptorshipSections.Assessment.options}
                        feedbackKey={PreceptorshipSections.Assessment.feedbackKey}
                    />
                </div>
                : null
            }
    </React.Fragment>
}