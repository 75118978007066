import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import ConsultationData from './consultation-data';
import Modal, { MODAL_INIT_PROPS } from '../../newcomponents/modal/modal';
import './consultation.css';
import ConsultationHeader from './subcomponents/ConsultationHeader/consultation-header';
import { strpAnthropometry, strpVitalSign } from './subcomponents/SOAP/subcomponents/Objective/VitalSignsAnthropometry/utils';
import {
    getAttendance,
    getPreceptorshipAssessment,
    getCIAPTwenty,
    getCIDTen,
    putVitalSign,
    putAnthropometry,
    putPreceptorshipAssessment,
    patchAppointment,
    patchAppointmentCIAPOrCID
} from './http';
import { checkIfAttendanceIsEditable, strpPreceptorship } from './helpers';
import { loaded, loading } from '../../layout/redux/AppActions';
import { AddAlert, AlertType } from '../../component';
import { useApp } from '../../layout/App';
import { EVENT_PATIENT_REGISTERED_CHANGE } from '../datailPatient/subcomponents/events';

const ConsultationContext = React.createContext();

export function useAttendance() {
    return useContext(ConsultationContext);
}

const initVitalSign = strpVitalSign()

const initAttendanceExtraData = {
    'anthropometry': strpAnthropometry(),
    'ciaps': [],
    'cids': [],
}

export default function ConsultationPage({ isPreceptorshipEditor=false }) {
    const [attendance, setAttendance] = useState({});
    const [attendanceExtraData, setAttendanceExtraData] = useState(initAttendanceExtraData)
    const [vitalSigns, setVitalSigns] = useState(initVitalSign)
    const [modalInfo, setModalInfo] = useState(MODAL_INIT_PROPS);
    const [lastUpdate, setLastUpdate] = useState(new Date())
    const [patientOnline, setPatientOnline] = useState(false);
    const [forbiddenModal, setForbiddenModal] = useState(false);
    const [displayPreceptorship, setDisplayPreceptorship] = useState(false || isPreceptorshipEditor)
    const [holdingPreceptorshipInstance, setHoldingPreceptorshipInstance] = useState(null)
    const history = useHistory();
    const dispatch = useDispatch();
    const [mainOpenConsultationTab, setMainOpenConsultationTab] = useState('soap')
    const { id, room_id } = useParams()
    const { currentHealthPlaceUser } = useApp()

    useEffect(() => {
        fetchAttendance();
        fetchPreceptorshipAssessment()
        fetchCIAPTwentyCodes()
        fetchCIDTenCodes()
        return () => {
            setAttendance({});
        };
    }, []);


    useEffect(() => {
        const handleAttendanceFetched = () => {
            console.log('Attendance was fetched and event was dispatched.');
            fetchAttendance()
        };

        window.addEventListener(EVENT_PATIENT_REGISTERED_CHANGE, handleAttendanceFetched);

        return () => {
            window.removeEventListener(EVENT_PATIENT_REGISTERED_CHANGE, handleAttendanceFetched);
        };
    }, []);

    useEffect(() => {
        if (attendance?.person?.id) {
            setLastUpdate(new Date(attendance.last_updated))
        }
    }, [attendance?.person?.id])

    const fetchAttendance = async () => {
        dispatch(loading())
        try {
            const attendance = await getAttendance(id)
            setAttendance(attendance)
            setVitalSigns(strpVitalSign(attendance.vital_sign))
            setAttendanceExtraData(prev => ({
                ...prev,
                anthropometry: strpAnthropometry(attendance.anthropometry),
            }))
            delete attendance.pre_natal_attendance
            delete attendance.anthropometry
            delete attendance.vital_sign
        } catch (err) {
            setAttendance({})
            setVitalSigns(initVitalSign)
            console.error('Failed to fetch attendance: ', err)
        }
        dispatch(loaded())
    }


    const fetchPreceptorshipAssessment = async () => {
        try {
            const preceptorship = await getPreceptorshipAssessment(id)
            setHoldingPreceptorshipInstance(strpPreceptorship(preceptorship))
            setDisplayPreceptorship(true)
        } catch (err) {
            console.error(err)
            if (!isPreceptorshipEditor) setDisplayPreceptorship(false)
            setHoldingPreceptorshipInstance(strpPreceptorship())
        }
    }

    const fetchCIAPTwentyCodes = async (params) => {
        try {
            const ciaps = await getCIAPTwenty(params)
            setAttendanceExtraData(prev => ({ ...prev, ciaps }))
        } catch (err) {
            setAttendanceExtraData(prev => ({ ...prev, 'ciaps': [] }))
        }
    }
    
    const fetchCIDTenCodes = async (params) => {
        try {
            const cids = await getCIDTen(params)
            setAttendanceExtraData(prev => ({ ...prev, cids }))
        } catch (err) {
            setAttendanceExtraData(prev => ({ ...prev, 'cids': [] }))
        }
    }

    const savePartialAppointment = async (field, value) => {
        try {
            await patchAppointment(attendance?.id, { [field]: value })
                .then(() => setLastUpdate(new Date()))
        } catch (err) {
            console.error('savePartialAppointment ~ ', err)
        }
    }

    const savePartialAppointmentForeignKey = async (field) => {
        try {
            await patchAppointmentCIAPOrCID(attendance?.id, { [field]: attendance?.[field]?.map(ciap => ciap.code) })
                .then(() => setLastUpdate(new Date()))
        } catch (err) {
            console.error('savePartialAppointmentForeignKey ~ ', err)
        }
    }

    const saveVitalSign = async () => {
        try {
            if (attendance.id) {
                await putVitalSign(vitalSigns, attendance.id)
                    .then(() => setLastUpdate(new Date()))
            }
        } catch (err) {
            console.error('saveVitalSign', err)
        }
    }

    const saveAnthropometry = async () => {
        try {
            if (attendance.id) {
                await putAnthropometry(attendanceExtraData.anthropometry, attendance.id)
                    .then(() => setLastUpdate(new Date()))
            }
        } catch (err) {
            console.error('saveAnthropometry', err)
        }
    }

    const saveHoldingPreceptorship = () => {
        let payload = { 
            ...holdingPreceptorshipInstance, 
            "attendance_id": Number(id), 
            "preceptor_user": currentHealthPlaceUser?.id,
            "student_health_place_user": attendance?.doctor?.user?.health_place_users?.[0]?.id,
            "room_id": room_id || null
        }
        putPreceptorshipAssessment(id, payload)
            .then(() => {
                dispatch(AddAlert('Preceptoria', 'Preceptoria salva com sucesso!', AlertType.SUCCESS))
            })
            .catch(err => {
                console.error('consultation ~ saveHoldingPreceptorship ', err)
                dispatch(AddAlert('Preceptoria', 'Falha ao salvar preceptoria', AlertType.ERROR))
            })
    }

    const handleStartConsutation = () => {
        setPatientOnline(false);
    };

    const handleCloseConsutation = () => {
        setForbiddenModal(false);
        history.push('/');
    };

    if (!Object.keys(attendance).length) return null
    const isEditable = checkIfAttendanceIsEditable(attendance) && !isPreceptorshipEditor
    
    const canWritePreceptorship = isPreceptorshipEditor && attendance?.doctor?.user?.id != currentHealthPlaceUser?.user?.id

    return (
        <ConsultationContext.Provider
            value={{
                attendance,
                setAttendance,
                savePartialAppointment,
                savePartialAppointmentForeignKey,
                saveVitalSign,
                saveAnthropometry,
                attendanceExtraData,
                setAttendanceExtraData,
                displayPreceptorship,
                canWritePreceptorship,
                holdingPreceptorshipInstance,
                setHoldingPreceptorshipInstance,
                saveHoldingPreceptorship,
                lastUpdate,
                setLastUpdate,
                modalInfo,
                setModalInfo,
                vitalSigns,
                setVitalSigns,
                mainOpenConsultationTab, 
                setMainOpenConsultationTab,
                isEditable,
                fetchAttendance,
                fetchCIAPTwentyCodes,
                fetchCIDTenCodes,
            }}
        >
            <Modal {...modalInfo} dismissFn={() => setModalInfo(MODAL_INIT_PROPS)} />
            <Modal
                title={'Acesso Negado'}
                content={<span>Usuário sem permissão ao prontuário, consulte o administrador.</span>}
                confirmText='Fechar'
                dismissFn={handleCloseConsutation}
                open={forbiddenModal}
            />
                    <div>
                        <div className='consultation-page'>
                            {/* isEditable ? <Chat /> : null */}
                            <div className='consultation-center'>
                                <Modal
                                    title='O Paciente está aguardando'
                                    content={<span>O paciente <strong>{attendance.person.name}</strong> entrou na consulta.</span>}
                                    confirmText='Entendi'
                                    dismissFn={handleStartConsutation}
                                    open={patientOnline}
                                />

                                <ConsultationHeader />
                                <ConsultationData />
                            </div>
                        </div>
                    </div>
        </ConsultationContext.Provider>
    );
}