import { AlertRowTextType } from '../../../../../../../../../../../../component/alertRowText/alertRowText'
import {
	parseNumberWithComma,
	parseNumberWithThousandSeparator,
} from '../../../../../../../../../../../../utils/parsers'

const hemogram_translate = require('../../../../../../../../../../../data/translation.json')

export function getHemoglobineResult(hb) {
	// FIXME CHECK PREGNANCY INTERVAL
	hb = parseNumberWithComma(hb)

	if (hb < 11) return { type: AlertRowTextType.WARNING, content: 'Anemia' }

	return { type: AlertRowTextType.SUCCESS, content: 'Normal' }
}

export function getLeukocytesResult(leukocytes, change) {
	leukocytes = parseNumberWithThousandSeparator(leukocytes)

	if (leukocytes < 4000 || leukocytes > 11000)
		return { type: AlertRowTextType.WARNING, content: change }

	return { type: AlertRowTextType.SUCCESS, content: 'Normal' }
}

export function getPlateletsResult(
	platelets,
	highattention,
	mediumattention,
	lowattention
) {
	platelets = parseNumberWithThousandSeparator(String(platelets))

	if (platelets < 50000)
		return { type: AlertRowTextType.WARNING, content: highattention }
	if (platelets < 100000)
		return { type: AlertRowTextType.WARNING, content: mediumattention }
	if (platelets <= 140000)
		return { type: AlertRowTextType.WARNING, content: lowattention }

	return { type: AlertRowTextType.SUCCESS, content: 'Normal' }
}

export function getCompleteHemogramResults(
	hemogram_exams = [],
	selectedLanguage
) {
	let alterations = []
	const { Hemoglobin, Leukocytes, Platelets } =
		hemogram_translate[selectedLanguage]

	for (let i = 0; i < hemogram_exams.length; i++) {
		let hemoglobine = getHemoglobineResult(hemogram_exams[i].hemoglobin)
		if (hemoglobine?.type === AlertRowTextType.WARNING) {
			alterations.push({ type: AlertRowTextType.INFO, content: Hemoglobin })
			break
		}
	}

	for (let i = 0; i < hemogram_exams.length; i++) {
		let leukocytes = getLeukocytesResult(hemogram_exams[i].leukocytes)
		if (leukocytes?.type === AlertRowTextType.WARNING) {
			alterations.push({ type: AlertRowTextType.INFO, content: Leukocytes })
			break
		}
	}

	for (let i = 0; i < hemogram_exams.length; i++) {
		let platelets = getPlateletsResult(hemogram_exams[i].platelets)
		if (platelets?.type === AlertRowTextType.WARNING) {
			alterations.push({ type: AlertRowTextType.INFO, content: Platelets })
			break
		}
	}

	return alterations
}
