import './css/consultation-data.css'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { AddAlert, AlertType, IcoEstetoscopio } from '../../component'
import api from '../../helpers/api'
import Button, { ButtonModel } from '../../newcomponents/button/button'
import Modal, { MODAL_INIT_PROPS } from '../../newcomponents/modal/modal'
import SOAPSection from './subcomponents/SOAP/SOAP'
import HealthProfileSection from './subcomponents/HealthProfileSection/HealthProfileSection'
import PatientDataSection from './subcomponents/PatientDataSection/PatientDataSection'
import NotesSection from './subcomponents/NotesSection/NotesSection'
import AttendanceHistorySection from './subcomponents/AttendanceHistorySection/AttendanceHistorySection'
import { useAttendance } from './consultation'
import { AttendanceHistoryIcon } from '../../component/icon/attendanceHistory'
import { NotesIcon } from '../../component/icon/notes'
import { PersonIcon } from '../../component/icon/person'
import CheckOutlinedIcon from '../../component/icon/check-outlined'
import { loaded, loading } from '../../layout/redux/AppActions'
import HoldingPatientInfoView from '../datailPatient/HoldingPatientInfoView'

const ActionButtons = ({ setConfirmEnd }) => {
    const dispatch = useDispatch()
    const { attendance, isEditable } = useAttendance()
    const { saveHoldingPreceptorship,canWritePreceptorship } = useAttendance()
    const [modalProps, setModalProps] = useState(MODAL_INIT_PROPS)
    let renderSaveButton = isEditable

    const handlePrintAppointment = async () => {
        dispatch(loading())
        try {
          await api.get(`appointment/print/?attendance_id=${attendance?.id}`)
            .then(res => {
                const link = document.createElement("a");
                link.href = res.data.base64
                link.download = 'prontuário-de-atendimento.pdf'
                link.click()
            })
            .catch(err => {
              console.error('MedicalRecordHistory ~ handleSubmit ~ ', err)
            })
        } catch (err) {
          console.error('MedicalRecordHistory ~ handleSubmit ~ ', err)
        }
        dispatch(loaded())
    }

    return <div className='consultation-data-button-actions btn-box'>
        <Modal {...modalProps} dismissFn={() => setModalProps(MODAL_INIT_PROPS)} />
        <Button 
            label='Imprimir atendimento'
            onClick={handlePrintAppointment}
            model={ButtonModel.SECONDARY}
        />
        {renderSaveButton && (
            <Button id='consultation-data-button-actions-save'
                label='Finalizar Consulta'
                onClick={() => setConfirmEnd(true)}
                disabled={!isEditable}
            />
        )}
        {
            canWritePreceptorship
            ? <Button 
                label='Salvar avaliação'
                onClick={saveHoldingPreceptorship}
            />
            : null
        }
    </div>
}

function getLastUpdateTime(last_updated) {
    let hours =  last_updated.getHours().toString().padStart(2, '0')
    let minutes = last_updated.getMinutes().toString().padStart(2, '0')
    return hours + ':' + minutes
}

export default function ConsultationData() {
    const dispatch = useDispatch()
    const history = useHistory()
    const { attendance, lastUpdate, mainOpenConsultationTab, setMainOpenConsultationTab } = useAttendance()
    const [confirmEnd, setConfirmEnd] = useState(false)
    

    const endConsultation = async (confirm) => {

        setConfirmEnd(false)

        if(!confirm) return

        api.put(`/attendance/action/${attendance.id}/close/`, {})
            .then(() => {
                dispatch(AddAlert('Atendimento', 'Atendimento finalizado com sucesso', AlertType.SUCCESS))
                history.push('/home')
            })
            .catch(error => {
                dispatch(AddAlert('Atendimento', 'Falha ao finalizar atendimento, tente novamente!', AlertType.ERROR))
                console.error(error)
            })
    }

    const sections = {
        'soap': <SOAPSection />,
        'notes': <NotesSection />,
        'attendancesHistory': <AttendanceHistorySection />,
        'healthprofile': <HealthProfileSection />,
        'patientdata': <HoldingPatientInfoView initHoldingPatientID={attendance?.person?.id} />,
    }

    return <>
    <div className='consultation-data'>
        <Modal
            open={confirmEnd}
            title={'Finalizar consulta'}
            content={
                <div>
                    Ao proseguir, todos os dados serão salvos e a consulta será finalizada.
                    <br></br>
                    <br></br>
                    <br></br>
                    <div style={{textAlign: 'center', fontWeight: 'bold'}}>Deseja finalizar a consulta?</div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', columnGap: '0.5rem', marginTop: '2rem'}}>
                        <Button 
                            label='Não, quero continuar'
                            model={ButtonModel.SECONDARY}
                            onClick={() => setConfirmEnd(false)}
                        />
                        <Button 
                            label='Sim, quero finalizar'
                            onClick={() => endConsultation(true)}
                        />
                    </div>
                </div>
            }
        />
        <div className='consultation-data-sideleft'>
            <div className={`consultation-data-sideleft-btn ${mainOpenConsultationTab === 'soap' ? 'active' : ''}`} onClick={() => setMainOpenConsultationTab('soap')}>
                <IcoEstetoscopio style={{ fontSize: '21px', marginRight: '12px'}} /> Soap
            </div>
            <div className={`consultation-data-sideleft-btn ${mainOpenConsultationTab === 'notes' ? 'active' : ''}`} onClick={() => setMainOpenConsultationTab('notes')}>
                <NotesIcon style={{ fontSize: '21px', marginRight: '12px'}} />  Anotações
            </div>
            {
                /*
            <div className={`consultation-data-sideleft-btn ${mainOpenConsultationTab === 'attendancesHistory' ? 'active' : ''}`} onClick={() => setMainOpenConsultationTab('attendancesHistory')}>
                <AttendanceHistoryIcon style={{ fontSize: '21px', marginRight: '12px'}} /> Histórico
            </div>
                */
            }

            <div className={`consultation-data-sideleft-btn ${mainOpenConsultationTab === 'patientdata' ? 'active' : ''}`} onClick={() => setMainOpenConsultationTab('patientdata')}>
                <PersonIcon style={{ fontSize: '24px', marginRight: '12px'}} /> Paciente
            </div>
        </div>
        <div className='consultation-data-separator' />
        <div className='consultation-data-mainOpenConsultationTab'>
            { sections[mainOpenConsultationTab] }
        </div>
    </div>
    <div className='consultation-data-footer'>
        <div className='consultation-data-footer-lastsave'><CheckOutlinedIcon style={{ width: '12px', fill: '' }}/>Atendimento salvo pela última vez em {getLastUpdateTime(lastUpdate)}</div> {/* FIXME  */}
        <ActionButtons setConfirmEnd={setConfirmEnd} />
    </div>
    </>
}