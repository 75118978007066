import React from 'react'
import { Button, IcoBin, IcoPencil, List } from '../../../../../../../../../../../../../component'
import { translate } from '../utils'
import { useThousandDays } from '../../../../../../../ThousandDaysSection'
import { convertDate } from '../../../../../../../../../../../../../utils/convertDate'
import ABOGroupRHFactorWrite from '../Write/ABOGroupRHFactorWrite'
import api from '../../../../../../../../../../../../../helpers/api'

export default function ABOGroupRHFactorView() {
	const { 
		selectedLanguage, 
		holdingProject: { abo_group_rh_factor_exams }, 
		setModalInfo, 
		fetchThousandDaysProjects 
	} = useThousandDays()

	const {
		Abobloodgroupandrhfactor,
		Notinformation,
		Notevaluated,
		Rhfactor,
		Abogroup,
		Dates
	} = require('../../../../../../../../../../../../data/translation.json')[selectedLanguage]

	const headDefault = [
		{ colunm: 'h_abo_group', text: Abogroup },
		{ colunm: 'h_rh_factor', text: Rhfactor },
		{ colunm: 'h_date', text: Dates, width: '100px' },
		{ colunm: 'h_action', text: '', width: '100px' },
	]

	const handleDelete = async (id) => {
		await api.delete(`exam/abo_group_rh_factor/${id}/`)
		fetchThousandDaysProjects()
	}

	const openExamWriteModal = (holdingExam) => {
		setModalInfo(prev => ({
			...prev,
			open: true,
			title: Abobloodgroupandrhfactor,
			content: (
				<div className='SOAP-ThousandDaysExamSubSection-Modal'>
					<ABOGroupRHFactorWrite 
						holdingABOGroupRHFactor={holdingExam} 
						setModalInfo={setModalInfo} 
					/>
				</div>
			),
		}))
	}

	return (
		<div className='StreptococcusGBSView'>
			<div>
				<List
					head={headDefault}
					data={abo_group_rh_factor_exams}
					noData={Notinformation}
					listCustom={(exam, i) => {
						exam['h_abo_group'] = exam?.abo_group ? translate[exam?.abo_group] : Notevaluated
						exam['h_rh_factor'] = exam?.rh_factor ? translate[exam?.rh_factor] : Notevaluated
						exam['h_date'] = exam?.date ? convertDate(exam.date, '-', '/') : '-'
						
						exam['h_action'] = (
							<div>
								<Button
									color='secondary'
									type='btn circle'
									action={() => openExamWriteModal(exam)}
									title='Editar exame'>
									<IcoPencil />
								</Button>
								<Button
									color='danger'
									type='btn circle'
									action={() => handleDelete(exam?.id)}
									title='Excluir exame'>
									<IcoBin />
								</Button>
							</div>
						)
						return exam
					}}
				/>
			</div>
		</div>
	)
}
