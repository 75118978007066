import './NewEditRoutine.css'
import React, { useEffect, useRef, useState } from "react";
import { AddAlert, AlertType, Input, RadioButton } from "../../../../../../../component";
import { useDispatch, useSelector } from "react-redux";
import NewButton, { ButtonModel } from '../../../../../../../newcomponents/button/button';
import { isValidEmail } from '../../../../../../../validators';
import { loaded, loading } from '../../../../../../../layout/redux/AppActions';
import { MODAL_INIT_PROPS } from '../../../../../../../newcomponents/modal/modal';
import { patchReportsRoutine, postReportsRoutine } from '../../../../../http';
import { useApp } from '../../../../../../../layout/App';
import NewMultiSelect from '../../../../../../../newcomponents/form/select/multiselect';
import { uniqueCIDs } from '../../../../../../consultation/subcomponents/SOAP/subcomponents/Assessment/helpers';
import { uniqueIDs } from '../../../../../../../utils/uniqueIDs';
import api from '../../../../../../../helpers/api';
import { getParams } from '../../../../../../../utils';
import { getHealthInsurances } from '../../../../../../configuration/http';
import { getCIDTen } from '../../../../../../consultation/http';
import { getSpecialties } from '../../../../../../agenda/SelectScheduleHealthPlaceUserManager/http';
import { getCollaborators } from '../../../../../../collaborators/http';
import { stringifyCollaboratorOption } from '../../../../../../../utils/stringifyCollaboratorOption';
import { parsePrintReportParams } from '../../../../../helpers';


const defaultOptions = require('../../../../../../../component/form/select/options.json')

export const getLabel = (id) => {
    switch(id) {
        case 'DAILY':
            return 'Relatório enviado todo dia seguinte'
        case 'WEEKLY':
            return 'Relatório enviado toda segunda-feira'
        case 'MONTHLY':
            return 'Relatório enviado toda primeira segunda-feira do mês'
        default:
            return ''
    }
}

function strpReport(routine=null) {
    return {
        id: routine?.id,
        reports_periodicity: routine?.reports_periodicity || 'DAILY', 
        reports_email: routine?.reports_email || '', 
        description: routine?.description || '',
        appointment_params: parsePrintReportParams(routine?.appointment_params)
    }
}

export default function NewEditRoutineModal({ initRoutine=null, fetchRoutines=() => null, setModalInfo=() => null }) {
    const dispatch = useDispatch()
    const searchTimeOut = useRef()
    const [report, setReport] = useState(strpReport(initRoutine))
    const [errors, setErrors] = useState({ reports_email: false, description: false })
    const { currentHealthPlaceUser } = useApp()
    const [citiesOptions, setCitiesOptions] = useState([])
	const [collaboratorsOptions, setCollaboratorsOptions] = useState([])
	const [healthInsurancesOptions, setHealthInsurancesOptions] = useState([])
    const [specialtiesOptions, setSpecialtiesOptions] = useState([])
	const [cidsOptions, setCidsOptions] = useState([])

    useEffect(() => {
		fetchCitiesOptions({ offset: 0 })
		fetchCIDTenCodes({ offset: 0 })
        fetchCollaborators({ offset: 0 })
		fetchHealthInsurances({ offset: 0 })
		fetchSpecialtiesOptions({ offset: 0 })
    }, [])
	
	const fetchCIDTenCodes = async (params) => {
        try {
            const cids = await getCIDTen(params)
            setCidsOptions(cids)
        } catch (err) {
            setCidsOptions([])
        }
    }

    const fetchCollaborators = async params => {
		await getCollaborators({
			...params,
			health_place__id: currentHealthPlaceUser?.health_place?.id,
			limit: 500,
			profile__in: 'DOCTOR%2CDENTIST%2CPSYCHOLOGIST%2CNUTRITIONIST%2CNURSE',
			has_person: true
		})
			.then(res => setCollaboratorsOptions(res.data.results.map(instance => stringifyCollaboratorOption(instance))))
			.catch(err => {
				console.error('fetchCollaborators', err)
				setCollaboratorsOptions([])
			})
	}
	
	const fetchSpecialtiesOptions = async (params={}) => {
        params = { ...params, limit: 50 }

        try {
            let res = await getSpecialties(params)

            setSpecialtiesOptions(res.data.results)
        } catch(err) {
            console.error('fetchSpecialtiesOptions ~ ', err)
        }
    }

	const fetchHealthInsurances = async (params={}) => {
		params = getParams({ ...params, limit: 50 })

		try {
			let res = await getHealthInsurances(params)
			setHealthInsurancesOptions(res.data.results)
		} catch (err) {
			console.error('fetchHealthInsurances ~ ', err)
			dispatch(AddAlert('Planos & convênios', 'Erro ao listar planos e convênios', AlertType.ERROR))
		}
	}

	const fetchCitiesOptions = async (params={}) => {
		params = getParams({ ...params, limit: 50 })

		try {
			let res = await api.get(`address/city/${params}`)
			setCitiesOptions(res.data.results)
		} catch (err) {
			console.error('fetchCitiesOptions ~ ', err)
			dispatch(AddAlert('Listar cidades', 'Erro ao obter cidades', AlertType.ERROR))
		}
	}

    const handleChange = (event) => {
        setErrors({ reports_email: false, description: false })
        setReport(prev => ({ ...prev, [event.target.name]: event.target.value }))
    }

    const handleSearchCity = (event) => {
		if (searchTimeOut.current) clearTimeout(searchTimeOut.current)

		searchTimeOut.current = setTimeout(() => {
			fetchCitiesOptions({ 'name': event.target.value })
		}, 400, event.target.value)
	}
	
	const handleSearchCIDs = (search) => {
        if (searchTimeOut.current) clearTimeout(searchTimeOut.current)

        searchTimeOut.current = setTimeout((search) => {
            fetchCIDTenCodes({ search });
        }, 400, search)
    };

    const handleSearchHealthInsurances = (event) => {
		if (searchTimeOut.current) clearTimeout(searchTimeOut.current)
	
		searchTimeOut.current = setTimeout(() => {
			fetchHealthInsurances({ 'name': event.target.value })
		}, 400, event.target.value)
	}

	const handleSearchSpecialties = (event) => {
        if (searchTimeOut.current) clearTimeout(searchTimeOut.current)

        searchTimeOut.current = setTimeout(() => {
            fetchSpecialtiesOptions({ name__icontains: event.target.value })
        }, 400, event.target.value)
    }

    const handleSearchCollaborators = (event) => {
		if (searchTimeOut.current) clearTimeout(searchTimeOut.current)

		searchTimeOut.current = setTimeout(() => {
			fetchCollaborators({ name_cpf_or_email: event.target.value })
		}, 400, event.target.value)
	}

    const handleSubmit = async () => {
        if (!report?.description) return setErrors(prev => ({ ...prev, description: true }))
        if (!isValidEmail(report.reports_email)) return setErrors(prev => ({ ...prev, reports_email: true }))
        const payload = {
            health_place_user: currentHealthPlaceUser?.id,
            reports_periodicity: report.reports_periodicity,
            reports_email: report.reports_email,
            description: report.description,
            appointment_params: parsePrintReportParams(report.appointment_params),
        }
        dispatch(loading())
        
        if (report?.id) {
            patchReportsRoutine(report?.id, payload)
                .then(() => {
                    fetchRoutines({ offset: 0 })
                    dispatch([loaded(), AddAlert('Salvar rotina', 'Rotina salva com sucesso', AlertType.SUCCESS)])
                    setModalInfo(MODAL_INIT_PROPS)
                })
                .catch(err => {
                    console.error('NewEditRoutine ~ handleSubmit ~ ', err)
                    dispatch([loaded(), AddAlert('Salvar rotina', 'Falha ao salvar rotina', AlertType.ERROR)])
                })
        } else {
            postReportsRoutine(payload)
                .then(() => {
                    fetchRoutines({ offset: 0 })
                    dispatch([loaded(), AddAlert('Salvar rotina', 'Rotina salva com sucesso', AlertType.SUCCESS)])
                    setModalInfo(MODAL_INIT_PROPS)
                })
                .catch(err => {
                    console.error('NewEditRoutine ~ handleSubmit ~ ', err)
                    dispatch([loaded(), AddAlert('Salvar rotina', 'Falha ao salvar rotina', AlertType.ERROR)])
                })
        }
    }

    const toggleOption = (loadKey, optionsList, fieldNameRef, optionRef) => {
		const selectedOption = optionsList.find(option => option[fieldNameRef] === optionRef);
		const isExisting = report?.appointment_params?.[loadKey]?.find(option => option[fieldNameRef] === optionRef);

        if (isExisting) {
            setReport(prev => ({
                ...prev,
                appointment_params: {
                    ...prev?.appointment_params,
                    [loadKey]: prev?.appointment_params?.[loadKey]?.filter(option => option[fieldNameRef] !== optionRef)
                }
            }))
		} else {
            setReport(prev => ({
                ...prev, 
                appointment_params: {
                    ...prev?.appointment_params,
                    [loadKey]: [...prev?.appointment_params[loadKey], selectedOption]
                }
            }))
		}
	};

    return <div className='NewEditRoutine'>
        <div className='NewEditRoutine-Grid'>
            <RadioButton
                label={getLabel(report.reports_periodicity)}
                checked={report.reports_periodicity}
                options={defaultOptions.PERIODICITY_CHOICES}
                action={event => setReport(prev => ({ ...prev, reports_periodicity: event.target.value.id }))}
            />
            <div className='NewEditRoutine-Grid-2FR'>
                <Input 
                    name='description'
                    label='Título'
                    value={report.description}
                    action={handleChange}
                    errors={{ error: errors, message: errors?.description }}
                />
                <Input
                    label='E-mail'
                    name='reports_email'
                    value={report.reports_email}
                    action={handleChange}
                    errors={{ error: errors, message: errors?.reports_email }}
                />
            </div>

            <div className='NewEditRoutine-Grid-3FR'>
                <NewMultiSelect
                    label='Profissionais'
                    filterNode={
                        <div className='ManageTeamEdgesModal-NewSelect'>
                            <Input
                                placeholder='Pesquisar por e-mail, nome ou cpf'
                                action={handleSearchCollaborators}
                                actionFocus={() => fetchCollaborators()}
                            />
                        </div>
                    }
                    defaultText={report?.appointment_params?.doctor__id__in?.length ? `${report?.appointment_params?.doctor__id__in.length} selecionados` : 'Selecione'}
                    onlyDefaultText
                    onSelect={({ optionRef }) => toggleOption('doctor__id__in', collaboratorsOptions, 'id', optionRef)}
                    selectedOptions={report?.appointment_params?.doctor__id__in?.map(({id}) => id)}
                    optionRefKey='id'
                    optionStrKey='str'
                    options={uniqueIDs(report?.appointment_params?.doctor__id__in, collaboratorsOptions)}
                />
                <NewMultiSelect
                    label='Especialidades'
                    filterNode={
                        <div className='NewAppointmentModal-NewSelect'>
                            <Input
                                placeholder='Pesquisar por nome'
                                action={handleSearchSpecialties}
                                actionFocus={() => fetchSpecialtiesOptions()}
                            />
                        </div>
                    }
                    defaultText={report?.appointment_params?.specialty__id__in?.length ? `${report?.appointment_params?.specialty__id__in.length} selecionadas` : 'Selecione'}
                    onlyDefaultText
                    onSelect={({ optionRef }) => toggleOption('specialty__id__in', specialtiesOptions, 'id', optionRef)}
                    selectedOptions={report?.appointment_params?.specialty__id__in?.map(specialty => specialty.id)}
                    optionRefKey='id'
                    optionStrKey='strf'
                    options={uniqueIDs(report?.appointment_params?.specialty__id__in, specialtiesOptions)}
                />
                <NewMultiSelect
                    label='Convênios'
                    filterNode={
                        <div className='NewAppointmentModal-NewSelect'>
                            <Input
                                placeholder='Pesquisar por nome'
                                action={handleSearchHealthInsurances}
                                actionFocus={() => fetchHealthInsurances()}
                            />
                        </div>
                    }
                    defaultText={report?.appointment_params?.health_insurance__id__in?.length ? `${report?.appointment_params?.health_insurance__id__in.length} selecionados` : 'Selecione'}
                    onlyDefaultText
                    onSelect={({ optionRef }) => toggleOption('health_insurance__id__in', healthInsurancesOptions, 'id', optionRef)}
                    selectedOptions={report?.appointment_params?.health_insurance__id__in?.map(insurance => insurance.id)}
                    optionRefKey='id'
                    optionStrKey='name'
                    options={uniqueIDs(report?.appointment_params?.health_insurance__id__in, healthInsurancesOptions)}
                />
            </div>
            <div className='NewEditRoutine-Grid-3FR'>
                <NewMultiSelect
                    label='Categoria'
                    filterNode={null}
                    defaultText={report?.appointment_params?.classification__in.length ? `${report?.appointment_params?.classification__in.length} selecionadas` : 'Selecione'}
                    onlyDefaultText
                    onSelect={({ optionRef }) => toggleOption('classification__in', defaultOptions.CLASSIFICATION_CHOICES, 'id', optionRef)}
                    selectedOptions={report?.appointment_params?.classification__in.map(classification => classification.id)}
                    optionRefKey='id'
                    optionStrKey='name'
                    options={defaultOptions.CLASSIFICATION_CHOICES}
                />
                <NewMultiSelect
                    label='Status'
                    filterNode={null}
                    defaultText={report?.appointment_params?.decision__in.length ? `${report?.appointment_params?.decision__in.length} selecionadas` : 'Selecione'}
                    onlyDefaultText
                    onSelect={({ optionRef }) => toggleOption('decision__in', defaultOptions.DECISION_CHOICES, 'id', optionRef)}
                    selectedOptions={report?.appointment_params?.decision__in.map(decision => decision.id)}
                    optionRefKey='id'
                    optionStrKey='name'
                    options={defaultOptions.DECISION_CHOICES}
                />
                <NewMultiSelect
                    label='Prioridade'
                    filterNode={null}
                    defaultText={report?.appointment_params?.priority__in.length ? `${report?.appointment_params?.priority__in.length} selecionadas` : 'Selecione'}
                    onlyDefaultText
                    onSelect={({ optionRef }) => toggleOption('priority__in', defaultOptions.PRIORITY_CHOICES, 'id', optionRef)}
                    selectedOptions={report?.appointment_params?.priority__in.map(priority => priority.id)}
                    optionRefKey='id'
                    optionStrKey='name'
                    options={defaultOptions.PRIORITY_CHOICES}
                />
            </div>
			<div className='NewEditRoutine-Grid-3FR'>
                <NewMultiSelect
                    label='Tipo'
                    filterNode={null}
                    defaultText={report?.appointment_params?.result__in.length ? `${report?.appointment_params?.result__in.length} selecionados` : 'Selecione'}
                    onlyDefaultText
                    onSelect={({ optionRef }) => toggleOption('result__in', defaultOptions.RESULT_CHOICES, 'id', optionRef)}
                    selectedOptions={report?.appointment_params?.result__in.map(result => result.id)}
                    optionRefKey='id'
                    optionStrKey='name'
                    options={defaultOptions.RESULT_CHOICES}
                />
                <NewMultiSelect
                    label='Residência do Paciente'
                    filterNode={
                        <div className='NewAppointmentModal-NewSelect'>
                            <Input 
                                placeholder='Pesquisar por cidade'
                                action={handleSearchCity}
                                actionFocus={()=> fetchCitiesOptions()}
                            />
                        </div>
                    }
                    defaultText={report?.appointment_params?.person__address__city__in.length ? `${report?.appointment_params?.person__address__city__in.length} selecionados` : 'Selecione'}
                    onlyDefaultText
                    onSelect={({ optionRef }) => toggleOption('person__address__city__in', citiesOptions, 'id', optionRef)}
                    selectedOptions={report?.appointment_params?.person__address__city__in.map(option => option.id)}
                    optionRefKey='id'
                    optionStrKey='name'
                    options={uniqueIDs(report?.appointment_params?.person__address__city__in, citiesOptions)}
                />
                <NewMultiSelect
                    label='CIDs'
                    filterNode={<div className='SOAP-Assessment-NewMultiSelect-FilterNode'>
                        <Input 
                            placeholder='Pesquisar por CID ou Título'
                            action={({ target: { value } }) => handleSearchCIDs(value)}
                            actionFocus={()=> fetchCIDTenCodes()}
                        />
                    </div>}
                    defaultText={report?.appointment_params?.related_diseases_and_problems__code__in.length ? `${report?.appointment_params?.related_diseases_and_problems__code__in.length} selecionados` : 'Selecione'}
                    onlyDefaultText
                    onSelect={({ optionRef }) => toggleOption('related_diseases_and_problems__code__in', cidsOptions, 'code', optionRef)}
                    selectedOptions={report?.appointment_params?.related_diseases_and_problems__code__in?.map(cid => cid.code) || []}
                    optionRefKey='code'
                    optionStrKey='title'
                    options={report?.appointment_params?.related_diseases_and_problems__code__in
                        ? uniqueCIDs([...report?.appointment_params?.related_diseases_and_problems__code__in, ...cidsOptions]).map(cid => ({ ...cid, title: `${cid.code} | ${cid.title}` }))
                        : []
                    }
                />
            </div>

        </div>
        <div className='NewEditRoutine-BtnBox'>
            <NewButton 
                label='Salvar'
                onClick={handleSubmit}
            />
        </div>
    </div>
}