import { buildDate, formatDateWithTimezone, formatDateWithTimezoneFromDateObj } from "../../utils/convertDate";

export const formatScheduleDateWithTimezone = (year, month, day, time) => {
    let hour, minute;
    [hour, minute] = time.split(':')
    let date = new Date(year, month - 1, day, hour, minute)
    let offset = date.getTimezoneOffset() * 60000;
    let dateISOTime = new Date(date.getTime() - offset).toISOString()
  
    return dateISOTime
}

export function generateTimeSlots(event, gap) {
  if (!event.data || !event.tempo || !event.tempoConsulta) {
    throw new Error('Missing required event properties');
  }
  
  const schedule = [];
  let startDate = convertToDateTime(event.data, event.tempo.de);
  const endDate = convertToDateTime(event.data, event.tempo.ate);
  const interval = parseInt(event.tempoConsulta.duration, 10); // Appointment duration in minutes

  while (true) {
    let endSlotTime = new Date(startDate.getTime() + interval * 60000);

    if (endSlotTime.getTime() > endDate.getTime()) {
      break;
    }

    schedule.push({
      de: formatTime(startDate),
      ate: formatTime(endSlotTime),
    });

    startDate = new Date(endSlotTime.getTime() + gap * 60000);
  }

  return schedule;
}
  
function convertToDateTime(date, time) {
    const [day, month, year] = date.split('/');
    const [hours, minutes] = time.split(':');
    return new Date(`${year}-${month}-${day}T${hours}:${minutes}:00`);
}
  
function formatTime(date) {
    return `${padZero(date.getHours())}:${padZero(date.getMinutes())}`;
}
  
function padZero(number) {
    return number.toString().padStart(2, '0');
}

export function parseAppointmentsURLQuerysetParams(filters) {
	const joinComman = (array) => array?.length ? array?.map(({id}) => id)?.join(',') : null
  const joinCommaCode = (array) => array?.length ? array?.map(({code}) => code)?.join(',') : null

  return {
		"name_or_cpf": filters?.name_or_cpf || '',
		"doctor__id__in": joinComman(filters?.doctor__id__in),
		"specialty__id__in": joinComman(filters?.specialty__id__in),
		"health_insurance__id__in": joinComman(filters?.health_insurance__id__in),
    "service_location__id__in": joinComman(filters?.service_location__id__in),
		"decision__in": joinComman(filters.decision__in),
		"classification__in": joinComman(filters.classification__in),
		"priority__in": joinComman(filters.priority__in),
		"result__in": joinComman(filters.result__in),		
    "related_diseases_and_problems__code__in": joinCommaCode(filters?.related_diseases_and_problems__code__in),
    "start__gte": filters.start__gte ? formatDateWithTimezoneFromDateObj(filters.start__gte, '00', '00') : null,
		"start__lte": filters.start__lte ? formatDateWithTimezoneFromDateObj(filters.start__lte, '23', '59') : null,
		"person__birthday__gte": filters?.person__birthday__gte ? formatDateWithTimezoneFromDateObj(filters.person__birthday__gte, '00', '00').substring(0, 10) : null,
		"person__birthday__lte": filters.person__birthday__lte ? formatDateWithTimezoneFromDateObj(filters.person__birthday__lte, '23', '59').substring(0, 10) : null,
    "person__address__city__in": joinComman(filters.person__address__city__in),
    "ordering": filters?.ordering || null
  }
}

export function parsePrintReportParams(filters) {

    const params = {
      "doctor__id__in": filters?.doctor__id__in || [],
      "specialty__id__in": filters?.specialty__id__in || filters?.specialty__in || [],
      "health_insurance__id__in": filters?.health_insurance__id__in || filters?.health_insurance__in ||  [],
      "service_location__id__in": filters?.service_location__id__in || [],
      "classification__in": filters?.classification__in || [],
      "decision__in": filters?.decision__in || [],
      "priority__in": filters?.priority__in || [],
      "result__in": filters?.result__in || [],
      "related_diseases_and_problems__code__in": filters?.related_diseases_and_problems__code__in || [],
      "start__gte": filters?.start__gte ? formatDateWithTimezoneFromDateObj(filters?.start__gte, '00', '00') : null,
      "start__lte": filters?.start__lte ? formatDateWithTimezoneFromDateObj(filters?.start__lte, '23', '59') : null,
      "person__birthday__gte": filters?.person__birthday__gte ? formatDateWithTimezoneFromDateObj(filters.person__birthday__gte, '00', '00').substring(0, 10) : null,
      "person__birthday__lte": filters?.person__birthday__lte ? formatDateWithTimezoneFromDateObj(filters.person__birthday__lte, '23', '59').substring(0, 10) : null,
      "person__address__city__in": filters?.person__address__city__in || [],
      "ordering": filters?.ordering || null,
    }

    return params
}

export function parseTicketURLQuerysetParams(filters) {
	const joinComman = (array) => array?.length ? array?.map(({id}) => id)?.join(',') : null

	return {
    "call_panel_service__id__in": joinComman(filters?.call_panel_service__id__in),
		"attendance__person__name_or_cpf": filters?.attendance__person__name_or_cpf || '',
		"attendance__doctor__id__in": joinComman(filters?.attendance__doctor__id__in),
    "called_at__gte": filters?.called_at__gte ? formatDateWithTimezoneFromDateObj(filters.called_at__gte, '00', '00') : null,
		"called_at__lte": filters?.called_at__lte ? formatDateWithTimezoneFromDateObj(filters.called_at__lte, '23', '59') : null,
    "attendance__service_location__id__in": joinComman(filters?.attendance__service_location__id__in),
    "status__in": joinComman(filters?.status__in),
    "ordering": filters?.ordering || null
  }
}