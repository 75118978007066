import { idade } from "../../../../../../../../../../component"
import { Mask1DecimalPlace } from "../../../../../../../../../../component/mask/mask"

export const strpPregnancyBackground = (background=null) => {
    return {
        'project': background?.project || -1,
        'unstable_family': background?.unstable_family || false,
        'pregnancy_acceptance': background?.pregnancy_acceptance || false,
        'cant_read_write': background?.cant_read_write || false,
        'tabagism': background?.tabagism || false,
        'last_delivery_less_than_1_year_ago': background?.last_delivery_less_than_1_year_ago || 'NO',
        'previous_pregnancies': background?.previous_pregnancies ? String(background.previous_pregnancies) : '',
        'abortions': background?.abortions ? String(background.abortions) : '',
        'prematures': background?.prematures ? String(background.prematures) : '',
        'deliveries_amount': background?.deliveries_amount ? String(background.deliveries_amount) : '',
        'vaginal_deliveries': background?.vaginal_deliveries ? String(background.vaginal_deliveries) : '',
        'cesarean_deliveries': background?.cesarean_deliveries ? String(background.cesarean_deliveries) : '',
        'forceps_deliveries': background?.forceps_deliveries ? String(background.forceps_deliveries) : '',
        'living_childrens': background?.living_childrens ? String(background.living_childrens) : '',
        'stillbirth_amount': background?.stillbirth_amount ? String(background.stillbirth_amount) : '',
        'weight_at_pregnancy_age': background?.weight_at_pregnancy_age ? Mask1DecimalPlace(String(background.weight_at_pregnancy_age)) : '',
        'height_at_pregnancy_age': background?.height_at_pregnancy_age || '',
        'voluntary_abortion': background?.voluntary_abortion || false,
        'spontaneous_abortion': background?.spontaneous_abortion || false,
        'stillbirth': background?.stillbirth || false,
        'fetal_death': background?.fetal_death || false,
        'eclampsia': background?.eclampsia || false,
        'placenta_previa_ob': background?.placenta_previa_ob || false,
        'premature_placental_abruption': background?.premature_placental_abruption || false,
        'isthmocervical_incompetence_ob': background?.isthmocervical_incompetence_ob || false,
        'intrauterine_growth_restriction': background?.intrauterine_growth_restriction || false,
        'fetal_malformation': background?.fetal_malformation || false,
        'pre_eclampsia': background?.pre_eclampsia || false,
        'abortion_threat': background?.abortion_threat || false,
        'genitourinary_tract_anomaly': background?.genitourinary_tract_anomaly || false,
        'placenta_previa_ogp': background?.placenta_previa_ogp || false,
        'maternal_cancer': background?.maternal_cancer || false,
        'hemolytic_disease': background?.hemolytic_disease || false,
        'treated_sterility': background?.treated_sterility || false,
        'isoimmunization': background?.isoimmunization || false,
        'gynecological_neoplasms': background?.gynecological_neoplasms || false,
        'congenital_malformations': background?.congenital_malformations || false,
        'retarded_uterine_growth': background?.retarded_uterine_growth || false,
        'polyhydramnium_oligohydramnium': background?.polyhydramnium_oligohydramnium || false,
        'abnormal_cervical_cytology_nic_i_ii_iii': background?.abnormal_cervical_cytology_nic_i_ii_iii || false,
        'hypertensive_disease_of_pregnancy': background?.hypertensive_disease_of_pregnancy || false,
        'gestional_diabetes': background?.gestional_diabetes || false,
        'gemellar': background?.gemellar || false,
        'isthmocervical_incompetence_ogp': background?.isthmocervical_incompetence_ogp || false,
        'cardiopathies': background?.cardiopathies || false,
        'severe_varicose_veins': background?.severe_varicose_veins || false,
        'severe_pneumopathy': background?.severe_pneumopathy || false,
        'diabetes_mellitus': background?.diabetes_mellitus || false,
        'autoimmune_diseases_collagenosis': background?.autoimmune_diseases_collagenosis || false,
        'psychiatric_illness': background?.psychiatric_illness || false,
        'severe_renal_disease': background?.severe_renal_disease || false,
        'epilepsy_neurological_disease': background?.epilepsy_neurological_disease || false,
        'hemopathies': background?.hemopathies || false,
        'hypertension': background?.hypertension || false,
        'recurrent_urinary_infection': background?.recurrent_urinary_infection || false,
        'severe_infections': background?.severe_infections || false,
        'aids_hiv': background?.aids_hiv || false,
        'syphilis': background?.syphilis || false,
        'tuberculosis': background?.tuberculosis || false,
        'toxoplasmosis': background?.toxoplasmosis || false,
        'drug_dependency': background?.drug_dependency || false,
        'alcoholism': background?.alcoholism || false,
        'thrombophilia': background?.thrombophilia || false,
        'endocrinopathies': background?.endocrinopathies || false,
        'thyroid_alterations': background?.thyroid_alterations || false
    }
}

export const parsePregnancyBackground = (background, project_id) => {
        
    return {
        'project': project_id,
        'unstable_family': background?.unstable_family || false,
        'pregnancy_acceptance': background?.pregnancy_acceptance || false,
        'cant_read_write': background?.cant_read_write || false,
        'tabagism': background?.tabagism || false,
        'last_delivery_less_than_1_year_ago': background?.last_delivery_less_than_1_year_ago || 'NO',
        'previous_pregnancies': background?.previous_pregnancies ? Number(background.previous_pregnancies) : null,
        'abortions': background?.abortions ? Number(background.abortions) : null,
        'prematures': background?.prematures ? Number(background.prematures) : null,
        'deliveries_amount': background?.deliveries_amount ? Number(background.deliveries_amount) : null,
        'vaginal_deliveries': background?.vaginal_deliveries ? Number(background.vaginal_deliveries) : null,
        'cesarean_deliveries': background?.cesarean_deliveries ? Number(background.cesarean_deliveries) : null,
        'forceps_deliveries': background?.forceps_deliveries ? Number(background.forceps_deliveries) : null,
        'living_childrens': background?.living_childrens ? Number(background.living_childrens) : null,
        'stillbirth_amount': background?.stillbirth_amount ? Number(background.stillbirth_amount) : null,
        'weight_at_pregnancy_age': background?.weight_at_pregnancy_age ? Number(String(background.weight_at_pregnancy_age).replace(',', '.')) : null,
        'height_at_pregnancy_age': background?.height_at_pregnancy_age ? Number(background.height_at_pregnancy_age) : null,
        'voluntary_abortion': background?.voluntary_abortion || false,
        'spontaneous_abortion': background?.spontaneous_abortion || false,
        'stillbirth': background?.stillbirth || false,
        'fetal_death': background?.fetal_death || false,
        'eclampsia': background?.eclampsia || false,
        'placenta_previa_ob': background?.placenta_previa_ob || false,
        'premature_placental_abruption': background?.premature_placental_abruption || false,
        'isthmocervical_incompetence_ob': background?.isthmocervical_incompetence_ob || false,
        'intrauterine_growth_restriction': background?.intrauterine_growth_restriction || false,
        'fetal_malformation': background?.fetal_malformation || false,
        'pre_eclampsia': background?.pre_eclampsia || false,
        'abortion_threat': background?.abortion_threat || false,
        'genitourinary_tract_anomaly': background?.genitourinary_tract_anomaly || false,
        'placenta_previa_ogp': background?.placenta_previa_ogp || false,
        'maternal_cancer': background?.maternal_cancer || false,
        'hemolytic_disease': background?.hemolytic_disease || false,
        'treated_sterility': background?.treated_sterility || false,
        'isoimmunization': background?.isoimmunization || false,
        'gynecological_neoplasms': background?.gynecological_neoplasms || false,
        'congenital_malformations': background?.congenital_malformations || false,
        'retarded_uterine_growth': background?.retarded_uterine_growth || false,
        'polyhydramnium_oligohydramnium': background?.polyhydramnium_oligohydramnium || false,
        'abnormal_cervical_cytology_nic_i_ii_iii': background?.abnormal_cervical_cytology_nic_i_ii_iii || false,
        'hypertensive_disease_of_pregnancy': background?.hypertensive_disease_of_pregnancy || false,
        'gestional_diabetes': background?.gestional_diabetes || false,
        'gemellar': background?.gemellar || false,
        'isthmocervical_incompetence_ogp': background?.isthmocervical_incompetence_ogp || false,
        'cardiopathies': background?.cardiopathies || false,
        'severe_varicose_veins': background?.severe_varicose_veins || false,
        'severe_pneumopathy': background?.severe_pneumopathy || false,
        'diabetes_mellitus': background?.diabetes_mellitus || false,
        'autoimmune_diseases_collagenosis': background?.autoimmune_diseases_collagenosis || false,
        'psychiatric_illness': background?.psychiatric_illness || false,
        'severe_renal_disease': background?.severe_renal_disease || false,
        'epilepsy_neurological_disease': background?.epilepsy_neurological_disease || false,
        'hemopathies': background?.hemopathies || false,
        'hypertension': background?.hypertension || false,
        'recurrent_urinary_infection': background?.recurrent_urinary_infection || false,
        'severe_infections': background?.severe_infections || false,
        'aids_hiv': background?.aids_hiv || false,
        'syphilis': background?.syphilis || false,
        'tuberculosis': background?.tuberculosis || false,
        'toxoplasmosis': background?.toxoplasmosis || false,
        'drug_dependency': background?.drug_dependency || false,
        'alcoholism': background?.alcoholism || false,
        'thrombophilia': background?.thrombophilia || false,
        'endocrinopathies': background?.endocrinopathies || false,
        'thyroid_alterations': background?.thyroid_alterations || false
    }
}

export const getPregnancyBackgroundResults = (pregnancy_background, person) => {
    const { fields_info } = require('./PregnancyRiskCalculator.json')
    let { anos } = idade(person.birthday)
    let results = []
    let total_score = 0

    if (anos && anos < 18) {
        results.push({
            name: "Gravidez na Adolescência",
            score: 1,
            notes: ""
        })
        total_score += 1
    } else if (anos && anos >= 35) {
        results.push({
            name: "Gravidez após os 35 anos",
            score: 1,
            notes: ""
        })
        total_score += 1
    }

    Object.entries(pregnancy_background).map(([field, value]) => {
        if ([
            'project',
            'previous_pregnancies',
            'abortions',
            'deliveries_amount', 
            'vaginal_deliveries', 
            'cesarean_deliveries',
            'forceps_deliveries',
            'living_childrens',
            'stillbirth_amount',
            'last_update'
        ].includes(field)) return 
        
        if (field === 'last_delivery_less_than_1_year_ago' && value === 'YES') {
            results.push({
                name: "Parto há menos de 1 ano",
                score: 2,
                notes: ""
            })
            total_score += 2
        } else if (field === 'prematures') {
            if (Number(value) === 1) {
                results.push({
                    name: "1 filho prematuro",
                    score: 5,
                    notes: "Um histórico de filhos prematuros pode aumentar o risco de um novo parto prematuro, o que pode levar a complicações para o bebê, como problemas respiratórios, dificuldades de alimentação e aumento do risco de morte neonatal. A mãe também pode ter complicações, como hemorragias e infecções pós-parto."
                })
                total_score += 5
            } else if (Number(value) > 1) {
                results.push({
                    name: `${value} filhos prematuros`,
                    score: 10,
                    notes: "Um histórico de filhos prematuros pode aumentar o risco de um novo parto prematuro, o que pode levar a complicações para o bebê, como problemas respiratórios, dificuldades de alimentação e aumento do risco de morte neonatal. A mãe também pode ter complicações, como hemorragias e infecções pós-parto."
                })
                total_score += 10
            }
        } else {
            if (value) {
                results.push(fields_info[field])
                total_score += fields_info[field]?.score || 0 
            }
        }
    })

    return { 
        pregnancy_problems: results,
        total_score: total_score, 
        risk_type: total_score <= 4 ? 'LOW' : total_score >= 5 && total_score <= 9 ? 'MEDIUM' : 'HIGH'
    } 
}
