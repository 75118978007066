import './NotesSection.css'
import React, { useRef } from "react"
import { useAttendance } from "../../consultation"
import ConsultationTextField from "../../consultation-text-field"

export default function NotesSection() {
    const { attendance, setAttendance, savePartialAppointment, isEditable } = useAttendance()
    const searchTimeout = useRef(null)

    const handleChange = (id, value) => {
        setAttendance(prev => ({ ...prev, [id]: value }))

        if (searchTimeout.current) {
            clearTimeout(searchTimeout.current);
        }

        searchTimeout.current = setTimeout(() => {
            savePartialAppointment(id, value);
        }, 500)
    };

    const handleBlur = (id) => {
        savePartialAppointment(id, attendance[id]);
    }

    return <>
        <div className='NotesSection-subtitle'>Anotações</div>
        <ConsultationTextField
            id='notes'
            notes='* Esse campo serve como bloco de notas e não é compartilhado com o paciente.'
            value={attendance.notes}
            handleChange={(value) => handleChange('notes', value)}
            readOnly={!isEditable}
            onBlur={() => handleBlur('notes')}
        />
    </>
}